import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {DatePipe} from '@angular/common';
import {CellErrorIconComponent} from '../../cell-error-icon/cell-error-icon.component';

@Component({
  selector: 'app-time-of-day-cell-renderer',
  templateUrl: './time-of-day-cell-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
  standalone: true,
  imports: [CellErrorIconComponent, DatePipe],
})
export class TimeOfDayCellRendererComponent extends CustomCellComponent {
}
