import {ChangeDetectorRef, Component, EventEmitter, inject, OnDestroy, signal, viewChild} from '@angular/core';
import {
  DefaultApiAdapter,
  EControlActions,
  FormControlService,
  IApiControl,
  IControlObject,
  IFilter,
} from 'frontier/nucleus';
import {BehaviorSubject, combineLatest, debounceTime, filter, mergeMap, of, Subscription, tap} from 'rxjs';
import {FormControl} from '@angular/forms';
import {ICheckboxFormElement, ISelectFormElement, ITextFormElement} from 'frontier/browserkit';
import {GuidFormControlComponent} from './guid-form-control/guid-form-control.component';
import {ControlStore} from 'frontier/nucleus/src/lib/apiv2/control.store';
import {FormWrapperComponent} from '../../components/control/form-control/dynamic-form/form-wrapper/form-wrapper.component';
import {
  TextElementComponent
} from '../../components/control/form-control/dynamic-form/form-element/text-element/text-element.component';
import {
  CheckboxElementComponent
} from '../../components/control/form-control/dynamic-form/form-element/checkbox-element/checkbox-element.component';

@Component({
  selector: 'kpi4me-guid-form',
  templateUrl: './guid-form.component.html',
  styleUrls: ['./guid-form.component.scss'],
  standalone: true,
  imports: [
    CheckboxElementComponent,
    TextElementComponent,
    FormWrapperComponent,
    GuidFormControlComponent,
  ],
})
export class GuidFormComponent implements OnDestroy {
  private _controlStore = inject(ControlStore);
  private _formControlService = inject(FormControlService);

  formRef = viewChild(GuidFormControlComponent);
  formFilter = signal<IFilter>({});

  hasRepo = new FormControl(false);
  formGuid = new FormControl('');
  repoSelectedElement = new FormControl(null);

  textElementData: ITextFormElement = {
    label: 'Formular selectionGUID',
    inputType: 'text',
    required: true
  };
  render = false;
  checkboxData: ICheckboxFormElement = {
    label: 'Mit Repository',
    value: true,
    displayAs: 'toggle'
  };
  repoSelectionData: ISelectFormElement = {
    label: 'Repository',
    options: [],
    createNewEnabled: true,
    multiple: false,
  };

  hasRepo$ = new BehaviorSubject(this.hasRepo.value);
  initializedFormControl$ = new BehaviorSubject<any>(null);
  private formGuidChange$ = new EventEmitter<string>();

  private subs = new Subscription();

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    this.subs.add(
      combineLatest([
        this.hasRepo$.pipe(filter(v => v == true)),
        this.initializedFormControl$.pipe(filter(v => v != null))
      ]).pipe(
        mergeMap(() => this.updateRepo(null))
      ).subscribe()
    );

    this.subs.add(
      this.formGuidChange$.pipe(debounceTime(2000)).subscribe((guid) => {
        this.render = false;
        this.cdr.detectChanges();
        this.render = true;
        this.cdr.detectChanges();
        console.log('guid change', guid);
      })
    )

    this.subs.add(
      this.repoSelectedElement.valueChanges.subscribe(v => {
        if (v) {
          this.formRef().patchFilter({
            parent: v
          });
        }
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onFormGuidChange(guid: string) {
    this.formGuidChange$.emit(guid);
  }

  onHasRepoChanged(b: boolean) {
    console.log('has repo changed', b);
    this.hasRepo$.next(b);
  }

  updateRepo(action: EControlActions) {
    console.log('updating repo');
    if (this.hasRepo.value == false) {
      return of(null);
    } else {
      return this._formControlService.dBObjectFormControlGetRepository({
          InstanceId: this.formRef().instanceId()
        }
      ).pipe(
        tap((repository) => {
          this.repoSelectionData = {
            ...this.repoSelectionData,
            ...repository,
            options: repository.options
          };
          if (action) {
            this._controlStore.controlDataChanged$.emit({GUID: this.formGuid.value, changeType: action});
          }
        })
      )
    }
  }

  onInizializedDynamicFormControl(event: IApiControl) {
    console.log('Inizialized dynamic form control');
    this.initializedFormControl$.next(event)
  }

  onNewElement() {
    this._formControlService.dBObjectFormControlNewElement({
      InstanceId: this.formRef().instanceId(),
      formElements: null,
      name: null
    })
      .subscribe(res => {
        this.updateRepo(EControlActions.create).subscribe(() => {
          this.repoSelectedElement.patchValue(res);
        })
      })
  }

  onDeleteElement(evt: IControlObject) {
    this._formControlService.dBObjectFormControlDeleteElement({
      InstanceId: this.formRef().instanceId(),
      dbobject: evt
    })
      .subscribe(res => {
        this.updateRepo(EControlActions.delete).subscribe(() => {
          if (res) {
            this.repoSelectedElement.patchValue(null);
          }
        })
      })
  }
}
