import {ChangeDetectorRef, Component, EventEmitter, inject, OnDestroy, signal} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, Subscription} from 'rxjs';
import {ITextFormElement} from '../../components/control/form-control/dynamic-form/form-element/form-data.interface';
import {GuidTableControlComponent} from './guid-table-control/guid-table-control.component';
import {
  TextElementComponent
} from '../../components/control/form-control/dynamic-form/form-element/text-element/text-element.component';

@Component({
  selector: 'kpi4me-guid-table',
  templateUrl: './guid-table.component.html',
  styleUrls: ['./guid-table.component.scss'],
  standalone: true,
  imports: [TextElementComponent, GuidTableControlComponent],
})
export class GuidTableComponent implements OnDestroy {
  private _cdr = inject(ChangeDetectorRef);

  tableGuidForm = new FormControl('');
  tableGuidFormData: ITextFormElement = {
    label: 'Tabellen selectionGUID',
    inputType: 'text',
    required: false
  };
  render = signal<boolean>(false);
  tableGuid = signal<string>(null);

  private _tableGuidChange$ = new EventEmitter();
  private _subs = new Subscription();

  constructor() {
    this._subs.add(
      this._tableGuidChange$.pipe(debounceTime(2000)).subscribe((guid) => {
        this.render.set(false);
        this.tableGuid.set(guid);
        this._cdr.detectChanges();
        this.render.set(true);
        console.log('guid change', guid);
      })
    )
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  onTableGuidChange(guid: string) {
    this._tableGuidChange$.emit(guid);
  }
}
