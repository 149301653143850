import {TColType} from '../../table-control/cell.enum';
import {TextCellEditorComponent} from './text-cell-editor/text-cell-editor.component';
import {BoolCellEditorComponent} from './bool-cell-editor/bool-cell-editor.component';
import {NumberCellEditorComponent} from './number-cell-editor/number-cell-editor.component';
import {EnumCellEditorComponent} from './enum-cell-editor/enum-cell-editor.component';
import {
  RepositorySelectionCellEditorComponent
} from './repository-selection-cell-editor/repository-selection-cell-editor.component';
import {DateCellEditorComponent} from './date-cell-editor/date-cell-editor.component';
import {LongTextCellEditorComponent} from './long-text-cell-editor/long-text-cell-editor.component';
import {AutocompleteEditorComponent} from './autocomplete-editor/autocomplete-editor.component';

export const ColtypeToEditorMap: Record<number, any> = {
  [TColType.clText]: TextCellEditorComponent,
  [TColType.clBool]: BoolCellEditorComponent,
  [TColType.clValue]: TextCellEditorComponent,
  [TColType.clReference]: RepositorySelectionCellEditorComponent,
  [TColType.clNumber]: NumberCellEditorComponent,
  [TColType.clEnum]: EnumCellEditorComponent,
  [TColType.clDateTime]: DateCellEditorComponent,
  [TColType.clLongText]: LongTextCellEditorComponent,
  [TColType.clAutoComplete]: AutocompleteEditorComponent,
  [TColType.clMail]: TextCellEditorComponent,
  [TColType.clTelephone]: TextCellEditorComponent,
  [TColType.clIban]: TextCellEditorComponent,
}
