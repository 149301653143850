import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {HttpClient} from '@angular/common/http';
import { IDialogData } from './base-dialog/dialog-data.interface';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogStates = new Map<string, IDialogData>();

  constructor(public dialog: MatDialog, public http: HttpClient) {
  }

  saveDialogState(dialogName: string, data: IDialogData) {
    this.dialogStates.set(dialogName, data);
  }

  loadDialogState(dialogName: string): any {
    return this.dialogStates.get(dialogName);
  }
}
