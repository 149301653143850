<ng-template #cancel>
  <button style="height: 100%" mat-flat-button>
    <span>Abbruch</span>
  </button>
</ng-template>

<input
  #inputField
  [format]="24"
  [ngxTimepicker]="timePickerValue"
  [value]="time"
  aria-label="default time"
  readonly
  style="width: 100%"
/>
<ngx-material-timepicker
  #timePickerValue
  [cancelBtnTmpl]="cancel"
  (timeChanged)="onTimeChanged($event)"
></ngx-material-timepicker>
