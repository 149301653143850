import {
  computed,
  Directive,
  effect,
  EffectRef,
  EventEmitter,
  inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {ControlService, Debouncer, IApiControl, IFilter} from 'frontier/nucleus';
import {concatMap, finalize, isObservable, Observable, of, pipe, retry, Subscription, switchMap} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {ControlStore} from 'frontier/nucleus/src/lib/apiv2/control.store';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import {tapResponse} from '@ngrx/operators';
import {patchState, signalState} from '@ngrx/signals';

export type IInitialStateProps = {
  filter: IFilter;
  sorting: object;
  custom: object;
}

type ControlState<TDto, TModel> = IInitialStateProps & {
  apiInstance: IApiControl | null;
  isInitialized: boolean;
  isChanged: boolean;
  isDeleted: boolean;
  data: TModel;
  apiData: TDto;
  loading: boolean;
}

export const INITIAL_STATE_TOKEN = new InjectionToken<Partial<IInitialStateProps>>('initialState');

@Directive()
export abstract class SignalControl<TDto, TModel = TDto> implements OnInit, OnDestroy {
  initialState: Partial<IInitialStateProps> = inject(INITIAL_STATE_TOKEN, {optional: true});
  GUID: string;

  protected readonly controlService: ControlService = inject(ControlService);
  protected readonly controlStore: ControlStore = inject(ControlStore);
  protected readonly debouncer: Debouncer = new Debouncer();

  readonly state = signalState<ControlState<TDto, TModel>>({
    apiInstance: null,
    filter: {},
    sorting: {},
    custom: {},
    isInitialized: false,
    isChanged: false,
    isDeleted: false,
    data: null,
    apiData: null,
    loading: true,
    ...this.initialState
  });
  readonly apiInstance = this.state.apiInstance;
  readonly apiInstance$ = toObservable(this.apiInstance);
  readonly instanceId = computed(() => this.apiInstance().instanceid);
  readonly filter = this.state.filter;
  readonly sorting = this.state.sorting;
  readonly custom = this.state.custom;
  readonly isInitialized = this.state.isInitialized;
  readonly isChanged = this.state.isChanged;
  readonly isDeleted = this.state.isDeleted;
  readonly data = this.state.data;
  readonly apiData = this.state.apiData;
  readonly loading = this.state.loading;

  protected subs = new Subscription();

  private changeInstanceParams = computed(() => {
    return {
      instanceId: this.apiInstance()?.instanceid,
      filter: this.filter(),
      sorting: this.sorting(),
      custom: this.custom(),
      isInitialized: this.isInitialized()
    }
  })

  @Output() instanceInitialized = new EventEmitter<IApiControl>();

  toModel?(data: TDto): TModel | Observable<TModel> {
    return data as unknown as TModel;
  };

  // abstract toDto(data: TModel): TDto;

  createInstanceEffectRef: EffectRef;

  constructor() {
    this.createInstanceEffectRef = effect(() => {
      const filter = this.filter();
      const sorting = this.sorting();
      const custom = this.custom();
      if (filter && sorting && custom) {
        this.createInstance().subscribe();
        this.createInstanceEffectRef.destroy();
      }
    }, {manualCleanup: true});

    this.apiInstance$.pipe(
      tap(apiInstance => {
        console.log('API Instance changed', apiInstance);
      }),
      takeUntilDestroyed()
    ).subscribe()
  }

  ngOnInit() {
    this.changeAndFetchInstance(this.changeInstanceParams)
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.deleteInstance();
    this.createInstanceEffectRef?.destroy();
  }

  private createInstance() {
    console.log('Creating instance ...', this.GUID);
    return this.controlService.controlPostInstance({
      _parameters: [
        this.GUID, this.filter(), this.sorting(), this.custom()
      ]
    }).pipe(
      tapResponse({
        next: (apiInstance) => {
          patchState(this.state, _ => ({
            apiInstance: apiInstance as IApiControl,
            isInitialized: true
          }))
          this.instanceInitialized.emit(apiInstance as IApiControl);
        },
        error: error => {
          console.error(error);
        }
      })
    )
  }

  readonly changeAndFetchInstance = rxMethod<void | Partial<{
    instanceId: string,
    filter: IFilter,
    sorting: object,
    custom: object,
    isInitialized: boolean
  }>>(
    pipe(
      filter(params => this.isInitialized()),
      tap((params) => {
        patchState(this.state, _ => ({loading: true}));
        console.log('Changing instance ...', params)
      }),
      concatMap(partialParams => {
        if (!partialParams) {
          partialParams = {};
        }
        const params = {
          instanceId: partialParams?.instanceId || this.apiInstance().instanceid,
          filter: partialParams?.filter || this.filter(),
          sorting: partialParams?.sorting || this.sorting(),
          custom: partialParams?.custom || this.custom(),
        }
        return this.queueRequest(
          this.controlService.controlPostChangeInstanceAndFetch({
            _parameters: [
              params.instanceId, params.filter, params.sorting, params.custom
            ]
          }).pipe(
            tap(apiData => patchState(this.state, _ => ({apiData: apiData as TDto}))),
            switchMap(dto => {
              const mapped = this.toModel(dto as TDto);
              if (isObservable(mapped)) {
                return mapped.pipe(map(v => ({data: v, apiData: dto})));
              } else {
                return of({data: mapped, apiData: dto})
              }
            }),
            tapResponse({
              next: (result) => {
                patchState(this.state, _ => ({
                  data: result.data as TModel,
                  isChanged: true,
                  loading: false
                }))
              },
              error: error => {
                console.error(error);
              }
            }),
            catchError(_ => {
              patchState(this.state, _ => ({loading: false}));
              return of(null);
            })
          )
        )
      })
    )
  )

  private deleteInstance() {
    if (this.apiInstance()?.instanceid == null) {
      return;
    }
    patchState(this.state, _ => ({loading: true}));
    this.queueRequest(
      this.controlService.controlDeleteInstance(this.apiInstance().instanceid).pipe(
        finalize(() => {
          patchState(this.state, _ => ({
            apiInstance: null,
            isDeleted: true,
            loading: false
          }))
        })
      )).subscribe()
  }

  /**
   * Method to prevent multiple api requests that trigger database transaction, possibly leading to an "open current transaction error"
   * @param apiRequest
   */
  protected queueRequest(apiRequest: Observable<any>): Observable<any> {
    return this.debouncer.queueRequest(apiRequest);
  }

  patchFilter(patch: IFilter) {
    patchState(this.state, state => ({filter: {...state.filter, ...patch}}));
  }

  setSorting(sorting: object) {
    patchState(this.state, _ => ({sorting}));
  }

  setCustom(custom: object) {
    patchState(this.state, _ => ({custom}));
  }
}
