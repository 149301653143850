<mat-form-field appearance="outline">
  @if (!hideLabel) {
    <mat-label>{{ label }}</mat-label>
  }
  <input matInput #inputRef
         placeholder="tt.mm.jjjj"
         name="dp" container="body"
         [formControl]="formControlElement"
         [required]="required"
         ngbDatepicker (keydown)="onKeyDown($event,d)" appRemoveDatepickerValidation
         (dateSelect)="dateChange()"
         (focusout)="dateChange()"
         (keyup.enter)="dateChange()"
         #d="ngbDatepicker"/>
  <button matSuffix mat-icon-button (click)="d.toggle()" type="button" class="icon-button-centered" tabindex="-1"
          style="width: 1.5em;
height: 1.5em;">
    <mat-icon svgIcon="{{EsvgFiles.calendar}}" class="blue-icon">
    </mat-icon>
  </button>

  <mat-error style="white-space: break-spaces">
    <ng-container *ngFor="let error of this.getErrorMessage()">
      {{ error }} <br>
    </ng-container>
  </mat-error>
</mat-form-field>
