import {ApiAdapter} from 'frontier/nucleus';
import {
  IForm,
  IFormElement,
  IFormGroup,
  IKeyToStyleMap
} from '../form-control/dynamic-form/form-element/form-data.interface';


export class FormApiAdapter extends ApiAdapter {
  styles: IKeyToStyleMap;

  from(apiData: IForm): IForm {
    console.log(apiData);

    apiData.formGroups.forEach((fg: IFormGroup) => {
      fg.elements.forEach((element: IFormElement) => {
        const elementStyles = this.styles[element.key];
        if (elementStyles) {
          element.styling = element.styling ? {...element.styling, ...elementStyles} : null
        }
        return elementStyles;
      })
    })

    return apiData;
  }

  to(data: any): any {
    return null;
  }
}
