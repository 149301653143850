import {AbstractControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export const passwordMatchValidator: ValidatorFn = (
  formGroup: AbstractControl
): ValidationErrors | null => {
  formGroup = formGroup as FormGroup;
  if (formGroup.get('password').value === formGroup.get('password2').value) {
    return null;
  } else {
    return {passwordMismatch: true};
  }
};
