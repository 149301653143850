<div mat-dialog-title>{{ data.title }}</div>
<mat-dialog-actions>
  <div [formGroup]="formGroup" class="formWrapper">
    <mat-form-field *ngIf="data.type === 'change'" appearance="outline">
      <mat-label>Altes Paswort</mat-label>
      <input formControlName="oldPassword" matInput type="password"/>
      <mat-error *ngIf="oldPassword.hasError('required')"
      >Das alte Passwort bitte eingeben.
      </mat-error
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Neues Passwort</mat-label>
      <input
        (input)="onPasswordInput()"
        formControlName="password"
        matInput
        type="password"
      />
      <mat-error *ngIf="password.hasError('required')"
      >Passwort muss gesetzt sein
      </mat-error
      >
      <!--      <mat-error *ngIf="password.hasError('minlength')">Passwort muss mindestens {{minPw}} Zeichen lang sein</mat-error>-->
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Passwort wiederholen</mat-label>
      <input
        (input)="onPasswordInput()"
        formControlName="password2"
        matInput
        type="password"
      />
      <mat-error *ngIf="password2.hasError('required')"
      >Passwort bitte bestätigen
      </mat-error
      >
      <mat-error *ngIf="password2.invalid && !password2.hasError('required')"
      >Passwörter stimmen nicht überein
      </mat-error>
    </mat-form-field>
    <div
      style="
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
      "
    >
      <app-save-icon-button
        (clicked)="onConfirm()"
        [confirmDisabled]="confirmDisabled"
        [disabled]="!formGroup.valid"
        appConfirmButton
      >
      </app-save-icon-button>
      <app-cancel-icon-button mat-dialog-close></app-cancel-icon-button>
    </div>
  </div>
</mat-dialog-actions>
