import {Component, OnInit} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {IEnumCellParams} from './enum-cell-params.interface';
import {EsvgFiles} from 'frontier/nucleus';
import {CellErrorIconComponent} from '../../cell-error-icon/cell-error-icon.component';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';
import {
  RendererContainerComponent
} from 'frontier/browserkit/src/lib/components/control/table-control/renderers/renderer-container/renderer-container.component';

@Component({
  selector: 'app-enum-cell-renderer',
  templateUrl: './enum-cell-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
  styles: [
    `

         `
  ],
  standalone: true,
  imports: [MatTooltip, NgIf, MatIcon, CellErrorIconComponent, RendererContainerComponent]
})
export class EnumCellRendererComponent extends CustomCellComponent implements OnInit {
  override params: IEnumCellParams;

  getValueFromRepository(): string {
    if (this.params.value) {
      if (this.params.isMulti) {
        return this.params.value.value.map((v: any) => {
          return (this.params.colDef as any).repository[v];
        });
      } else {
        return (this.params.colDef as any).repository[this.params.value.value];
      }
    } else {
      return '';
    }
  }


  cellEditable() {
    return this.params && this.params.value ? this.params.value.editable : true;
  }

  protected readonly EsvgFiles = EsvgFiles;
}
