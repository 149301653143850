import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {BaseFormElementComponent} from '../base-form-element.class';
import {AbstractControl, FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Subscription} from 'rxjs';
import {EsvgFiles} from 'frontier/nucleus';
import {debounceTime, tap} from 'rxjs/operators';
import {MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipGrid, MatChipInput, MatChipInputEvent, MatChipRemove, MatChipRow} from '@angular/material/chips';
import {ISearchFormElement} from '../form-data.interface';
import {IFilteredEntity} from './filtered-entity.interface';
import {MatOption} from '@angular/material/core';
import {MatInput} from '@angular/material/input';
import {MatIcon} from '@angular/material/icon';
import {NgFor, NgIf} from '@angular/common';
import {MatFormField, MatLabel} from '@angular/material/form-field';

@Component({
  selector: 'kpi4me-search-element',
  templateUrl: './search-element.component.html',
  styleUrls: ['./search-element.component.scss', '../form-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    MatFormField,
    MatLabel,
    NgIf,
    MatChipGrid,
    NgFor,
    MatChipRow,
    MatChipRemove,
    MatIcon,
    MatInput,
    FormsModule,
    MatAutocompleteTrigger,
    MatChipInput,
    ReactiveFormsModule,
    MatAutocomplete,
    MatOption,
  ],
})
export class SearchElementComponent extends BaseFormElementComponent {
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('input') inputRef: ElementRef<HTMLInputElement>;

  @Input() multiple = true;

  @Input() filteredEntities: IFilteredEntity[] = [];
  @Output() textChange = new EventEmitter();

  @Output() textSelected = new EventEmitter();

  subs = new Subscription();
  entities: any[] = [];

  edited = false;

  textForm = new FormControl('');

  displayWith = (value: any) => {
    if (value) {
      return typeof value === 'string' ? value : value.label;
    }
  };

  @HostListener('keydown.enter', ['$event']) onEnter() {
    if (this.edited) {
      // this.onOptionSelected($event)
    }
  }

  @HostListener('focusout', ['$event']) onBlur() {
    // if (this.edited) {
    //   this.onOptionSelected()
    // }
  }

  override get formControlElement(): FormControl<IFilteredEntity[]> {
    return super.formControlElement;
  }

  @Input()
  override set formControlElement(c: AbstractControl) {
    this._formControlElement = c;
  }

  @Input()
  override set data(form: ISearchFormElement) {
    this.multiple = form.multiple;
    super.data = form;
  }

  constructor() {
    super();
    this.subs.add(this.textForm.valueChanges.pipe(
      tap(v => console.log(v)),
      debounceTime(500),
      tap(value => {
        this.textChange.emit(value)
      }),
    ).subscribe());
  }

  onOptionSelected(evt: MatAutocompleteSelectedEvent) {
    this._formControlElement.value.push(evt.option.value);
    this.inputRef.nativeElement.value = '';
    this.textForm.setValue(null);
    this.modelChange.emit(this._formControlElement.value);
  }

  add(event: MatChipInputEvent): void {
    this.textSelected.emit(event);
    // const value = (event.value || '').trim();
    // console.log('add event')
    // // Add our fruit
    // if (value) {
    //   this._formControlElement.value.push(value);
    // }
    //
    // // Clear the input value
    // event.chipInput?.clear();
    //
    // this.textForm.setValue(null);
  }

  remove(entity: IFilteredEntity): void {
    const index = this._formControlElement.value.indexOf(entity);

    if (index >= 0) {
      this._formControlElement.value.splice(index, 1);
    }

    this.modelChange.emit(this._formControlElement.value);
  }

  protected readonly EsvgFiles = EsvgFiles;
}
