<div (keydown.enter)="onEnter()" class="mat-elevation-z0 login-wrapper">
  <div class="logo-wrapper">
    <img class="big-logo" src="assets/images/big-logo.png"/>
  </div>
<!--  <mat-card-subtitle style="margin-bottom: 2em">-->
<!--    <div class="version-number">V {{ loginService.env.globalVersion }}</div>-->
<!--  </mat-card-subtitle>-->
  <mat-card-content>
    <form [formGroup]="form">
      <!--      <div *ngIf="submitted && getForm.username.errors" class="invalid-feedback">-->
      <!--        <div *ngIf="getForm.username.errors.required">Geben Sie einen gültigen Nutzernamen ein</div>-->
      <!--      </div>-->
<!--      <mat-form-field appearance="outline">-->
<!--        <mat-label>Nutzername</mat-label>-->
<!--        <input formControlName="username" matInput name="username" required/>-->
<!--        <mat-error-->
<!--          *ngIf="getForm.username.errors && getForm.username.errors.required"-->
<!--        >Benutzernamen eingeben-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->
<!--      <br/><br/>-->
<!--      <mat-form-field appearance="outline">-->
<!--        <mat-label>Passwort</mat-label>-->
<!--        <input-->
<!--          formControlName="password"-->
<!--          matInput-->
<!--          name="password"-->
<!--          required-->
<!--          type="password"-->
<!--        />-->
<!--        <mat-error-->
<!--          *ngIf="getForm.password.errors && getForm.password.errors.required"-->
<!--        >Passwort eingeben-->
<!--        </mat-error>-->
<!--      </mat-form-field>-->
    </form>
  </mat-card-content>
  <mat-card-actions class="LoginCardActions">
<!--    <button (click)="login()" mat-raised-button>Login</button>-->
    <button (click)="singleSignOnLogin()" mat-raised-button>Anmelden</button>
    <!--    <a routerLink="/forgotlogindata" routerLinkActive="active"-->
    <!--      >Passwort zurücksetzen</a-->
    <!--    >-->
  </mat-card-actions>
</div>
