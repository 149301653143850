import {Component, ViewEncapsulation} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {ICellRendererParams} from "ag-grid-community";
import {CellErrorIconComponent} from '../../cell-error-icon/cell-error-icon.component';
import {MatTooltip} from '@angular/material/tooltip';
import {
  RendererContainerComponent
} from 'frontier/browserkit/src/lib/components/control/table-control/renderers/renderer-container/renderer-container.component';

interface ICurrencyCellRendererParams extends ICellRendererParams {
  isNegativeCurrencyRed?: boolean;
}

@Component({
  selector: 'kpi4me-currency-cell-renderer',
  templateUrl: './currency-cell-renderer.component.html',
  styleUrls: ['./currency-cell-renderer.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [MatTooltip, CellErrorIconComponent, RendererContainerComponent],
})
export class CurrencyCellRendererComponent extends CustomCellComponent {
  private negativCurrenyRegex = /-\s?(([0-9]*)|(.))*(,)?[0-9]*(\s?)(€)/;
  override params: ICurrencyCellRendererParams;

  isNegativeCurrency(): boolean {
    const value = this.params && this.params.value ? this.params.value.value : ''
    return !!value.match(this.negativCurrenyRegex)?.includes(value);
  }
}
