import {Component, inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent} from '@angular/material/dialog';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {BaseDialogComponent} from 'frontier/browserkit/src/lib/dialogs/base-dialog/base-dialog.component';
import {IDialogData} from 'frontier/browserkit/src/lib/dialogs/base-dialog/dialog-data.interface';
import {ConfirmIconButtonComponent} from 'frontier/browserkit/src/lib/components/confirm-icon-button/confirm-icon-button.component';
import {MatInput} from '@angular/material/input';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {DialogHeaderComponent} from 'frontier/browserkit/src/lib/components/dialog-header/dialog-header.component';

interface INodeEditorDialogData extends IDialogData {
  title: string;
  name: string;
}

@Component({
  selector: 'app-node-editor-dialog',
  templateUrl: './node-editor-dialog.component.html',
  styleUrls: ['./node-editor-dialog.component.scss'],
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatDialogActions,
    ConfirmIconButtonComponent,
  ],
})
export class NodeEditorDialogComponent extends BaseDialogComponent implements OnInit {
  override data = inject(MAT_DIALOG_DATA) as INodeEditorDialogData;

  form = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });

  ngOnInit(): void {
    if (this.data && this.data.name) {
      this.form.patchValue({name: this.data.name});
    }
  }

  onConfirm() {
    this.confirm();
  }

  protected getDialogOutput(): INodeEditorDialogData {
    return {
      ...this.data,
      ...this.form.value,
    };
  }

  protected isValid(): boolean {
    return this.form.valid;
  }

  protected loadForm(storedData: INodeEditorDialogData): void {
    this.form.patchValue(storedData);
  }

  protected getForm(): any {
    return this.form.value as Partial<{ name: string }>;
  }
}
