import {Component, ElementRef, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {EsvgFiles, SvgService} from 'frontier/nucleus';
import {IUploadDocument} from 'frontier/browserkit/src/lib/components/upload-wrapper/uploaded-document.interface';
import {MatIconButton} from '@angular/material/button';
import {MatProgressBar} from '@angular/material/progress-bar';
import {NgIf} from '@angular/common';
import {MatChipListbox, MatChipOption} from '@angular/material/chips';
import {MatIcon} from '@angular/material/icon';
import {DropUploadDirective} from './drop-upload.directive';

@Component({
  selector: 'app-upload-wrapper',
  templateUrl: './upload-wrapper.component.html',
  styleUrls: ['./upload-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UploadWrapperComponent,
    },
  ],
  standalone: true,
  imports: [
    DropUploadDirective,
    MatIcon,
    MatChipListbox,
    MatChipOption,
    NgIf,
    MatProgressBar,
    MatIconButton,
  ],
})
export class UploadWrapperComponent implements ControlValueAccessor {
  @ViewChild('fileUpload') fileUploadRef: ElementRef;

  @Input() readAs: 'ArrayBuffer' | 'Text' | 'Image' = 'ArrayBuffer';
  @Input() accept: string;

  @Input() maxSize: number;
  @Input() uploading = false;
  @Output() changed = new EventEmitter();

  document: IUploadDocument =
    null;
  private touched: boolean;

  onChange: (document: IUploadDocument) => void;
  onTouched: () => void;

  constructor(private svgService: SvgService) {
  }

  get fileName(): string {
    if (this.document && this.document.name) {
      const array = this.document.name.split('\\');
      return array[array.length - 1];
    }
    return null;
  }

  onUploadClick() {
    this.fileUploadRef.nativeElement.click();
  }

  private readFile(fileRead: any): void {
    const reader: FileReader = new FileReader();
    reader.onloadend = (e: ProgressEvent<FileReader>) => {
      console.log(reader.result);
      // Ergebnis vom FileReader auslesen
      const fileNameSplit = fileRead.name.split('.');
      this.document = {
        name: fileRead.name,
        data: e.target.result,
        fileFormate: fileNameSplit?.length > 0 ? fileNameSplit[fileNameSplit.length - 1] : '',
      };
      this.onChange(this.document);
      this.changed.emit(this.document); // Emit the change event
      this.markAsTouched();
    };

    switch (this.readAs) {
      case 'Image':
        reader.readAsDataURL(fileRead);
        break;
      case 'ArrayBuffer':
        reader.readAsArrayBuffer(fileRead);
        break;
      case 'Text':
        reader.readAsText(fileRead, 'UTF-8');
        break;
    }
  }

  onFileDrop(evt: FileList) {
    this.fileUploadRef.nativeElement.files = evt;

    const dT = new DataTransfer();
    dT.items.add(evt[0]);
    this.fileUploadRef.nativeElement.files = dT.files;

    this.readFile(evt[0]);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(obj: any): void {
    this.document = obj;
  }

  addAttachment(evt: Event): void {
    const fileRead = (evt.target as any).files[0];
    console.log('add attachment');
    this.readFile(fileRead);
  }

  onDeleteImage() {
    this.document = null;
    this.onChange(this.document);
    this.fileUploadRef.nativeElement.value = '';
    this.uploading = false;
    this.changed.emit(this.document); // Emit the change event
  }

  protected readonly EsvgFiles = EsvgFiles;
}
