import {Component, HostListener, ViewChild, ViewEncapsulation} from '@angular/core';
import {ITableVerticalDataRow} from '../../table-vertical-api-adapter';
import {BaseCellEditorComponent} from '../base-cell-editor/base-cell-editor.component';
import {FormControl} from '@angular/forms';
import {EControlActions, IControlObject} from 'frontier/nucleus';
import {
  SelectElementComponent
} from '../../../form-control/dynamic-form/form-element/select-element/select-element.component';
import {
  ISelectFormElement,
  ISelectFormOption
} from '../../../form-control/dynamic-form/form-element/form-data.interface';
import {MatTooltip} from '@angular/material/tooltip';

@Component({
  selector: 'kpi4me-repository-selection-cell-editor',
  templateUrl: './repository-selection-cell-editor.component.html',
  styleUrls: ['./repository-selection-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [SelectElementComponent, MatTooltip],
})
export class RepositorySelectionCellEditorComponent extends BaseCellEditorComponent {
  @ViewChild(SelectElementComponent) selectElement: SelectElementComponent;

  override formControl: FormControl<IControlObject>;

  selectElementData: ISelectFormElement;

  @HostListener('keydown.enter', ['$event'])
  override onEnter(event: KeyboardEvent) {
  }

  override set row(r: ITableVerticalDataRow) {
    this.selectElementData = {options: r.repository};
    super.row = r;
  }

  override onBlur() {
    return;
  }

  override get row(): ITableVerticalDataRow {
    return super.row;
  }

  override getValueForApi = () => {
    return this.formControl.value || null;
  }

  onNewElement(event: string) {
    this._tableControlService.tableControlNewSelectionEntry({
      InstanceId: this.row.apiInstance.instanceid,
      name: event,
      attributename: this.row.attribute,
      RowObject: this.row.apiRow.obj
    }).subscribe((repo) => {
        if (repo) {
          this.selectElement.options = repo.map((repoObject: IControlObject) => {
            let option: ISelectFormOption = {
              value: repoObject,
              name: repoObject.name
            }
            return option;
          });
          this.cdr.detectChanges();
        }

        this.selectionDataChanged.emit({GUID: null, changeType: EControlActions.newSelectionEntry});
      }
    )
  }

  onDeleteElement(event: ISelectFormOption) {
    this._tableControlService.tableControlDeleteSelectionEntry({
      InstanceId: this.row.apiInstance.instanceid,
      attribute: event.value,
      attributename: this.row.attribute,
      RowObject: {
        type: this.row.apiInstance.type,
        rowid: this.row.apiInstance.rowid,
        signature: this.row.apiInstance.signature
      },
    }).subscribe(() => {
      this.selectElementData = {
        ...this.selectElementData,
        options: this.selectElementData.options.filter(obj => {
          return obj.signature !== event.value.signature
        })
      };
      this.updateTable.emit();
      this.cdr.detectChanges();
      this.selectionDataChanged.emit({GUID: null, changeType: EControlActions.deleteSelectionEntry});
    })
  }

  onUpdateTable() {
    this.updateTable.emit();
  }

  onChangeElement(event: { oldEntry: { value: ISelectFormOption, name: string }, newName: string }) {
    this._tableControlService.tableControlChangeSelectionEntry({
      InstanceId: this.row.apiInstance.instanceid,
      name: event.newName,
      attribute: event.oldEntry.value,
      attributename: this.row.attribute,
      RowObject: {
        type: this.row.apiInstance.type,
        rowid: this.row.apiInstance.rowid,
        signature: this.row.apiInstance.signature
      },
    }).subscribe((_) => {
      const repo = _ as IControlObject[];
      this.selectElementData = {...this.selectElementData, options: repo as ISelectFormOption[]};
      this.formControl.patchValue(repo.find(o => o.name === event.newName));
      this.confirmChange();
      this.updateTable.emit();
      this.selectionDataChanged.emit({GUID: null, changeType: EControlActions.changeSelectionEntry});
    })
  }

  getErrorMessage(): string {
    if (this.row.invalidMessage) {
      this.formControl.setErrors({linkedTelFieldsError: true});
      return 'Sie müssen zu einer Telefonnummer auch stets die Art des Anschlusses angeben.';
    }
    return '';
  }
}
