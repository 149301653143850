import {Route} from '@angular/router';

export const routes: Route[] = [
  {
    path: 'home',
    loadComponent: () => import('../home/home.component').then(m => m.HomeComponent)
  },
  {
    path: 'login',
    loadComponent: () => import('frontier/browserkit/src/lib/views/login/login.component')
      .then(m => m.LoginComponent),
    data: {nextRoute: 'home'}
  },
  {
    path: 'sso',
    loadComponent: () => import('frontier/browserkit/src/lib/authentication/keycloak-redirect/keycloak-redirect.component')
      .then(m => m.KeycloakRedirectComponent), pathMatch: 'prefix'
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'trapo',
    loadComponent: () => import('../home/views/go-to-trapo/go-to-trapo.component').then(m => m.GoToTrapoComponent)
  },
  {
    path: 'openitem/:id',
    loadComponent: () => import('../home/home.component').then(m => m.HomeComponent)
  },
]
