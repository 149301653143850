<form class="search-form">
  <mat-icon svgIcon="{{EsvgFiles.search}}" class="blue-icon"></mat-icon>
  <input
    #searchInput
    [formControl]="control"
    (ngModelChange)="this.ngModelChange.emit($event)"
    (keydown)="onKeyPress($event)"
    class="search-field"
    placeholder="Suche..."
    name="searchForm"
    autocomplete="off"
  >
</form>
