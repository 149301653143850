<button
  (click)="rotateGranularity(granularity, $event)"
  [matTooltip]="'Granularität: ' + selectedGranularity()?.name"
  class="btn date-picker-button btn-outline-secondary granularity-button"
>
  <span style="margin: auto;">{{ selectedGranularity()?.shortcut }}</span>

  <mat-icon
    class="left-arrow-icon"
    matSuffix
    [svgIcon]="EsvgFiles.arrow_down"
  ></mat-icon>
</button>
<mat-select style="visibility: hidden; width: 0; height: 0; display: block;" [(ngModel)]="granularity"
            (selectionChange)="onGranularitySelectionChange($event)">
  <mat-option *ngFor="let g of granularities" [value]="g.id">
    {{ g.name }}
  </mat-option>
</mat-select>
