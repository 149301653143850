import {TColType} from './cell.enum';
import {ICustomColDef} from './interfaces/custom-col-def.interface';

export const ColTypeColDefMap: Record<number, ICustomColDef> = {
  // standard string cell
  [-1]: {
    cellRenderer: 'customCell',
    cellEditor: 'customCellEditor',
    filter: 'agTextColumnFilter',
  },
  // string cell with adjusted renderer for currency
  [TColType.clCurrency]: {
    cellRenderer: 'currencyCell',
    cellEditor: 'customCellEditor',
    filter: 'agTextColumnFilter',
  },
  // reference to another object --> tree for selection == foreign key reference
  [TColType.clReference]: {
    cellRenderer: 'referenceCell',
    cellEditor: 'referenceCellEditor',
    filter: 'agTextColumnFilter',
  },
  // reference cell with multi-select
  [TColType.clRefSelection]: {
    cellRenderer: 'referenceSelectionCell',
    cellEditor: 'referenceCellEditor',
    filter: 'agTextColumnFilter',
  },
  // time range in time plan table
  [TColType.clTimeRangeString]: {
    cellRenderer: 'customCell',
    cellEditor: 'timeRangeCellEditor',
    filter: 'agTextColumnFilter',
  },
  // date cell
  [TColType.clDateTime]: {
    cellRenderer: 'dateCell',
    cellEditor: 'dateCellEditor',
    filter: 'agDateColumnFilter',
  },
  // type cell, e.g. state
  [TColType.clEnum]: {
    cellRenderer: 'enumCell',
    cellEditor: 'enumCellEditor',
    filter: 'agTextColumnFilter',
    cellRendererParams: {
      isMulti: false,
    },
    cellEditorParams: {
      isMulti: false,
    },
  },
  // type cell, e.g. state
  [TColType.clEnumMulti]: {
    cellRenderer: 'enumCell',
    cellEditor: 'enumCellEditor',
    filter: 'agTextColumnFilter',
    cellRendererParams: {
      isMulti: true,
    },
    cellEditorParams: {
      isMulti: true,
    },
  },
  [TColType.clColor]: {
    cellRenderer: 'colorCellRenderer',
    cellEditor: 'colorCellEditor',
  },
  [TColType.clValue]: {
    // float values cols
    cellRenderer: 'customCell',
    cellEditor: 'customCellEditor',
    filter: 'agNumberColumnFilter',
  },
  [TColType.clNumber]: {
    // inter values cols
    cellRenderer: 'customCell',
    cellEditor: 'customCellEditor',
    filter: 'agNumberColumnFilter',
  },
  [TColType.clPercentage]: {
    // inter values cols
    cellRenderer: 'percentageCellRenderer',
    cellEditor: 'percentageCellEditor',
    filter: 'agNumberColumnFilter',
  },
  [TColType.clTimeOfDay]: {
    cellRenderer: 'timeOfDayCellRenderer',
    cellEditor: 'timeOfDayCellEditor',
  },
  [TColType.clBool]: {
    // boolean values
    cellRenderer: 'boolCellRenderer',
    cellEditor: 'boolCellEditor',
    filter: 'agNumberColumnFilter',
  },
  [TColType.document]: {
    cellRenderer: 'documentCellRenderer',
    hasEditor: false,
    filter: null,
  },
  [TColType.clDocument]: {
    cellRenderer: 'documentCellRenderer',
    hasEditor: false,
    filter: null,
  },
  [TColType.clAutoComplete]: {
    cellRenderer: 'autocompleteRenderer',
    cellEditor: 'autocompleteEditor',
  }
};
