import {EventEmitter, Injectable} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {EControlActions} from 'frontier/nucleus';

export interface IControlDataChanged {
  GUID: string;
  changeType: EControlActions;
}

@Injectable({
  providedIn: 'root'
})
export class ControlStore {

  controlDataChanged$ = new EventEmitter<IControlDataChanged>();
  controlDataChangedSignal = toSignal(this.controlDataChanged$);
  tenantChanged$ = new EventEmitter();

  constructor() {
  }
}
