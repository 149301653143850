import {Component, EventEmitter, inject, Input, Output, ViewEncapsulation} from '@angular/core';
import {BaseControlComponent, DefaultApiAdapter, IControlObject} from 'frontier/nucleus';
import {Observable, tap} from 'rxjs';
import {CrudControlService} from 'frontier/nucleus/src/lib/apiv2/generated/api/crudControl.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'crud-control',
  templateUrl: './crud-control.component.html',
  styleUrls: ['./crud-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
})
export class CrudControlComponent extends BaseControlComponent {
  private _crudService = inject(CrudControlService);

  private objectData: any;

  @Input() set crudObject(o: IControlObject) {
    if (o == null) return;

    // read the data of the object
    this.read(o).pipe(
      tap(data => {
        this.objectData = data;
        this.dataChange.emit(data);
      })
    );
  }

  // if new crud data is read Output it.
  @Output() dataChange = new EventEmitter();

  apiAdapter = new DefaultApiAdapter();

  create(o: any): Observable<any> {
    return this._crudService.crudControlCreateObject({
      InstanceId: this.apiInstance.instanceid,
      InitializeWith: o
    })
  }

  read(o: IControlObject): Observable<any> {
    return this._crudService.crudControlGetObject({
        InstanceId: this.apiInstance.instanceid, DBObject: o
      }
    )
  }

  update(o: IControlObject, newcontent: any): Observable<any> {
    return this._crudService.crudControlChangeObject({
        InstanceId: this.apiInstance.instanceid,
        DBObject: o,
        ModifiedObject: newcontent
      }
    )
  }

  delete(o: IControlObject): Observable<any> {
    return this._crudService.crudControlGetObject({
        InstanceId: this.apiInstance.instanceid,
        DBObject: o
      }
    )
  }
}
