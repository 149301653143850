import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {DateTime} from 'luxon';

// Might need to polyfill depending on needed browser support...
const isInt = Number.isInteger;

export function ToNgbDate(date: DateTime): NgbDate {
  if (!date || !date.isValid) {
    return null;
  }

  return new NgbDate(date.year, date.month, date.day);
}

export function FromNgbDate(date: NgbDate): DateTime {
  if (!date || !isInt(date.year) || !isInt(date.month) || !isInt(date.day)) {
    return null;
  }

  return DateTime.fromObject({
    year: date.year,
    month: date.month,
    day: date.day,
  });
}
