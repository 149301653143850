import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {ISelectableEntity} from './selectable-entity.interface';
import {MatListOption, MatSelectionList} from '@angular/material/list';
import {IEntitySelectorDialogData} from './entity-selector-data.interface';
import {ConfirmIconButtonComponent} from 'frontier/browserkit/src/lib/components/confirm-icon-button/confirm-icon-button.component';
import {NgFor} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {DialogHeaderComponent} from 'frontier/browserkit/src/lib/components/dialog-header/dialog-header.component';

@Component({
  selector: 'app-entity-selector',
  templateUrl: './entity-selector.component.html',
  styleUrls: ['./entity-selector.component.scss'],
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatDialogContent,
    MatSelectionList,
    FormsModule,
    NgFor,
    MatListOption,
    MatDialogActions,
    ConfirmIconButtonComponent,
  ],
})
export class EntitySelectorComponent implements OnInit {
  @ViewChild(MatSelectionList) matSelectionList: MatSelectionList;
  selectedEntities: ISelectableEntity[] = [];
  multiple: boolean = false;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EntitySelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEntitySelectorDialogData
  ) {
  }

  ngOnInit(): void {
    console.log(this.data);
  }

  onConfirm() {
    this.dialogRef.close(this.selectedEntities);
  }
}
