import {ITableAction} from './interfaces/actions.interface';
import {EControlActions, EsvgFiles} from 'frontier/nucleus';

export const CSV_EXPORT_ACTION: ITableAction = {
  displayName: 'CSV-Export',
  controlAction: EControlActions.csvExport,
  disabledIfNoSelection: false,
  disabledIfMultiSelection: false,
  color: 'primary',
  icon: EsvgFiles.download,
};

export const CSV_IMPORT_ACTION: ITableAction = {
  displayName: 'CSV-Import',
  controlAction: EControlActions.csvImport,
  disabledIfNoSelection: false,
  disabledIfMultiSelection: false,
  color: 'primary',
  icon: EsvgFiles.upload,
};

export const NEW_ENTRY_ACTION: ITableAction = {
  displayName: 'Neuer Eintrag',
  controlAction: EControlActions.create,
  disabledIfNoSelection: false,
  disabledIfMultiSelection: false,
  color: 'accent',
  icon: EsvgFiles.add,
};

export const DUPLICATE_ACTION: ITableAction = {
  displayName: 'Duplizieren',
  controlAction: EControlActions.copy,
  disabledIfNoSelection: true,
  disabledIfMultiSelection: false,
  color: 'accent',
  icon: EsvgFiles.copy,
}

export const DELETE_ACTION: ITableAction = {
  displayName: 'Löschen',
    controlAction: EControlActions.delete,
    disabledIfNoSelection: true,
    disabledIfMultiSelection: false,
    color: 'warn',
    icon: EsvgFiles.delete,
}

export const DEFAULT_TABLE_ACTIONS: ITableAction[] = [
  NEW_ENTRY_ACTION,
  DELETE_ACTION,
  CSV_EXPORT_ACTION,
];
