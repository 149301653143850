import {Component} from '@angular/core';
import {AgFilterComponent} from 'ag-grid-angular';
import {AgPromise, IDoesFilterPassParams, IFilterParams,} from 'ag-grid-community';
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';
import {TreeControlComponent} from '../../../tree-control/tree-control.component';

interface ICustomFilterParams extends IFilterParams {
  colDef: ICustomColDef;
}

@Component({
  selector: 'app-repository-filter',
  templateUrl: './repository-filter.component.html',
  styleUrls: ['./repository-filter.component.scss'],
  standalone: true,
  imports: [TreeControlComponent],
})
export class RepositoryFilterComponent implements AgFilterComponent {
  params: ICustomFilterParams;

  constructor() {
  }

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    return false;
  }

  getModel(): any {
  }

  isFilterActive(): boolean {
    return false;
  }

  setModel(model: any): void | AgPromise<void> {
    return undefined;
  }
}
