import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {Injectable} from '@angular/core';

export enum EsvgFiles {
  bank = 'account_balance_FILL0_wght300_GRAD0_opsz24.svg',
  add = 'add_FILL0_wght300_GRAD0_opsz24.svg',
  car = 'airport_shuttle_FILL0_wght300_GRAD0_opsz24.svg',
  alternative = 'alt_route_24dp__FILL0_wght300_GRAD0_opsz24.svg',
  email = 'alternate_email_FILL0_wght300_GRAD0_opsz24.svg',
  chart_bars = 'bar_chart_FILL0_wght300_GRAD0_opsz24.svg',
  block = 'block_FILL0_wght300_GRAD0_opsz24.svg',
  bookmark = 'bookmark_FILL0_wght300_GRAD0_opsz24.svg',
  breaking_news = 'breaking_news_FILL0_wght400_GRAD0_opsz24.svg',
  calculate = 'calculate_FILL0_wght300_GRAD0_opsz24.svg',
  calendar = 'calendar_today_FILL0_wght300_GRAD0_opsz24.svg',
  campaign = 'campaign_FILL0_wght300_GRAD0_opsz24.svg',
  close = 'close_FILL0_wght300_GRAD0_opsz24.svg',
  copy = 'content_copy_FILL0_wght300_GRAD0_opsz24.svg',
  moon = 'dark_mode_FILL0_wght300_GRAD0_opsz24.svg',
  delete = 'delete_FILL0_wght300_GRAD0_opsz24.svg',
  document = 'description_FILL0_wght300_GRAD0_opsz24.svg',
  correct = 'done_FILL0_wght300_GRAD0_opsz24.svg',
  download = 'download_FILL0_wght300_GRAD0_opsz24.svg',
  edit = 'edit_FILL0_wght300_GRAD0_opsz24.svg',
  elderly = 'elderly_FILL0_wght300_GRAD0_opsz24.svg',
  error = 'error_FILL0_wght300_GRAD0_opsz24.svg',
  money = 'euro_FILL0_wght300_GRAD0_opsz24.svg',
  list = 'event_list_FILL0_wght300_GRAD0_opsz24.svg',
  list_checked = 'fact_check_FILL0_wght300_GRAD0_opsz24.svg',
  new = 'fiber_new_FILL0_wght300_GRAD0_opsz24.svg',
  filter = 'filter_alt_FILL0_wght300_GRAD0_opsz24.svg',
  folder = 'folder_open_FILL0_wght300_GRAD0_opsz24.svg',
  brush = 'format_paint_FILL0_wght300_GRAD0_opsz24.svg',
  fullscreen_close = 'fullscreen_exit_FILL0_wght300_GRAD0_opsz24.svg',
  group = 'group_FILL0_wght300_GRAD0_opsz24.svg',
  home = 'home_FILL0_wght300_GRAD0_opsz24.svg',
  hourglass = 'hourglass_empty_FILL0_wght300_GRAD0_opsz24.svg',
  info = 'info_FILL0_wght300_GRAD0_opsz24.svg',
  arrow_down = 'keyboard_arrow_down_FILL0_wght300_GRAD0_opsz24.svg',
  arrow_left = 'keyboard_arrow_left_FILL0_wght300_GRAD0_opsz24.svg',
  arrow_right = 'keyboard_arrow_right_FILL0_wght300_GRAD0_opsz24.svg',
  arrow_up = 'keyboard_arrow_up_FILL0_wght300_GRAD0_opsz24.svg',
  sun = 'light_mode_FILL0_wght300_GRAD0_opsz24.svg',
  link = 'link_FILL0_wght300_GRAD0_opsz24.svg',
  mail = 'mail_FILL0_wght300_GRAD0_opsz24.svg',
  mail_outgoing = 'outgoing_mail_FILL0_wght300_GRAD0_opsz24.svg',
  room = 'meeting_room_FILL0_wght300_GRAD0_opsz24.svg',
  menu = 'menu_FILL0_wght300_GRAD0_opsz24.svg',
  merge = 'merge_FILL0_wght300_GRAD0_opsz24.svg',
  launch = 'open_in_new_FILL0_wght300_GRAD0_opsz24.svg',
  employee = 'person_book_FILL0_wght300_GRAD0_opsz24.svg',
  user = 'person_FILL0_wght300_GRAD0_opsz24.svg',
  print = 'print_FILL0_wght300_GRAD0_opsz24.svg',
  pin = 'push_pin_FILL0_wght300_GRAD0_opsz24.svg',
  refresh = 'refresh_FILL0_wght300_GRAD0_opsz24.svg',
  reset = 'reset_tv_FILL0_wght300_GRAD0_opsz24.svg',
  save = 'save_FILL0_wght300_GRAD0_opsz24.svg',
  search = 'search_FILL0_wght300_GRAD0_opsz24.svg',
  settings = 'settings_FILL0_wght300_GRAD0_opsz24.svg',
  chart_lines = 'show_chart_FILL0_wght300_GRAD0_opsz24.svg',
  contract = 'signature_FILL0_wght300_GRAD0_opsz24.svg',
  robot = 'smart_toy_24dp_FILL0_wght300_GRAD0_opsz24.svg',
  splitscreen = 'splitscreen_FILL0_wght300_GRAD0_opsz24.svg',
  support = 'support_agent_FILL0_wght300_GRAD0_opsz24.svg',
  table = 'table_FILL0_wght300_GRAD0_opsz24.svg',
  timeout = 'timer_off_FILL0_wght300_GRAD0_opsz24.svg',
  upload = 'upload_FILL0_wght300_GRAD0_opsz24.svg',
  verification = 'verified_FILL0_wght300_GRAD0_opsz24.svg',
  warning = 'warning_FILL0_wght300_GRAD0_opsz24.svg',
  back = 'arrow_back_FILL0_wght400_GRAD0_opsz24.svg',
  help = 'help_24dp_FILL0_wght400_GRAD0_opsz24.svg'
}

const svgFiles = [
  'clear',
  'settings',
  'delete',
  'error',
  'add_new_report',
  'edit',
  'refresh',
  'info',
  'search',
  'switchOrder',
  'committed',
  'accept',
  'reject',
  'upcoming',
  'merge',
  'read',
  'close',
  'revert',
  'recycle_bin',
  'left',
  'differentiate_kpi',
  'expand',
  'put_together',
  'arrow_left',
  'remove',
  'add_to_list',
  'add',
  'rename',
  'home',
  'folder',
  'subscribe',
  'unsubscribe',
  'receive',
  'deprecated',
  'visibility_off',
  'visibility',
  'sort',
  'warning',
  'groups',
  'startpage',
  'circle',
  'collapse',
  'unpublished',
  'launch',
  'send_to_examine',
  'user',
  'log_out',
  'help',
  'mail',
  'geo',
  'show_graphs',
  'menu',
  'structure',
  'show_manual',
  'manual_download',
  'web_help',
  'password',
  'transfer_format',
  'copy_to_clipboard',
  'copy2',
  'shared_report',
  'chart',
  'kpi_roll',
  'save',
  'filter',
  'benchmark_kpi',
  'support',
  'video_help',
  'set_roles',
  'unfold',
  'upload_file',
  'description',
  'webhooks',
  'trigger',
  'benchmark_kpi',
  'calendar',
  'kpi',
  'dashboard',
  'unpublished',
  'add_new_report',
  'bar_chart',
  'stacked_bar_chart',
  'line_diagram',
  'pie_chart',
  'table_chart',
  'area_chart',
  'simulation',
  'differentiate_kpi_3',
  'slider_handle',
  'map',
  'create_kpi',
  'create_kpi_group',
  'create_benchmark',
  'kpi_group',
  'reset_layout',
  'dark_mode',
  'light_mode',
  'histogram',
  'link',
  'contract',
  'contract_template',
  'upload_filled',
  'user_filled',
  'elderly',
  'user_group',
  'forms',
  'chart_column',
  'service_provider',
  'email_at',
  'support_2',
  'mail_template',
  'paper_plane',
  'table_solid',
  'log_out_fa',
  'log_out_fa_thin',
  'campaign',
  'chart_line',
  'edit_fa',
  'delete_fa',
  'search_fa',
  'info_fa',
  'pause',
  'groups2',
  'reload',
  'car',
  'cartype',
  'construction',
  'eventfinance',
  'financialaccount',
  'qualificationdemand',
  'qualification',
  'room',
  'courses',
  'employee',
  'eventtypes',
  'bookmark',
  'admin',
];

@Injectable({
  providedIn: 'root',
})
export class SvgService {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    this.init();
  }

  init() {
    // 1st argument: icon-label of type string
    // 2nd argument: The second argument is the relative URL path pointing to the location of the icon. This is of type SafeResourceUrl.
    // To parse the url path string into SafeResourceUrl, we can make use of the DomSanitizer provided by Angular.
    // Import DomSanitizer and inject it into your component. And put into constructor.
    // Dann kann die svg-Grafik folgendermaßen verwendet werden:
    // *** <mat-icon svgIcon='foo'></mat-icon> ***
    // *******************************************

    svgFiles.forEach((fileName) => {
      this._matIconRegistry.addSvgIcon(
        fileName,
        this._domSanitizer.bypassSecurityTrustResourceUrl(
          '../assets/svg/' + fileName + '.svg'
        )
      );
    });

    const eSvgFileValues = Object.values(EsvgFiles);
    eSvgFileValues.forEach((fileName) => {
      this._matIconRegistry.addSvgIcon(
        fileName,
        this._domSanitizer.bypassSecurityTrustResourceUrl(
          '../assets/svg_new/' + fileName
        )
      );
    });
  }
}
