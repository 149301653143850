import {Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation,} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {IForm, IFormElement, IFormGroup, TAnyFormElement} from './form-element/form-data.interface';
import {Subscription} from 'rxjs';
import {MatButton} from '@angular/material/button';
import {FormElementComponent} from './form-element/form-element.component';
import {MatDivider} from '@angular/material/divider';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {IAction, IFormChange} from "frontier/browserkit";
import {EControlActions} from "frontier/nucleus";
import {
  DynamicFormActionComponent
} from 'frontier/browserkit/src/lib/components/control/form-control/dynamic-form/dynamic-form-action/dynamic-form-action.component';

@Component({
  selector: 'kpi4me-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    MatIcon,
    MatDivider,
    FormElementComponent,
    MatButton,
    DynamicFormActionComponent,
  ],
})
export class DynamicFormComponent implements OnDestroy {

  constructor() {
  }

  @Output() formChange: EventEmitter<IFormChange> = new EventEmitter<IFormChange>();
  @Output() callActionFunction: EventEmitter<EControlActions> = new EventEmitter<EControlActions>();
  @Input() displayHeaders: boolean = true;
  @Input() standardForm: boolean;
  @Input() hasImports: boolean = false;
  @Input() showImports: boolean = false;

  private subs: Subscription = new Subscription();

  private _formData: IForm;
  form: FormGroup;

  private controlFormObjects: any = {};
  @Input() actions: IAction[] = [];

  @Input() set formData(form: IForm) {
    if (form == null) return;

    console.log('Received form: ', form);

    this._formData = form;

    if (this.standardForm) {
      this._formData = form;
    } else {
      this._formData = {
        confirmable: form.confirmable,
        formGroups: form.formGroups.map((fg: IFormGroup) => {
          return {
            ...fg,
            elements: fg.elements.map((el: IFormElement) => {
              return {
                ...el,
                tableLabel: el.label || el.tableLabel, // if label is not set, use tableLabel. This is the case on cell value changes
                label: null,
              };
            }),
          };
        }),
      };
    }

    const group: any = {};
    this.formData.formGroups.forEach((fg: IFormGroup) => {
      let maxCol: number = 1;
      fg.elements.forEach((baseElement: TAnyFormElement) => {
        maxCol = Math.max(maxCol, baseElement.styling?.columnEnd == null ? 1 : baseElement.styling.columnEnd);

        // save reference for remap
        this.controlFormObjects[baseElement.key] = baseElement;

        // create a form group json object
        group[baseElement.key] = new FormControl(baseElement.value != null ? baseElement.value : null);
        if (baseElement.required) {
          group[baseElement.key].addValidators([Validators.required]);
        }
      });
      fg.maxCol = String(maxCol - 1);
    })
    this.form = new FormGroup(group);
  }

  get formData() {
    return this._formData;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getTemplateColumns(fg: IFormGroup): string {
    if (fg.maxCol === null) {
      return '';
    }

    return 'repeat(' + fg.maxCol + ', 1fr)'
  }

  saveValue(fc: FormControl, fg: IFormGroup): void {
    console.log('onFocusOutOnFormControlElement');
    const controlName = this.getControlName(fc);

    // update local formData
    this.formData = {
      ...this.formData,
      formGroups: this.formData.formGroups.map((formGroup: IFormGroup): IFormGroup => {
        if (formGroup.header === fg.header) {
          return {
            ...fg,
            elements: fg.elements.map((el: IFormElement) => {
              if ((el.key) === controlName) {
                return {
                  ...el,
                  value: fc.value,
                };
              } else {
                return el;
              }
            })
          };
        } else {
          return formGroup;
        }
      }),
    };

    // map the form control to the initial db object and set the value to the input value
    const elements: TAnyFormElement[] = fg.elements.map((el: TAnyFormElement) => {
      return {
        ...this.controlFormObjects[el.key],
      };
    })
    // check if there are any changes and cancel otherwise
    if (elements.every((formEl: TAnyFormElement, index: number): boolean => formEl.value === fg.elements[index].value)) return;
    this.formChange.emit({elements, formGroup: fg, changedElement: controlName})
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup: any = c.parent.controls;
    return Object.keys(formGroup).find((name: string): boolean => c === formGroup[name]) || null;
  }
}
