import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {IHierarchyUploadDialogData} from './hierarchy-upload-dialog.interface';
import { ConfirmIconButtonComponent } from '../../../confirm-icon-button/confirm-icon-button.component';
import { DialogHeaderComponent } from '../../../dialog-header/dialog-header.component';
import { UploadWrapperComponent } from '../../../upload-wrapper/upload-wrapper.component';

@Component({
  selector: 'lib-upload-dialog',
  standalone: true,
  imports: [
    ConfirmIconButtonComponent,
    DialogHeaderComponent,
    MatDialogActions,
    MatDialogContent,
    MatFormField,
    MatInput,
    MatLabel,
    ReactiveFormsModule,
    MatSlideToggle,
    UploadWrapperComponent
  ],
  templateUrl: './hierarchy-upload-dialog.component.html',
  styleUrl: './hierarchy-upload-dialog.component.scss'
})
export class HierarchyUploadDialogComponent {
  data = inject(MAT_DIALOG_DATA) as IHierarchyUploadDialogData;
  dialogRef = inject(MatDialogRef<HierarchyUploadDialogComponent>);

  form = new FormGroup({
    file: new FormControl(null, [Validators.required]),
    asCopy: new FormControl(false),
  });

  confirm() {
    this.dialogRef.close({...this.form.value, file: this.form.value.file.data});
  }
}
