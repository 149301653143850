import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'kpi4me-create-new-entity',
  template: `

    <div class="create-new-option">
      <mat-icon class="green-icon" svgIcon="{{EsvgFiles.add}}" style="height: 100%;"></mat-icon>
      <span>{{ this.label }}</span>
    </div>

  `,
  styles: [
    `:host {
      display: block;
      width: 100%;
      padding: 4px 16px;
      margin-bottom: 0.5rem;
      white-space: nowrap;

      .create-new-option {
        cursor: pointer;
        display: flex;
        align-items: center;

      }
    }`,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  imports: [
    MatIconModule
  ],
  standalone: true
})
export class CreateNewEntityComponent {
  @Input() label = 'Neues Element';
  protected readonly EsvgFiles = EsvgFiles;
}
