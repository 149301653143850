import {Component, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ApiConfigService} from 'frontier/nucleus';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from '../authentication.service';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'kpi4me-keycloak-redirect',
  templateUrl: './keycloak-redirect.component.html',
  styleUrls: ['./keycloak-redirect.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [MatProgressSpinner],
})
export class KeycloakRedirectComponent implements OnDestroy {
  subs = new Subscription();

  constructor(private apiConfig: ApiConfigService, private route: ActivatedRoute, private authService: AuthenticationService,
              private router: Router) {
    this.subs.add(
      route.queryParams.pipe(
        tap(params => {
          let {callbackurl, refreshtoken} = params;
          const [callbackUrl, accessToken] = callbackurl.split('?');
          authService.storeBearerToken(accessToken, refreshtoken);
          router.navigate([callbackUrl]);
        })
      ).subscribe()
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
