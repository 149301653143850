<app-dialog-header
  (close)="dialogRef.close()"
  [dialogRef]="dialogRef"
  [title]="'Nutzer anlegen'"
>
</app-dialog-header>

<mat-dialog-content>
  <mat-form-field style="width: 100%">
    <mat-label>E-Mail-Adressen</mat-label>
    <textarea cdkTextareaAutosize [cdkAutosizeMinRows]="10"
              matInput [formControl]="form"></textarea>
  </mat-form-field>
  @for (line of form.value.split('\n'); let i = $index; track i) {
    @if (emailErrors[i]) {
      <div class="error-message">
        Zeile {{i + 1}}: {{ emailErrors[i] }}
      </div>
    }
  }
</mat-dialog-content>
<div mat-dialog-actions style="margin-top:1rem;">
  <app-confirm-icon-button (customClick)="confirm()" [disabled]="form.invalid"></app-confirm-icon-button>
</div>
