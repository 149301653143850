<div class="upload-wrapper" appDropUpload (fileDropped)="onFileDrop($event)">
  <div style="position: relative; flex-direction: column; gap: 0.25rem" class="centered">
    <div class="mat-h3">{{ label }}</div>
    <mat-icon class="blue-icon upload-icon" svgIcon="{{EsvgFiles.upload}}"></mat-icon>
    <div>Datei hier ablegen</div>
    <div>oder</div>
    <mat-chip-listbox aria-label="File selection">
      <mat-chip-option
        style="cursor: pointer"
        color="primary"
        selected
        (click)="onUploadClick()"
      >Durchsuchen
      </mat-chip-option
      >
    </mat-chip-listbox>
    <div style="position: absolute; bottom: 0; cursor: default;" *ngIf="fileName == null && getFileEndTooltip() !== ''"
         [matTooltip]="getFileEndTooltip()">
      ❔
    </div>
    <mat-progress-bar mode="indeterminate" style="width: 100%; height: 0.5rem; margin-top: 0.5rem;"
                      *ngIf="uploading; else fileNameRef">

    </mat-progress-bar>
    <ng-template #fileNameRef>
      <div
        class="file-name"
        [ngStyle]="{
        visibility: fileName == null ? 'hidden' : 'unset'
      }"
      >
      <span>
        {{ fileName }}
      </span>
        <button mat-icon-button class="red-icon" (click)="onDeleteImage()">
          <mat-icon svgIcon="{{EsvgFiles.delete}}"></mat-icon>
        </button>
      </div>
    </ng-template>

  </div>
</div>
<input
  #fileUpload
  style="display: none"
  type="file"
  [formControl]="formControlElement"
  [accept]="accept"
  (change)="addAttachment($event)"
/>
