<kpi4me-select-element
  class="form-field-no-padding"
  [formControlElement]="formControl"
  (modelChange)="onSelectionChange()"
  [editEnabled]="false"
  [deleteEnabled]="false"
  [data]="selectElementData"
  [compareWith]="compareWith"
  [hideLabel]="true"
></kpi4me-select-element>
