import {Component, computed, effect, input, viewChild} from '@angular/core';
import {ICellRendererParams} from 'ag-grid-community';
import {MatTooltip} from '@angular/material/tooltip';
import {CellErrorIconComponent} from '../../cell-error-icon/cell-error-icon.component';
import {NgClass} from '@angular/common';

@Component({
  selector: 'lib-renderer-container',
  standalone: true,
  imports: [
    CellErrorIconComponent,
    MatTooltip,
    NgClass
  ],
  templateUrl: './renderer-container.component.html',
  styleUrl: './renderer-container.component.scss'
})
export class RendererContainerComponent {
  params = input.required<ICellRendererParams>()
  showTooltip = input<boolean>(true)
  inputTooltip = input<string>(null, {alias: 'tooltip'})

  cellErrorIcon = viewChild(CellErrorIconComponent)
  computedTooltip = computed(() => {
    const showTooltip = this.showTooltip()
    const inputTooltip = this.inputTooltip()
    if (!showTooltip) return null

    if (inputTooltip) return inputTooltip

    return this.params().value?.value?.length > this.showTooltipIfLengthExceeds ? this.params().value?.value : null
  })

  // Show the tooltip if the length of the value exceeds this number
  private showTooltipIfLengthExceeds = 22

  constructor() {
    effect(() => {
      const params = this.params()
      const errorTooltipRef = this.cellErrorIcon().tooltipRef()
      if (!params || !errorTooltipRef) return
      if (params.value?.error != null) {
        errorTooltipRef.show()
      }
    });
  }

  onContextMenu($event: MouseEvent) {
    console.log(this.params().context);
    $event.preventDefault();
  }
}
