import {computed, inject, Injectable, Signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {ControlService} from '../apiv2/generated/api/control.service';
import {IControlPermission} from 'frontier/nucleus/src/lib/authorization/control-permission.interface';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  private _controlService = inject(ControlService)

  private _allowedControls: Signal<IControlPermission[]> = toSignal(
    this._controlService.controlGetGetControls()
      .pipe(map(permissions => permissions as IControlPermission[]))
  )

  private _permissionsByGuid: Signal<Record<string, IControlPermission>> = computed(() => {
    return this._allowedControls()?.reduce((acc, permission) => {
      acc[permission.guid] = permission
      return acc
    }, {} as Record<string, IControlPermission>);
  })

  hasControl(guid: string): boolean {
    return this._permissionsByGuid() && this._permissionsByGuid()[guid] !== undefined
  }

  hasAction(guid: string, action: string): boolean {
    return this._permissionsByGuid()[guid]?.actions.includes(action)
  }

}
