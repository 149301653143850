export const ALLOWED_KEYS: any = {
  Tab: true,
  Backspace: true,
  Escape: true,
  ArrowUp: true,
  ArrowDown: true,
  ArrowLeft: true,
  ArrowRight: true,
  Enter: true,
  PageUp: true,
  PageDown: true,
  Home: true,
  End: true,
  Space: true,
  ',': true,
};
