import {Component, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {MatMenu, MatMenuItem} from '@angular/material/menu';
import {TreeNode} from '@ali-hm/angular-tree-component';
import {IApiControl} from 'frontier/nucleus';
import {MatTooltip} from '@angular/material/tooltip';
import {NgIf} from '@angular/common';

@Component({
  selector: 'kpi4me-delete-node',
  templateUrl: './delete-node.component.html',
  styleUrls: ['./delete-node.component.scss'],
  standalone: true,
  imports: [
    MatMenu,
    NgIf,
    MatMenuItem,
    MatTooltip,
  ],
})
export class DeleteNodeComponent {
  @ViewChild(MatMenu, {static: true}) menu: MatMenu;
  @Input() node: TreeNode;
  @Input() apiInstance: IApiControl;
  @Output() deletePress = new EventEmitter<{
    mode: 'node' | 'children' | 'all';
    attachTo?: any;
  }>();

  getRefCountOfNode() {
    if (this.node) {
      if (this.node.data.referenceCount <= 0) {
        return '';
      } else if (this.node.data.referenceCount === 1) {
        return 'Es wird 1 Referenz gelöscht'
      }
      return `Es werden ${this.node.data.referenceCount} Referenzen gelöscht`;
    } else {
      return '';
    }
  }

  /**
   * Deletes a node from the hierarchy and conditionally attaches the children to some other node in the same hierarchy.
   * @param mode
   * @param attachTo
   */
  deleteNode(mode: 'node' | 'children' | 'all') {
    this.deletePress.emit({mode});
  }
}
