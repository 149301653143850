import {AfterViewInit, Component, HostListener, inject, OnDestroy, ViewChild,} from '@angular/core';
import {ICellEditorParams} from 'ag-grid-community';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';
import {Subscription} from 'rxjs';
import {DialogService} from '../../../../../dialogs/dialog.service';
import {ControlFormDialogComponent} from '../../../control-form-dialog/control-form-dialog.component';
import {IControlFormData} from '../../../control-form-dialog/control-form-data.interface';
import {ControlRendererComponent} from '../../../control-renderer/control-renderer.component';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {EComponentType, IDynamicComponent,} from './dynamic-component.interface';

const allowedClasses = ['mat-select-placeholder', 'mat-option-text'];

// Custom params that additionally has the dynamic creatable components as an attribute.
interface IFormCellEditorParams extends ICellEditorParams {
  dynamicComponents: Record<string, IDynamicComponent>;
}

/**
 * Editor component for cells, that have multiple forms in their column definition.
 * In such a case, the editor can render different forms. I.e. for a calendar cell, it can either be
 * a holiday or normal collision. The user should be able to open either dialog.
 */
@Component({
  selector: 'app-form-cell-editor',
  templateUrl: './form-cell-editor.component.html',
  styleUrls: ['./form-cell-editor.component.scss'],
  standalone: true,
  imports: [ControlRendererComponent],
})
export class FormCellEditorComponent
  extends CustomCellEditorComponent
  implements AfterViewInit, OnDestroy {
  private _dialogService = inject(DialogService);

  @ViewChild(ControlRendererComponent, {static: true})
  controlRenderer: ControlRendererComponent;

  // Record that stores the dynamically creatable components. It is set in the ag init method.
  // In the ag init params there is a custom attribute "dynamicComponents" that stores that GUIDMap
  GUIDMap: Record<string, IDynamicComponent>;
  override params: IFormCellEditorParams;

  private subs = new Subscription();

  // detect clicks outside
  @HostListener('document:click', ['$event'])
  override clickOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (
      !(
        // is the element inside this element as a child?
        (
          this._elementRef.nativeElement.contains(event.target) ||
          // does the element contain one of the allowed classes?
          allowedClasses.reduce(
            (includesOne: boolean, cssClass: string) =>
              includesOne || target.classList.contains(cssClass),
            false
          ) ||
          // Click on the ngb day ?
          target.hasAttribute('ngbdatepickerdayview')
        )
      )
    ) {
      this.params.api.stopEditing();
    }
  }

  override isPopup(): boolean {
    return true;
  }

  /**
   * Creates the dynamic component. It either renders a form
   * @param params
   */
  override agInit(params: ICellEditorParams) {
    this.params = params as IFormCellEditorParams;
    this.GUIDMap = this.params.dynamicComponents;
    if ((params.colDef as ICustomColDef).apiCol.forms.length == 1) {
      const form = (params.colDef as ICustomColDef).apiCol.forms[0];
      const guid: string = form.guid;
      const dbobject = params.value[form.cellvalue];
      const dynamicComponent: IDynamicComponent = this.GUIDMap[guid];

      // Either open dialog or render form
      if (dynamicComponent.type === EComponentType.form) {
        // Render the form component
        const filter = {
          dbobject,
        };
        this.renderForm(dynamicComponent.component, guid, this.params, filter);
      }
      if (dynamicComponent.type === EComponentType.dialog) {
        // Open the dialog if there is a conflict
        if (dbobject) {
          this.openDialog(
            dynamicComponent.component,
            guid,
            dbobject,
            this.params
          );
        }
      }
      this._cdr.detectChanges();
    }
  }

  // overwrite super method
  override ngAfterViewInit() {
  }

  override ngOnDestroy() {
    // overwrite base class method
    this.subs.unsubscribe();
  }

  override isCancelAfterEnd(): boolean {
    return this.canceled;
  }

  override getValue(): any {
    return this.params.node.data[this.params.column.getColDef().field];
  }

  protected override setPastedValue(pasted: string) {
    const guid = (this.params.colDef as ICustomColDef).apiCol.forms[0].guid;
    const dynamicComponent: IDynamicComponent = this.GUIDMap[guid];

    // Either open dialog or render form
    if (dynamicComponent.type === EComponentType.form) {
      (this.controlRenderer.formRef.instance as any).setPastedValue(pasted);
    }
  }

  /**
   * Opens the dynamic dialog component, that renders the component parameter inside of it.
   * @param component component that gets rendered in the dialog
   * @param guid the control guid
   * @param dbobject the dbobjects that are passed to the filter objectlist
   * @param params editor params
   * @private
   */
  private openDialog(
    component: any,
    guid: string,
    dbobject: any,
    params: ICellEditorParams
  ) {
    const data: IControlFormData = {
      component,
      guid,
      params,
      filter: {objectlist: dbobject},
    };
    this._dialogService.dialog
      .open(ControlFormDialogComponent, {
        maxHeight: '90vh',
        data,
      })
      .afterClosed()
      .subscribe((result) => {
        this.params.api.stopEditing();
      });
  }

  /**
   * Renders the form component
   * @param component
   * @param guid
   * @param params
   * @param filter
   * @private
   */
  private renderForm(
    component: any,
    guid: string,
    params: ICellEditorParams,
    filter: { dbobject: any }
  ) {
    this.controlRenderer.render(component, guid, this.params, filter);
    // if (params.charPress) {}

    // paste subscription
    this.subs.add(
      (this.controlRenderer.formRef.instance as any).gotData.subscribe(
        (gotData: boolean) => {
          if (gotData && params.charPress) {
            this.handlePaste(params);
          }
        }
      )
    );

    // confirmed subscription: stop editing and refresh the cache for new values.
    this.subs.add(
      (this.controlRenderer.formRef.instance as any).confirmed.subscribe(
        (value: { result: boolean; date: NgbDate }) => {
          this.params.context.formCellEditorConfirmed.emit(value);
          this.params.stopEditing();
          this.params.api.refreshInfiniteCache();
          // sets focus into current grid cell
          this.params.api.setFocusedCell(
            this.params.rowIndex,
            this.params.colDef.field
          );
        }
      )
    );
    // canceled subscription: Only stop editing. No update needed.
    this.subs.add(
      (this.controlRenderer.formRef.instance as any).canceled.subscribe(() => {
        this.params.stopEditing();
        this.params.api.setFocusedCell(
          this.params.rowIndex,
          this.params.colDef.field
        );
      })
    );
  }
}
