import {EsvgFiles, KPI4meColor} from 'frontier/nucleus';

export interface ISideNavContentCategory {
  title?: string;
  color: KPI4meColor;
  items: TSideNavContentItem[];
  icon?: EsvgFiles;
  opened?: boolean;
}

export interface ISidenavContentItem {
  title: string;
  icon?: string;
  tabId: string;
}

export interface ISideNavContentItemComponent extends ISidenavContentItem {
  componentName: string;
}

// type check for TSideNavContentItemComponent
export function isSideNavContentItemComponent(item: TSideNavContentItem): item is ISideNavContentItemComponent {
  return (item as ISideNavContentItemComponent).componentName !== undefined;
}

export interface ISideNavContentItemLink extends ISidenavContentItem {
  link: string;
}


export type TSideNavContentItem = ISideNavContentItemComponent | ISideNavContentItemLink;

