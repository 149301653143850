import {ApiAdapter} from './api-adapter';

export class DefaultApiAdapter extends ApiAdapter {
  from(apiData: any): any {
    return apiData;
  }

  to(data: any): any {
    return data;
  }
}
