import {AbstractControl, ValidatorFn} from "@angular/forms";

export const
  ibanValidator: ValidatorFn = (control: AbstractControl) => {
    if ((<string>control.value) === '') return null;

    const input = <string>control.value

    const countryCode = input.slice(0, 2);
    const validationDigits = input.slice(2, 4);
    const bban = input.slice(4);

    const ibanRegex = /^[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?:[ ]?[0-9]{2})$/;

    const codeLengths: { countryCode: string, len: number }[] = [
      {countryCode: 'AD', len: 24},
      {countryCode: 'AE', len: 23},
      {countryCode: 'AT', len: 20},
      {countryCode: 'AZ', len: 28},
      {countryCode: 'BA', len: 20},
      {countryCode: 'BE', len: 16},
      {countryCode: 'BG', len: 22},
      {countryCode: 'BH', len: 22},
      {countryCode: 'BR', len: 29},
      {countryCode: 'CH', len: 21},
      {countryCode: 'CR', len: 21},
      {countryCode: 'CY', len: 28},
      {countryCode: 'CZ', len: 24},
      {countryCode: 'DE', len: 22},
      {countryCode: 'DK', len: 18},
      {countryCode: 'DO', len: 28},
      {countryCode: 'EE', len: 20},
      {countryCode: 'ES', len: 24},
      {countryCode: 'FI', len: 18},
      {countryCode: 'FO', len: 18},
      {countryCode: 'FR', len: 27},
      {countryCode: 'GB', len: 22},
      {countryCode: 'GI', len: 23},
      {countryCode: 'GL', len: 18},
      {countryCode: 'GR', len: 27},
      {countryCode: 'GT', len: 28},
      {countryCode: 'HR', len: 21},
      {countryCode: 'HU', len: 28},
      {countryCode: 'IE', len: 22},
      {countryCode: 'IL', len: 23},
      {countryCode: 'IS', len: 26},
      {countryCode: 'IT', len: 27},
      {countryCode: 'JO', len: 30},
      {countryCode: 'KW', len: 30},
      {countryCode: 'KZ', len: 20},
      {countryCode: 'LB', len: 28},
      {countryCode: 'LI', len: 21},
      {countryCode: 'LT', len: 20},
      {countryCode: 'LU', len: 20},
      {countryCode: 'LV', len: 21},
      {countryCode: 'MC', len: 27},
      {countryCode: 'MD', len: 24},
      {countryCode: 'ME', len: 22},
      {countryCode: 'MK', len: 19},
      {countryCode: 'MR', len: 31},
      {countryCode: 'MT', len: 27},
      {countryCode: 'MU', len: 30},
      {countryCode: 'NL', len: 18},
      {countryCode: 'NO', len: 15},
      {countryCode: 'PK', len: 24},
      {countryCode: 'PL', len: 28},
      {countryCode: 'PS', len: 29},
      {countryCode: 'PT', len: 25},
      {countryCode: 'QA', len: 29},
      {countryCode: 'RO', len: 24},
      {countryCode: 'RS', len: 22},
      {countryCode: 'SA', len: 24},
      {countryCode: 'SE', len: 24},
      {countryCode: 'SI', len: 19},
      {countryCode: 'SK', len: 24},
      {countryCode: 'SM', len: 27},
      {countryCode: 'TN', len: 24},
      {countryCode: 'TR', len: 26},
      {countryCode: 'QA', len: 29},
      {countryCode: 'AL', len: 28},
      {countryCode: 'BY', len: 28},
      {countryCode: 'CR', len: 22},
      {countryCode: 'EG', len: 29},
      {countryCode: 'GE', len: 22},
      {countryCode: 'IQ', len: 23},
      {countryCode: 'LC', len: 32},
      {countryCode: 'SC', len: 31},
      {countryCode: 'ST', len: 25},
      {countryCode: 'SV', len: 28},
      {countryCode: 'TL', len: 23},
      {countryCode: 'UA', len: 29},
      {countryCode: 'VA', len: 22},
      {countryCode: 'VG', len: 24},
      {countryCode: 'XK', len: 20},
    ];

    const currentCodeLength = codeLengths.find(code => {
      return code.countryCode === countryCode;
    });

    // Check if country code even exists and the length of the code matches it
    if (!currentCodeLength || currentCodeLength.len !== input.length) {
      return {invalidIban: true};
    }

    let valid;

    try {
      valid = (<string>control.value).match(ibanRegex);
    } catch {
      valid = false;
    }

    if (!valid) {
      return {invalidIban: true};
    }

    const convertedCountryCode: string = String(countryCode.charCodeAt(0) - 55) + String(countryCode.charCodeAt(1) - 55);

    const validationNumber = bban + convertedCountryCode + validationDigits;

    if (checkMod97(validationNumber)) {
      return null;
    } else {
      return {invalidIban: true};
    }
  }

function checkMod97(numberstring: string): boolean {
  // Remove leading zeros
  numberstring = numberstring.replace(/^0+/, '');

  const block1 = numberstring.slice(0, 12);
  let block2 = numberstring.slice(12, 24);
  let block3 = numberstring.slice(24);

  block2 = String((Number(block1) % 97)) + block2;
  block3 = String((Number(block2) % 97)) + block3;
  const remainder = String((Number(block3) % 97));

  // Vergleiche das Ergebnis mit dem gewünschten Wert
  return remainder === '1';
}
