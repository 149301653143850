import {IApiAdapter} from './interfaces/api-adapter.interface';

/**
 * Classes inherited from this one serve as an adapter or interface to/from the data from the api.
 */
export abstract class ApiAdapter implements IApiAdapter {
  /**
   * Maps data to the api-interface data.
   * @param data
   */
  abstract to(data: any): any;

  /**
   * Maps data from the api interface to frontend data.
   * @param apiData
   */
  abstract from(apiData: any): any;
}
