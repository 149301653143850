<div class="flex-column ui-helper-content-wrapper">
  <div class="flex-row space-between align-center">
    <h6>Hilfe</h6>
    <mat-icon (click)="closeOverlay()" [svgIcon]="EsvgFiles.close"></mat-icon>
  </div>
  <mat-divider/>
  <div class="help-content" #helpContentHost>

  </div>
</div>

