import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {PercentPipe} from '@angular/common';
import {CellErrorIconComponent} from '../../cell-error-icon/cell-error-icon.component';
import {MatTooltip} from '@angular/material/tooltip';
import {
  RendererContainerComponent
} from 'frontier/browserkit/src/lib/components/control/table-control/renderers/renderer-container/renderer-container.component';

@Component({
  selector: 'app-percentage-cell-renderer',
  templateUrl: './percentage-cell-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
  standalone: true,
  imports: [
    MatTooltip,
    CellErrorIconComponent,
    PercentPipe,
    RendererContainerComponent,
  ],
})
export class PercentageCellRendererComponent extends CustomCellComponent {
}
