<kpi4me-tree-select
  *ngIf="treeSelectData"
  [formControlElement]="selectForm"
  [data]="treeSelectData"
  (confirm)="onConfirm()"
  (newElementClick)="onNewElementClick($event)"
  (deleteElementClick)="onDeleteElement($event)"
  (changeElementClick)="onChangeElement($event)"
  [columnName]="params.colDef.headerName"
  (clear)="resetSelection()"
></kpi4me-tree-select>
