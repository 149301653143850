<app-dialog-header
  [dialogRef]="dialogRef"
  [title]="data.title"
  (close)="dialogRef.close()"
>
</app-dialog-header>
<mat-dialog-content>
  <mat-selection-list [(ngModel)]="selectedEntities" [multiple]="multiple">
    <mat-list-option
      *ngFor="let option of data.entities"
      [color]="'primary'"
      [value]="option.id"
    >
      {{ option.displayName }}
    </mat-list-option>
  </mat-selection-list>
</mat-dialog-content>
<mat-dialog-actions>
  <app-confirm-icon-button
    (customClick)="onConfirm()"
    [disabled]="selectedEntities.length <= 0"
  >
  </app-confirm-icon-button>
</mat-dialog-actions>
