<kpi4me-checkbox-element
  [data]="checkboxData"
  [formControlElement]="hasRepo"
  (modelChange)="onHasRepoChanged($event)"
></kpi4me-checkbox-element>

<kpi4me-text-element
  [formControlElement]="formGuid"
  [data]="textElementData"
  (modelChange)="onFormGuidChange($event)"
>

</kpi4me-text-element>

@if (render) {
  @if (hasRepo.value) {
    <kpi4me-form-wrapper
      [GUID]="formGuid.value"
    >
    </kpi4me-form-wrapper>
  } @else {
    <lib-guid-form-control
      [standardForm]="true"
      [inputGUID]="formGuid.value"
      (instanceInitialized)="onInizializedDynamicFormControl($event)"
    />
  }
}
