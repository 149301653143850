import {Component} from '@angular/core';
import {ILoadingCellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'app-loading-renderer',
  templateUrl: './loading-renderer.component.html',
  styleUrls: ['./loading-renderer.component.scss'],
  standalone: true,
  imports: [MatProgressSpinner],
})
export class LoadingRendererComponent implements ILoadingCellRendererAngularComp {
  params: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
