import {EControlActions, EsvgFiles} from "frontier/nucleus";
import {IAction} from "./interfaces/actions.interface";

export const JSON_EXPORT_ACTION: IAction = {
  displayName: 'JSON-Export',
  controlAction: EControlActions.jsonExport,
  color: 'primary',
  icon: EsvgFiles.download,
};

export const JSON_IMPORT_ACTION: IAction = {
  displayName: 'JSON-Import',
  controlAction: EControlActions.jsonImport,
  color: 'primary',
  icon: EsvgFiles.upload,
};
