import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
  signal,
  viewChild,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  DefaultApiAdapter,
  EControlActions, FormControlService,
  IApiAdapter,
  IApiControl,
  IControlObject,
  IFilter
} from 'frontier/nucleus';
import {ISelectFormElement, ISelectFormOption} from '../form-element/form-data.interface';
import {mergeMap, Subscription, tap} from 'rxjs';
import {FormControl} from '@angular/forms';
import {
  FormWrapperControlComponent
} from './form-wrapper-control/form-wrapper-control.component';
import {ControlStore} from 'frontier/nucleus/src/lib/apiv2/control.store';
import {
  FormWrapperControlComponent as FormWrapperControlComponent_1
} from './form-wrapper-control/form-wrapper-control.component';
import {SelectElementComponent} from '../form-element/select-element/select-element.component';
import {NgIf} from '@angular/common';
import {DefaultFormApiAdapter} from 'frontier/browserkit';

@Component({
  selector: 'kpi4me-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    NgIf,
    SelectElementComponent,
    FormWrapperControlComponent_1,
  ],
})
export class FormWrapperComponent implements OnDestroy {
  private _formControlService = inject(FormControlService);
  private _controlStore = inject(ControlStore);

  @ViewChild('select') select: ElementRef;
  selectionData: ISelectFormElement;
  selectFormControl = new FormControl(null);
  formRef = viewChild(FormWrapperControlComponent);
  formFilter = signal<IFilter>({});

  @Input() GUID: string;
  @Input() deleteInSelectionAlwaysEnabled = true;
  @Input() deleteInSelectionEnabled = true;
  @Input() createNewEnabled = true;
  @Input() apiAdapter: IApiAdapter = new DefaultFormApiAdapter();
  @Input() visibleFormFields = true;
  @Input() deleteEnabled = true;
  @Output() selectionChange = new EventEmitter<any>();
  @Output() getFormData = new EventEmitter<any>();
  @Output() formChange = new EventEmitter();

  apiInstance: IApiControl;

  formInit = new EventEmitter();
  subs = new Subscription();

  protected readonly EControlActions = EControlActions;

  constructor(
    protected cdr: ChangeDetectorRef,
  ) {
    this.subs.add(
      this.formInit.pipe(mergeMap(() => {
        return this.updateRepo(null);
      })).subscribe(() => {
      })
    )

    this.subs.add(
      this.selectFormControl.valueChanges.subscribe(v => {
        if (v) {
          this.formRef().patchFilter({
            parent: v
          })
          // this.formRef.changeAndFetch().subscribe((res) => {
          //   this.getFormData.emit(res);
          // });
          this.selectionChange.emit(v);
        }
        console.log(v);
      })
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onNewElement(name: string) {
    this._formControlService.dBObjectFormControlNewElement({
      InstanceId: this.formRef().instanceId(),
      formElements: null,
      name
    })
      .subscribe(res => {
        this.updateRepo(EControlActions.create).subscribe(() => {
          this.selectFormControl.patchValue(res);
        })
      })
  }

  deleteElementClicked($event: ISelectFormOption) {
    const dbobject: IControlObject = {
      type: $event.value.type ? $event.value.type : $event.type,
      rowid: $event.value.rowid ? $event.value.rowid : $event.rowid,
      signature: $event.value.signature ? $event.value.signature : $event.signature,
    };
    this._formControlService.dBObjectFormControlDeleteElement(
      {
        InstanceId: this.formRef().instanceId(),
        dbobject: dbobject
      })
      .subscribe(() => {
        this.selectFormControl.patchValue(null);
        this.updateRepo(EControlActions.delete).subscribe();
      })
  }

  updateRepo(action: EControlActions) {
    return this._formControlService.dBObjectFormControlGetRepository(
      {InstanceId: this.formRef().instanceId()}
    ).pipe(
      tap((repository) => {

        const options: ISelectFormOption[] = repository.options.map((o: any) => {
          const mappedO: ISelectFormOption = {
            value: o,
            name: o.name,
          }

          return mappedO;
        });
        // Set value to only possibility in case of dummy repository
        if (options && options.length === 1) {
          this.selectFormControl.patchValue(options[0]);
        }
        this.selectionData = {
          options
        };
        if (action) {
          this._controlStore.controlDataChanged$.emit({GUID: this.GUID, changeType: action});
        }
      })
    );
  }
}
