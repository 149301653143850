import {EventEmitter, inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {AuthenticationService} from '../../authentication/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DialogService} from '../../dialogs/dialog.service';
import {HttpOptions, UserService} from 'frontier/nucleus';
import {ApiConfigService} from 'frontier/nucleus/src/lib/apiv2/api-config.service';

@Injectable({
  providedIn: 'root',
})
export class LoginStore {
  private _userService = inject(UserService);
  // PortalUrl = 'http://' + ServerConfig.PortalIP + ':' + ServerConfig.PortalPORT + '/qspace/api/portal/';
  // PortalUrl = 'http://localhost:49494/qspace/api/portal/';

  url;
  // Headers
  httpOptions = HttpOptions;
  loggedOut$ = new EventEmitter();

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    private dialogService: DialogService,
    private apiService: ApiConfigService,
  ) {
    this.url = apiService.url;
  }

  // Unsubscribe to the timer
  logout(): Observable<boolean> {
    this.snackBar.dismiss();
    this.dialogService.dialog.closeAll();
    return this._userService.userGetLogout();
  }

  SessionIsValid() {
    return this.http.get(
      this.url + '/intern/api/user/SessionIsValid/',
      this.httpOptions
    );
  }

  login(username: string, password: string) {
    this.authService.deleteSession();
    // String convert to Base 64
    //  const auth = btoa(username + ':' + password);
    const auth2 = btoa(
      encodeURIComponent(username + ':' + password).replace(
        /%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
          return String.fromCharCode(Number('0x' + p1));
        }
      )
    );
    // Set headers
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + auth2,
      Accept: 'application/json',
      'Content-type': 'text/plain;charset=UTF-8',
    });

    console.log(headers);
    // First Connection
    if (this.authService.isAuthenticated() === false) {
      return this.http.get(this.url + '/intern/api/user/Login/', {
        headers,
        observe: 'response',
      });
    } else {
      return EMPTY;
    }
  }
}
