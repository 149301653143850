import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from "@angular/forms";
import {CancelIconButtonComponent} from 'frontier/browserkit/src/lib/components/cancel-icon-button/cancel-icon-button.component';
import {MatTooltip} from '@angular/material/tooltip';
import {ConfirmIconButtonComponent} from 'frontier/browserkit/src/lib/components/confirm-icon-button/confirm-icon-button.component';
import {
  CheckboxElementComponent
} from '../../../components/control/form-control/dynamic-form/form-element/checkbox-element/checkbox-element.component';
import {NgIf} from '@angular/common';
import {DialogHeaderComponent} from 'frontier/browserkit/src/lib/components/dialog-header/dialog-header.component';

@Component({
  selector: 'app-save-changes-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['../shared-dialogs.scss'],
  standalone: true,
  imports: [
    DialogHeaderComponent,
    NgIf,
    CheckboxElementComponent,
    MatDialogActions,
    ConfirmIconButtonComponent,
    MatTooltip,
    CancelIconButtonComponent,
  ],
})
export class ConfirmationDialogComponent implements OnInit {
  title: string;
  description: string;
  confirmButtonText: string;
  cancelButtonText: string;
  email: string;
  showCustomCheckBox: boolean;
  checkBoxText: string = '';
  checkBoxValue = new FormControl(false);

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogComponent
  ) {
  }

  ngOnInit() {
    return;
  }

  onConfirm() {
    if (this.data.showCustomCheckBox === true) {
      this.dialogRef.close({result: true, checkBox: this.checkBoxValue.value});
    } else {
      this.dialogRef.close(true);
    }
  }

  onCancel() {
    if (this.data.showCustomCheckBox === true) {
      this.dialogRef.close({result: false, checkBox: this.checkBoxValue.value});
    } else {
      this.dialogRef.close(false);
    }
  }
}
