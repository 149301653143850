import {Component} from '@angular/core';
import {SaveIconButtonComponent} from '../save-icon-button/save-icon-button.component';
import {MatIcon} from '@angular/material/icon';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-confirm-icon-button',
  templateUrl: './confirm-icon-button.component.html',
  styleUrls: ['./confirm-icon-button.component.scss'],
  standalone: true,
  imports: [MatButton, MatIcon],
})
export class ConfirmIconButtonComponent extends SaveIconButtonComponent {
  constructor() {
    super();
  }
}
