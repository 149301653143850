import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'app-control-renderer',
  templateUrl: './control-renderer.component.html',
  styleUrls: ['./control-renderer.component.scss'],
  standalone: true,
})
export class ControlRendererComponent implements OnInit {
  @ViewChild('formHost', {read: ViewContainerRef, static: true})
  formHost: ViewContainerRef;
  formRef: ComponentRef<unknown>;

  @Output() confirmed = new EventEmitter<any>();
  @Output() canceled = new EventEmitter<any>();

  constructor(protected componentFactoryResolver: ComponentFactoryResolver) {
  }

  ngOnInit(): void {
  }

  render(component: any, guid: string, params: any, filter: any) {
    // Destroy the previous rendered component if one exists.
    if (this.formHost) {
      this.formHost.clear();
    }
    if (this.formRef) {
      this.formRef.destroy();
    }
    const componentFactory: ComponentFactory<any> =
      this.componentFactoryResolver.resolveComponentFactory(component);
    this.formRef = this.formHost.createComponent(
      componentFactory
    ) as ComponentRef<any>;
    (this.formRef.instance as any).GUID = guid;
    (this.formRef.instance as any).params = params;
    (this.formRef.instance as any).apiInstance = {
      ...(this.formRef.instance as any).apiInstance,
      filter,
    };
    (this.formRef.instance as any).confirmed.subscribe(() => {
      this.confirmed.emit();
    });
    (this.formRef.instance as any).canceled.subscribe(() => {
      this.canceled.emit();
    });
  }
}
