import {Component, inject} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {DialogHeaderComponent} from '../../../components/dialog-header/dialog-header.component';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {MatInput} from '@angular/material/input';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {ConfirmIconButtonComponent} from '../../../components/confirm-icon-button/confirm-icon-button.component';
import {emailRegex} from 'frontier/nucleus/src/lib/regex/email-regex';

export function emailListValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const emails: string[] = control.value ? control.value.split('\n') : [];
    const errors: { [key: number]: string } = {};
    emails.forEach((email, index) => {
      if (email === '') {
        errors[index] = 'Leere Zeile. Wert wird benötigt'
      }
      else if (!isValidEmail(email.trim())) {
        errors[index] = `Ungültige E-Mail-Adresse: ${email.trim()}`;
      }
    });

    return Object.keys(errors).length > 0 ? { invalidEmails: errors } : null;
  };
}

function isValidEmail(email: string): boolean {
  // Regular expression for validating email
  const emailPattern = emailRegex;
  return emailPattern.test(email);
}

@Component({
  selector: 'lib-create-user-dialog',
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatDialogContent,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    MatLabel,
    CdkTextareaAutosize,
    ConfirmIconButtonComponent,
    MatDialogActions,
  ],
  templateUrl: './create-user-dialog.component.html',
  styleUrl: './create-user-dialog.component.css'
})
export class CreateUserDialogComponent {
  private _fb = inject(FormBuilder);

  public dialogRef = inject(MatDialogRef<CreateUserDialogComponent>)

  form = this._fb.control("", [emailListValidator(), Validators.required])
  errors: any;

  confirm() {
    this.dialogRef.close(this.form.value.split('\n'));
  }

  get emailErrors() {
    return this.form.errors?.invalidEmails || {};
  }
}
