<mat-dialog-content
  style="max-height: unset; display: flex; flex-direction: column; height: 100%"
>
  <app-control-renderer></app-control-renderer>
</mat-dialog-content>

<!--<mat-dialog-actions>-->
<!--  <app-confirm-icon-button-->
<!--  (customClick)="onConfirm()">-->
<!--  </app-confirm-icon-button>-->
<!--</mat-dialog-actions>-->
