export enum EGranularity {
  day,
  week,
  month,
  quarter,
  halfyear,
  year,
  custom,
  all,
}
