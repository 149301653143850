import {Component, signal} from '@angular/core';
import {CustomCellEditorComponent} from '../custom-cell-editor.component';
import {GridApi, ICellEditorParams} from 'ag-grid-community';
import {ICustomColDef} from '../../../interfaces/custom-col-def.interface';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'app-time-range-cell-editor',
  templateUrl: './time-range-cell-editor.component.html',
  styleUrls: ['../custom-cell-editor.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    MatIcon,
    MatTooltip,
  ],
})
export class TimeRangeCellEditorComponent extends CustomCellEditorComponent {
  protected readonly EsvgFiles = EsvgFiles;

  validRegex = new RegExp(
    '^([0-1]?\\d|2[0-3])(?::([0-5]?\\d))?(?::([0-5]?\\d))?(\\s*-\\s*([0-1]?\\d|2[0-3])(?::([0-5]?\\d))?(?::([0-5]?\\d))?)(\\s*;\\s*([0-1]?\\d|2[0-3])(?::([0-5]?\\d))?(?::([0-5]?\\d))?(\\s*-\\s*([0-1]?\\d|2[0-3])(?::([0-5]?\\d))?(?::([0-5]?\\d))?))*$',
    'g'
  );
  showTooltip = signal(false);
  validationTooltip =
    'Die Eingabe ist ungültigt. Der Wert muss einem Zeit-Intervall entsprechen. Beispiel: 08:30 - 14';

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override agInit(params: ICellEditorParams) {
    super.agInit(params);
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  override ngOnDestroy() {
    (<GridApi>this.params.context.gridApi).setFocusedCell(this.params.rowIndex, this.params.column);

    if (this.canceled) {
      this.textFormControl.patchValue(this.oldValue.value);
      return;
    } else if (this.oldValue.value === this.textFormControl.value) {
      return;
    }

    if (this.textFormControl.valid === false) {
      this.params.colDef.cellStyle = {
        'border-color': '#D55F13', // var(--color-orange-600)
        'border-width': '2px',
      }
      this.params.value.value = this.textFormControl.value;
      this._feedbackService.setError('Die Anfangs- und Endzeit müssen jeweils im Format \'hh\' oder \'hh:mm\' oder \'hh:mm:ss\' angegeben werden.');
      this._cdr.detectChanges();
      return;
    }

    // check if the value is too big
    if (
      this.isMaximumLengthReached(
        (this.params.colDef as ICustomColDef).apiCol.attributetype
      )
    ) {
      this._feedbackService.setError('Der eingegeben Wert ist zu groß');
      this.textFormControl.patchValue(this.oldValue.value);
      return;
    }

    this.saveValue();
    this.params.colDef.cellStyle = {
      'border-color': '',
      'border-width': ''
    }
  }

  override getValue(): any {
    return super.getValue();
  }

  override focusOut() {
    super.focusOut();
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  override isCancelAfterEnd() {
    return false;
  }

  onInputChange(value: string) {
    console.log(value);
    this.showTooltip.set(!value.match(this.validRegex));
  }

}
