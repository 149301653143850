<mat-form-field appearance="outline" subscriptSizing="dynamic" *ngIf="multiple !== null">
  <mat-icon
    class="left-arrow-icon"
    style="margin-right: 0.5rem"
    *ngIf="(arrowPosition  !== 'right')"
    matIconSuffix
    [svgIcon]="EsvgFiles.arrow_down"
  ></mat-icon>

  <mat-icon
    style="margin-right: 0.5rem"
    *ngIf="icon"
    matIconPrefix
    [svgIcon]="icon"
  ></mat-icon>

  @if (!hideLabel) {
    <mat-label>{{ label }}</mat-label>
  }

  <mat-select
    #selectElement
    class="hide-arrow"
    panelClass="custom-panel"
    [formControl]="formControlElement"
    [multiple]="multiple"
    [compareWith]="compareWith"
    (closed)="onSelectionChange(formControlElement.value)"
    (opened)="focusSearchInput()"
  >
    <kpi4me-search-input
      #selectionSearch
      *ngIf="searchForm"
      [control]="searchForm"
      (ngModelChange)="onSearchChange($event)"
      class="search-form"
    ></kpi4me-search-input>

    <mat-option
      *ngIf="emptyOptionAdded && !multiple;"
      [value]="''"
    >
      <div style="display:flex; justify-content: space-between">
        <span class="display-name" [matTooltip]="''"></span>
      </div>
    </mat-option>

    <ng-container *ngIf="multiple"
                  #selectAll
                  style="width: 100%"
    >
      <mat-checkbox class="select-all-checkbox"
                    color="primary" [checked]="selectAllChecked"
                    (change)="onSelectAll($event)">
        Alle auswählen
      </mat-checkbox>
    </ng-container>

    <mat-option
      *ngFor="let o of filteredOptions | async"
      [value]="o.value"
    >
      <div style="display:flex; justify-content: space-between">
        <span class="display-name">{{ o.name }}</span>
        <span class="icon-wrapper">
          <mat-icon
            (click)="copy($event, o);"
            *ngIf="copyEnabled"
            class="blue-icon smaller-icon"
            svgIcon="{{EsvgFiles.copy}}"
            [matTooltip]="copyTooltip"
          ></mat-icon>

          <mat-icon
            (click)="delete($event, o);"
            *ngIf="deleteAlwaysEnabled || (deleteEnabled && isOptionUnused(o))"
            class="red-icon smaller-icon"
            svgIcon="{{EsvgFiles.delete}}"

            [matTooltip]="deleteTooltip"
          ></mat-icon>

          <mat-icon
            (click)="edit($event, o)" *ngIf="editEnabled"
            class="blue-icon smaller-icon"
            svgIcon="{{EsvgFiles.edit}}"
            [matTooltip]="editTooltip"
          ></mat-icon>
        </span>
      </div>
    </mat-option>

    <mat-option [value]="''" style="display: none">
    </mat-option>

    <kpi4me-create-new-entity
      *ngIf="createNewEnabled"
      class="create-new-option"
      [label]="newButtonLabel"
      (click)="createNewOption(label)"
    ></kpi4me-create-new-entity>


    <kpi4me-clear-entity
      *ngIf="clearEnabled"
      class="create-new-option"
      (click)="clearSelection()"
    ></kpi4me-clear-entity>
  </mat-select>

  <mat-icon
    class="left-arrow-icon"
    *ngIf="(arrowPosition === 'right')"
    matSuffix
    [svgIcon]="EsvgFiles.arrow_down"
  ></mat-icon>

  <mat-error>
    {{ this.getErrorMessage() }}
  </mat-error>
</mat-form-field>
