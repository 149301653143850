import {Component, input, InputSignal, model, ModelSignal, ViewEncapsulation} from '@angular/core';
import {EsvgFiles, KPI4meColor} from 'frontier/nucleus';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {NgClass, NgIf} from '@angular/common';

@Component({
  selector: 'lib-section-title',
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    NgIf,
    NgClass
  ],
  templateUrl: './section-title.component.html',
  styleUrl: './section-title.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SectionTitleComponent {
  title: InputSignal<string> = input.required<string>();
  color: InputSignal<KPI4meColor> = input<KPI4meColor>();
  icon: InputSignal<EsvgFiles> = input.required<EsvgFiles>();
  sideNavState: InputSignal<"closed" | "opened"> = input.required<'closed' | 'opened'>();
  categoryOpened: ModelSignal<boolean> = model.required<boolean>();

  getOpenCloseIcon(): string {
    if (this.categoryOpened()) {
      return EsvgFiles.arrow_down;
    } else {
      return EsvgFiles.arrow_up;
    }
  }

  toggleOpenClosed(): void {
    this.categoryOpened.update(opened => !opened);
  }
}
