<lib-renderer-container
  [params]="params"
>
  <ng-container *ngIf="isFloatValue(); else elseBlock">
    {{ params.value?.value | number: null:'de' }}
  </ng-container>
  <ng-template #elseBlock>
    {{ params.value?.value }}
  </ng-template>
</lib-renderer-container>

