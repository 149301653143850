import {GridOptions} from 'ag-grid-community';
import {CustomCellComponent} from './renderers/custom-cell/custom-cell.component';
import {CustomCellEditorComponent} from './editors/custom-cell-editor/custom-cell-editor.component';
import {
  TimeRangeCellEditorComponent
} from './editors/custom-cell-editor/time-range-cell-editor/time-range-cell-editor.component';
import {DateCellEditorComponent} from './editors/date-cell-editor/date-cell-editor.component';
import {EnumCellEditorComponent} from './editors/enum-cell-editor/enum-cell-editor.component';
import {EnumCellRendererComponent} from './renderers/enum-cell-renderer/enum-cell-renderer.component';
import {ReferenceCellRendererComponent} from './renderers/reference-cell-renderer/reference-cell-renderer.component';
import {ReferenceCellEditorComponent} from './editors/reference-cell-editor/reference-cell-editor.component';
import {
  ReferenceCellSelectionRendererComponent
} from './renderers/reference-cell-selection-renderer/reference-cell-selection-renderer.component';
import {DayColumnHeaderComponent} from './renderers/day-column-header/day-column-header.component';
import {FormCellEditorComponent} from './editors/form-cell-ceditor/form-cell-editor.component';
import {RepositoryFilterComponent} from './filters/repository-filter/repository-filter.component';
import {ColorCellRendererComponent} from './renderers/color-cell-renderer/color-cell-renderer.component';
import {ColorCellEditorComponent} from './editors/color-cell-editor/color-cell-editor.component';
import {LoadingRendererComponent} from './renderers/loading-renderer/loading-renderer.component';
import {LoadingComponent} from './overlays/loading/loading.component';
import {PercentageCellRendererComponent} from './renderers/percentage-cell-renderer/percentage-cell-renderer.component';
import {PercentageCellEditorComponent} from './editors/percentage-cell-editor/percentage-cell-editor.component';
import {BooleanCellEditorComponent} from './editors/boolean-cell-editor/boolean-cell-editor.component';
import {BooleanCellRendererComponent} from './renderers/boolean-cell-renderer/boolean-cell-renderer.component';
import {TimeOfDayCellEditorComponent} from './editors/time-of-day-cell-editor/time-of-day-cell-editor.component';
import {DateCellRendererComponent} from './renderers/date-cell-renderer/date-cell-renderer.component';
import {
  TimeOfDayCellRendererComponent
} from './renderers/time-of-day-cell-renderer/time-of-day-cell-renderer.component';
import {AG_GRID_GERMAN_LOCALE} from 'frontier/nucleus';
import {
  AutocompleteCellRendererComponent
} from './renderers/autocomplete-cell-renderer/autocomplete-cell-renderer.component';
import {AutocompleteCellEditorComponent} from './editors/autocomplete-cell-editor/autocomplete-cell-editor.component';
import {CurrencyCellRendererComponent} from "./renderers/currency-cell-renderer/currency-cell-renderer.component";
import {
  DocumentCellRendererSharedComponent
} from './renderers/document-cell-renderer/document-cell-renderer-shared.component';

/**
 * Grid options that are shared among all tables. It defines framework components that
 * extend the default ag-grid. It also defines the height of rows and header rows.
 */
export const SharedGridOptions: GridOptions = {
  rowModelType: 'infinite',
  cacheBlockSize: 20,
  cacheOverflowSize: 2,
  blockLoadDebounceMillis: 300,
  infiniteInitialRowCount: 1,
  maxConcurrentDatasourceRequests: 20,
  maxBlocksInCache: 10,
  rowHeight: 30,
  headerHeight: 35,
  suppressPropertyNamesCheck: true,
  popupParent: document.querySelector('body'),
  localeText: AG_GRID_GERMAN_LOCALE,
  loadingCellRenderer: 'loadingRendererComponent',
  loadingOverlayComponent: 'loadingOverlayComponent',
  rowSelection: 'multiple',
  defaultColDef: {
    resizable: true,
    maxWidth: 300,
    suppressKeyboardEvent: (params => params.event.key === 'Delete')
  },
  components: {
    customCell: CustomCellComponent,
    currencyCell: CurrencyCellRendererComponent,
    dateCell: DateCellRendererComponent,
    enumCell: EnumCellRendererComponent,
    enumCellEditor: EnumCellEditorComponent,
    referenceCell: ReferenceCellRendererComponent,
    referenceSelectionCell: ReferenceCellSelectionRendererComponent,
    referenceCellEditor: ReferenceCellEditorComponent,
    colorCellRenderer: ColorCellRendererComponent,
    colorCellEditor: ColorCellEditorComponent,
    dateCellEditor: DateCellEditorComponent,
    percentageCellRenderer: PercentageCellRendererComponent,
    timeOfDayCellRenderer: TimeOfDayCellRendererComponent,
    percentageCellEditor: PercentageCellEditorComponent,
    boolCellRenderer: BooleanCellRendererComponent,
    boolCellEditor: BooleanCellEditorComponent,
    customCellEditor: CustomCellEditorComponent,
    timeRangeCellEditor: TimeRangeCellEditorComponent,
    timeOfDayCellEditor: TimeOfDayCellEditorComponent,
    dayColumnHeader: DayColumnHeaderComponent,
    formCellEditor: FormCellEditorComponent,
    repositoryFilter: RepositoryFilterComponent,
    loadingRendererComponent: LoadingRendererComponent,
    loadingOverlayComponent: LoadingComponent,
    autocompleteRenderer: AutocompleteCellRendererComponent,
    autocompleteEditor: AutocompleteCellEditorComponent,
    documentCellRenderer: DocumentCellRendererSharedComponent,
  },
};
