import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {EsvgFiles} from 'frontier/nucleus';
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {IMenuOption} from '../user-menu/menu-option.interface';
import {MatIcon} from '@angular/material/icon';
import {NgFor, NgIf} from '@angular/common';

@Component({
  selector: 'kpi4me-options-menu',
  templateUrl: './options-menu.component.html',
  styleUrls: ['./options-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [MatMenuTrigger, MatMenu, NgFor, MatMenuItem, NgIf, MatIcon]
})
export class OptionsMenuComponent implements OnInit {
  @ViewChild(MatMenu, {static: true}) menuRef: MatMenu;
  @ViewChild(MatMenuTrigger, {static: true}) matMenuTrigger: MatMenuTrigger;

  @Input() options: IMenuOption[] = [];

  @Output() optionTriggered = new EventEmitter<any>()

  protected readonly EsvgFiles = EsvgFiles;

  constructor(protected cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    return;
  }
}
