// your NgbDateFRParserFormater extends from NgbDateParserFormatter
// Is a Injectable that have two functions
import {Injectable} from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct,} from '@ng-bootstrap/ng-bootstrap';
import {DateTime} from 'luxon';

@Injectable()
export class NgbDateDEParserFormatter extends NgbDateParserFormatter {
  readonly DT_FORMAT = 'dd.MM.yyyy';
  private DELIMITER = '.';

  parse(value: string): NgbDateStruct {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | string): string {
    if (!date) return '';
    let dateTime: DateTime;
    if (typeof date === 'string') {
      dateTime = DateTime.fromFormat(date, this.DT_FORMAT);
    } else {
      dateTime = DateTime.fromObject({
        year: date.year,
        month: date.month,
        day: date.day
      });
    }

    if (!dateTime.isValid) return '';
    return dateTime.toFormat(this.DT_FORMAT);
  }
}
