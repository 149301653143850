<div style="position: relative">
  <kpi4me-select-element
    *ngIf="getDisplayValue; else noDisplayValueError"
    [formControlElement]="formControlElement"
    [disabled]="disabled"
    [data]="selectionData"
    [compareWith]="compareBy"
    (modelChange)="onSelectedRowChange($event)"
    (newElementClick)="onNew()"
    (deleteElementClick)="delete($event)"
    [editEnabled]="editEnabled"
    [createNewEnabled]="showNew"
    [deleteEnabled]="showDelete"
    [singleStringArray]="singleStringArray"
    [newButtonLabel]="newButtonLabel"
  />

  @if (loading()) {
    <mat-progress-bar [mode]="'indeterminate'" style="position: absolute; width: 100%"/>
  }

</div>


<ng-template #noDisplayValueError>
  Table Row Selection needs a get Display Value function as input.
</ng-template>
