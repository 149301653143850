import {enableProdMode, importProvidersFrom, LOCALE_ID} from '@angular/core';
import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {SplitterModule} from 'primeng/splitter';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {routes} from './app/config/routes';
import {provideRouter, withEnabledBlockingInitialNavigation, withHashLocation} from '@angular/router';
import {bootstrapApplication, BrowserModule} from '@angular/platform-browser';
import {DatePipe, DecimalPipe, registerLocaleData} from '@angular/common';
import {
  API_CONFIG_TOKEN,
  ApiConfigService,
  Configuration as LibraryConfiguration,
  ConfigurationParameters,
  IApiConfig,
  StoreService,
  SvgService
} from 'frontier/nucleus';
import {DialogService, FeedbackService, TokenInterceptor} from 'frontier/browserkit';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BUILD_HASH_TOKEN} from 'frontier/nucleus/src/lib/configurations/build-hash';
import {Configuration} from './app/api/generated';
import {AUTHORIZATION_CONFIG_TOKEN,} from 'frontier/nucleus/src/lib/authorization/control-list.token';
import {AUTHORIZATION_CONFIG} from './app/config/authorization.config';

registerLocaleData(localeDe); // de-DE

export const API_CONFIG: IApiConfig = {
  url:
    environment.env === 'prod'
      ? window.location.origin
      : environment.url,
  environment,
};

export function libraryApiConfigFactory(): LibraryConfiguration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    basePath: environment.env === 'prod'
      ? window.location.origin
      : environment.url,
  }
  return new LibraryConfiguration(params);
}

export function appApiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set configuration parameters here.
    basePath: environment.env === 'prod'
      ? window.location.origin
      : environment.url,
  }
  return new Configuration(params);
}


if (environment.env === 'prod') {
  enableProdMode();
}

console.log('Running in environment', environment.env);

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      MatSidenavModule,
      MatToolbarModule,
      MatIconModule,
      MatTooltipModule,
      MatButtonModule,
      MatTabsModule,
      FormsModule,
      ReactiveFormsModule,
      SplitterModule,
      DatePipe
    ),
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {subscriptSizing: 'dynamic', appearance: 'outline'}},
    {provide: LOCALE_ID, useValue: 'de-DE'},
    {provide: API_CONFIG_TOKEN, useValue: API_CONFIG},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {provide: LibraryConfiguration, useFactory: libraryApiConfigFactory},
    {provide: Configuration, useFactory: appApiConfigFactory},
    {provide: BUILD_HASH_TOKEN, useValue: environment.buildHash},
    // Inject the control list from the library and the application into the authorization service
    {provide: AUTHORIZATION_CONFIG_TOKEN, useValue: AUTHORIZATION_CONFIG},
    DecimalPipe,
    SvgService,
    ApiConfigService,
    StoreService,
    DialogService,
    FeedbackService,
    DatePipe,
    provideRouter(routes, withEnabledBlockingInitialNavigation(), withHashLocation()),
  ]
}).catch((err) => console.error(err));
