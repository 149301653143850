import {Component, ElementRef, inject, ViewChild, ViewEncapsulation} from '@angular/core';
import {EsvgFiles, SignalControl} from 'frontier/nucleus';
import {ICellRendererParams} from 'ag-grid-community';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {ICustomColDef} from "frontier/browserkit";
import {toBlob} from 'frontier/nucleus/src/lib/utils/to-blob';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {MatSnackBar} from "@angular/material/snack-bar";

interface IDocumentCellRendererParams extends ICellRendererParams {
  accept: string
}

@Component({
  selector: 'kpi4me-document-cell-renderer-shared',
  template: `
    <mat-icon svgIcon="{{EsvgFiles.upload}}" class="blue-icon icon-button-centered" *ngIf="showUpload()"
              (click)="onEdit()">
    </mat-icon>
    <mat-icon *ngIf="showDownload()" svgIcon="{{EsvgFiles.download}}" class="blue-icon icon-button-centered"
              (click)="onDownload()">
    </mat-icon>
    <input
      #fileUpload
      style="display: none"
      type="file"
      [accept]="accept"
      (change)="addAttachment($event)"
    />
  `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: center;
        gap: 0.5rem;
      }

      mat-icon {
        cursor: pointer;
      }
    `
  ],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [NgIf, MatIcon],
})

export class DocumentCellRendererSharedComponent extends CustomCellComponent {

  document: { data?: string | ArrayBuffer; name?: any; fileFormate?: any } =
    null;
  @ViewChild('fileUpload') fileUploadRef: ElementRef;
  accept: string;

  protected readonly EsvgFiles = EsvgFiles;

  private _snackbar: MatSnackBar = inject(MatSnackBar);

  override agInit(params: IDocumentCellRendererParams) {
    if (params.accept) {
      this.accept = params.accept;
    }
    super.agInit(params);
  }

  onEdit() {
    this.fileUploadRef.nativeElement.click();
  }

  onDownload() {
    const d = this.params.value.value;
    this._controlService.controlGetDownloadDocument(
      (<SignalControl<any>>this.params.context).apiInstance().instanceid, d.rowid, d.type, d.signature,
    ).subscribe((data) => {
      if (data.size > 0) {
        const downloadURL = window.URL.createObjectURL(data as Blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = d.name;
        link.click();
      } else {
        this._snackbar.open('Es gibt leider kein Rechnungsdokument zum Download.', 'ok',
          {duration: 3000, verticalPosition: 'top'});
      }
    });
  }

  showDownload() {
    return !!this.params.value;

  }

  showUpload() {
    return this.params.value && this.params.value.editable;
  }

  addAttachment(evt: Event) {
    const fileReaded = (evt.target as any).files[0];
    console.log('add attachment');
    this.readFile(fileReaded);
  }

  private readFile(fileReaded: any) {
    this.params.api.showLoadingOverlay();
    const reader = new FileReader();
    reader.onloadend = (e) => {
      console.log(reader.result);
      // Ergebnis vom FileReader auslesen
      const fileNameSplit = fileReaded.name.split('.');
      this.document = {
        name: fileReaded.name,
        data: e.target.result,
        fileFormate: fileNameSplit?.length > 0 ? fileNameSplit[fileNameSplit.length - 1] : '',
      };
      this._controlService.controlPostUploadDocument(this.document.name, this.document.fileFormate,
        this.params.data.apiRow.obj.rowid, this.params.data.apiRow.obj.type,
        this.params.data.apiRow.obj.signature, (this.params.colDef as ICustomColDef).apiCol.attribute,
        toBlob(this.document.data as ArrayBuffer)).subscribe({
        next: () => {
          this.params.api.hideOverlay();
          this.params.api.refreshInfiniteCache();
        },
        error: () => this.params.api.hideOverlay()
      });
    };
    reader.readAsArrayBuffer(fileReaded);
  }
}
