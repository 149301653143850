import {AfterViewInit, Component, HostListener, ViewChild, ViewContainerRef,} from '@angular/core';
import {ICellEditorParams} from 'ag-grid-community';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {NgbDate, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {FormControl} from '@angular/forms';
import {
  DateElementComponent
} from '../../../form-control/dynamic-form/form-element/date-element/date-element.component';

interface IDateData {
  year: number | string;
  month: number | string;
  day: number | string;
}

@Component({
  selector: 'app-date-cell-editor',
  templateUrl: './date-cell-editor.component.html',
  styleUrls: ['../custom-cell-editor/custom-cell-editor.component.scss'],
  standalone: true,
  imports: [DateElementComponent],
})
export class DateCellEditorComponent
  extends CustomCellEditorComponent
  implements AfterViewInit {
  @ViewChild(NgbInputDatepicker) datePickerRef: NgbInputDatepicker;
  @ViewChild(DateElementComponent) dateElementRef: DateElementComponent;
  @ViewChild('input', {read: ViewContainerRef}) public override input: ViewContainerRef;

  override params: ICellEditorParams;
  startDate: NgbDate;
  dateString: string;

  @HostListener('document:click', ['$event'])
  protected override clickOut(event: MouseEvent) {

  }

  override agInit(params: ICellEditorParams) {
    this.params = params;
    this.oldValue = {...this.params.value};

    // initialize the start date of the calendar to jump to the selected value. If there is no previous date, jump to the current date.
    if (params.value.value) {
      console.log(params.value.value);
      this.textFormControl = new FormControl(params.value.value);
    } else {
      this.textFormControl = new FormControl(null);
    }
    if (params.charPress) {
      this.handlePaste(params);
    }
  }

  override ngAfterViewInit() {
    // focus on the input
    setTimeout(() => {
      this.dateElementRef.inputRef.nativeElement.focus();
    });

    // this.datePickerRef.open();
    this.dateElementRef.datePickerRef.open();
    this.dateElementRef.datePickerRef.navigateTo(this.startDate);
  }

  override ngOnDestroy() {
    this.saveValue();
  }

  override isCancelAfterEnd() {
    return this.canceled || this.oldValue.value === this.textFormControl.value;
  }

  override getValue(): any {
    return this.params.value;
  }

  override isPopup(): boolean {
    return true;
  }

  protected override saveValue() {
    if (this.isCancelAfterEnd()) {
      return;
    }
    const colDef: any = this.params.colDef;
    if (this.textFormControl.value) {

      const val = this.textFormControl.value;
      super
        .changeLine(
          this.params.data.apiRow.obj,
          this.params.data.apiRow.rowidx,
          colDef.attribute,
          colDef.attributeindex,
          val,
        ).subscribe();
    } else {
      super
        .changeLine(
          this.params.data.apiRow.obj,
          this.params.data.apiRow.rowidx,
          colDef.attribute,
          colDef.attributeindex,
          null,
        ).subscribe();
    }

  }

  protected override handlePaste(params: ICellEditorParams) {
    if (params.charPress === 'delete') {
      super.handlePaste(params);
      return;
    }
    if (params.charPress.includes('#pasted')) {
      const pasted: string = JSON.parse(params.charPress.slice(7));
      this.setPastedValue(pasted);
    } else {
      this.textFormControl.patchValue(this.textFormControl.value + params.charPress);
    }
  }

  protected override setPastedValue(pasted: string) {
    console.log(pasted);
    const dateString = pasted;
    this.textFormControl.patchValue(dateString);
    this.params.value.value = dateString;
    this.params.api.stopEditing();
  }

  private getDateString(value: IDateData): string {
    const year = String(value.year);
    const month = String(value.month);
    const day = String(value.day);
    if (year.length > 4 || month.length > 2 || day.length > 2 || year.length < 1 || month.length < 1 || day.length < 1) {
      console.error('Date does not have the correct form.');
      return '';
    }
    return year + '-' + (month.length === 1 ? ('0' + month) : month) + '-' + (day.length === 1 ? ('0' + day) : day);
  }
}
