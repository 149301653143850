import {Component, inject} from '@angular/core';
import {SvgService, ThemeService} from 'frontier/nucleus';
import {RouterOutlet} from '@angular/router';


@Component({
  selector: 'kpi4me-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    RouterOutlet,
  ],
})
export class AppComponent {
  title = 'calculus';

  svgService = inject(SvgService)
  themeService = inject(ThemeService)

}
