<!--<div fxLayout="column">-->
<!--  <mat-selection-list-->
<!--    #input-->
<!--    (selectionChange)="onSelectionChange()"-->
<!--    *ngIf="params?.value"-->
<!--    [(ngModel)]="params.value.value"-->
<!--    [multiple]="params.isMulti"-->
<!--  >-->
<!--    <mat-list-option-->
<!--      *ngFor="let name of getRepository(); index as i"-->
<!--      [color]="'primary'"-->
<!--      [value]="i"-->
<!--      class="smaller-list-item"-->
<!--    >-->
<!--      {{ name }}-->
<!--    </mat-list-option>-->
<!--  </mat-selection-list>-->

<!--  <app-confirm-icon-button-->
<!--    (customClick)="onConfirm()"-->
<!--    *ngIf="params.isMulti"-->
<!--  ></app-confirm-icon-button>-->
<!--</div>-->
<kpi4me-select-element class="form-field-no-padding"
                       [formControlElement]="textFormControl"
                       (modelChange)="onSelectionChange()"
                       [editEnabled]="false"
                       [deleteEnabled]="false"
                       [data]="selectElementData"
                       [compareWith]="compareWith"
                       [confirmButton]="true"
></kpi4me-select-element>
