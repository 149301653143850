import {Component, ElementRef, HostListener, inject, ViewChild,} from '@angular/core';

import {ICellEditorParams} from 'ag-grid-community';
import {NgxMaterialTimepickerComponent, NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {Subscription} from 'rxjs';
import {DatePipe} from '@angular/common';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {MatButton} from '@angular/material/button';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-time-of-day-cell-editor',
  templateUrl: './time-of-day-cell-editor.component.html',
  styleUrls: ['../custom-cell-editor/custom-cell-editor.component.scss'],
  standalone: true,
  imports: [MatButton, NgxMaterialTimepickerModule, FormsModule],
})
export class TimeOfDayCellEditorComponent extends CustomCellEditorComponent {
  private _datePipe = inject(DatePipe);

  @ViewChild('inputField') timePickerInput: ElementRef<any>;
  @ViewChild(NgxMaterialTimepickerComponent)
  timePickerRef: NgxMaterialTimepickerComponent;

  subs = new Subscription();
  time: string = '';
  private timePickerOpen: boolean = false;

  override agInit(params: ICellEditorParams) {
    super.agInit(params);

    if (!params.charPress) {
      this.time = this._datePipe.transform(params.value.value, 'HH:mm');
      this.textFormControl.patchValue(this.time);
    }
  }

  override ngAfterViewInit() {
    this.subs.add(
      this.timePickerRef.opened.subscribe(() => {
        this.timePickerOpen = true;
      })
    );
    this.subs.add(
      this.timePickerRef.closed.subscribe(() => {
        this.timePickerOpen = false;
        this.textFormControl.patchValue(this.time);
        this.params.api.stopEditing();
      })
    );

    setTimeout(() => {
      this.timePickerInput.nativeElement.click();
    }, 0);
  }

  // detect clicks outside
  @HostListener('document:click', ['$event'])
  protected override clickOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const timePickerContainer = document.getElementsByTagName(
      'ngx-material-timepicker-container'
    )[0];
    if (
      !this._elementRef.nativeElement.contains(target) &&
      (timePickerContainer == undefined ||
        !timePickerContainer.contains(target)) &&
      this.timePickerOpen == false
    ) {
      this.params.api.stopEditing();
    }
  }

  override isPopup(): boolean {
    return false;
  }

  onTimeChanged(evt: string) {
    this.time = evt;
  }
}
