import {Component, effect, EventEmitter, inject, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {TableControlComponent} from '../table-control/table-control.component';
import {EControlActions} from 'frontier/nucleus';
import {mergeMap, tap} from 'rxjs/operators';
import {IApiRow} from '../table-control/api-row.interface';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AbstractControl} from '@angular/forms';
import {
  SelectElementComponent
} from "../form-control/dynamic-form/form-element/select-element/select-element.component";
import {ISelectFormElement, ISelectFormOption} from '../form-control/dynamic-form/form-element/form-data.interface';
import {NgIf} from '@angular/common';
import {IApiTableData} from 'frontier/browserkit';
import {MatProgressBar} from "@angular/material/progress-bar";
import {patchState} from '@ngrx/signals';

export const tableRowSelectionImports = [NgIf, SelectElementComponent, MatProgressBar];

@Component({
  selector: 'kpi4me-table-row-selection',
  templateUrl: './table-row-selection.component.html',
  styleUrls: ['./table-row-selection.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: tableRowSelectionImports,
})
export class TableRowSelectionComponent extends TableControlComponent {
  private _snackBar = inject(MatSnackBar);

  @ViewChild(SelectElementComponent) selectRef: SelectElementComponent;

  @Input() override toModel(d: IApiTableData) {}
  @Input() showNew = false;
  @Input() newButtonLabel = 'Neu';
  @Input() showDelete = true;
  @Input() disabled = false;
  @Input() singleStringArray = true;
  @Input() getDisplayValue: (row: any) => string;
  @Input() multiple = false;
  @Input() editEnabled = true;
  @Input() formControlElement: AbstractControl;
  @Input() selectionData: ISelectFormElement;
  @Output() selectedRowChange = new EventEmitter<IApiRow>();
  @Output() newClicked = new EventEmitter();
  @Output() rowsChanged = new EventEmitter<IApiRow[]>();

  rows: IApiRow[];

  compareBy = (e1: any, e2: any) => {
    return e1 && e2 && e1.objid === e2.objid;
  };

  constructor() {
    super();
    effect(() => {
      const apiData = this.apiData();

      if (apiData) {
        this.fetchRows().subscribe();
      }
    }, {allowSignalWrites: true});
  }

  fetchRows() {
    patchState(this.state, {loading: true});
    return this._tableService.tablePostFetchRows({
      _parameters: [
        this.instanceId(),
        0,
        this.apiData().rowcount,
        0,
        this.apiData().displayedColumns.length - 1
      ]
    }).pipe(tap((_: object []) => {
      const rows = _ as IApiRow[];
      this.setRows(rows)
      patchState(this.state, {loading: false});
    }));
  }

  protected setRows(rows: IApiRow[]) {
    console.log(rows);
    this.rows = rows;
    this.rowsChanged.emit(rows);
    this.selectionData = {
      ...this.selectionData,
      options: rows.map(r => {
        return {
          name: this.getDisplayValue ? this.getDisplayValue(r) : JSON.stringify(r),
          value: r
        }
      }),
      multiple: this.multiple
    }
    this.cdr.detectChanges();
  }

  onSelectedRowChange(event: any) {
    if (event != null) {
      this.selectedRowChange.emit(event);
    }
  }

  @Input() onNew() {
    this._tableControlService.tableControlNewLine({InstanceId: this.instanceId()})
      .pipe(
        mergeMap(() => {
          return this.fetchRows()
        })
      ).subscribe(
      (_: object[]) => {
        const rows = _ as IApiRow[];
        this.formControlElement.patchValue(rows[0], {emitEvent: false});
        this.selectedRowChange.emit(rows[0]);
        this.controlStore.controlDataChanged$.emit({GUID: this.GUID, changeType: EControlActions.create})
        this.newClicked.emit();
      }
    );
  }

  // deletes the row by calling the api. Re-fetches the table afterward
  delete(obj: ISelectFormOption) {
    this._tableControlService.tableControlDeleteLine(
      {InstanceId: this.instanceId(), RowObjects: [obj.value.obj]}
    ).pipe(
      tap((result) => {
        if (result == false) {
          this._snackBar.open('Der Eintrag kann nicht gelöscht werden', 'ok', {duration: 5000})
        } else {
          this.rowDeleted.emit();
          if (this.formControlElement.value == null || this.formControlElement.value.signature === obj.signature) {
            this.formControlElement.reset();
            this.selectedRowChange.emit(null);
          }
          this.controlStore.controlDataChanged$.emit({GUID: this.GUID, changeType: EControlActions.delete})
        }
      }),
      mergeMap(() => {
        return this.fetchRows()
      })
    ).subscribe();
  }
}
