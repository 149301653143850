<app-dialog-header
  [dialogRef]="dialogRef"
  [title]="'Hierarchie Importieren'"
  (close)="dialogRef.close(null)"
>
</app-dialog-header>
<mat-dialog-content [formGroup]="form">
  <div class="flex-column" style="gap: 1rem;">
    <mat-slide-toggle [formControlName]="'asCopy'" >Hierarchie als Kopie importieren.</mat-slide-toggle>
    <app-upload-wrapper [formControlName]="'file'" readAs="Text"/>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <app-confirm-icon-button
    (customClick)="this.confirm()"
    [disabled]="form.invalid"
  ></app-confirm-icon-button>
</mat-dialog-actions>
