import {Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerConfig,
  NgbDateStruct,
  NgbInputDatepicker,
  NgbPeriod,
} from '@ng-bootstrap/ng-bootstrap';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, filter} from 'rxjs/operators';
import {PlacementArray} from '@ng-bootstrap/ng-bootstrap/util/positioning';
import {EGranularity} from './granularity.enum';
import {FormsModule, NgModel} from '@angular/forms';
import {FromNgbDate, ToNgbDate} from './date-adapter.const';
import {IGranularity} from '../easy-date-picker/granularity-rotator/granularity.interface';
import {DateTime} from 'luxon';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {GranularityRotatorComponent} from '../easy-date-picker/granularity-rotator/granularity-rotator.component';
import {IClassicCalendar} from '../../home-without-gl/toolbar/interfaces';
import {NgbDateDEParserFormatter} from './ngb-date-de-parser-formatter';
import {FeedbackService} from '../../../services/feedback.service';
import {EsvgFiles} from 'frontier/nucleus/src/lib/svg/svg.service';
import {ITheme} from 'frontier/nucleus';

function ngbDateConfiguration() {
  const ngbDateConfig = new NgbDatepickerConfig();
  ngbDateConfig.minDate = {year: 1899, month: 1, day: 1};
  ngbDateConfig.maxDate = {year: 2099, month: 12, day: 31};
  return ngbDateConfig;
}

@Injectable()
class NgbDateAdapterDefault extends NgbDateAdapter<any> {
  toModel(date: NgbDateStruct | null): any {
    return date;
  }

  fromModel(value: any): NgbDateStruct | null {
    return value;
  }
}


@Component({
  selector: 'kpi4me-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  standalone: true,
  imports: [
    NgbInputDatepicker,
    GranularityRotatorComponent,
    FormsModule,
    NgIf,
    MatIcon,
    MatTooltip,
  ],
  providers: [
    {provide: NgbDateAdapter, useClass: NgbDateAdapterDefault},
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateDEParserFormatter,
    },
    {
      provide: NgbDatepickerConfig,
      useFactory: ngbDateConfiguration,
    },
  ]
})
export class CalendarComponent implements OnInit {
  @ViewChild(NgbInputDatepicker) datePicker: NgbInputDatepicker;
  @ViewChild('dpFromDate') fromDateRef: NgbInputDatepicker;
  @ViewChild('dpToDate') toDateRef: NgbInputDatepicker;
  @Input() placement: PlacementArray =
    'bottom-left bottom-right top-left top-right';
  @Input() showGranularityButtons = true;
  @Input() theme: ITheme;
  @Input() selectedGranularity: EGranularity | null =
    EGranularity.month;
  @Output() dateChange: EventEmitter<IClassicCalendar> =
    new EventEmitter<IClassicCalendar>();

  minDate = {year: 1990, month: 1, day: 1};
  fromDateInput: string = '';
  toDateInput: string = '';

  private openedAs: 'from' | 'to';
  granularities: IGranularity[] = [
    {id: EGranularity.day, shortcut: 'D', name: 'Tag'},
    {id: EGranularity.week, shortcut: 'W', name: 'Woche'},
    {id: EGranularity.month, shortcut: 'M', name: 'Monat'},
    {id: EGranularity.quarter, shortcut: 'Q', name: 'Quartal'},
    {
      id: EGranularity.halfyear,
      shortcut: 'H',
      name: 'Halbjahr',
    },
    {id: EGranularity.year, shortcut: 'Y', name: 'Jahr'},
  ];
  _toDate: NgbDate | null;
  // for each granularity, the dates are saved. So when the granularity changes back again,
  // we can restore the previous dates. [GranularityEnum]: {from: NgbDate, to: NgbDate}
  private granularityDateState: any = {};

  hoveredDate: NgbDate | null = null;
  subscriptions = new Subscription();
  form: any;

  filteredGranularities: IGranularity[] = [];
  private oldFromDate: NgbDate;
  private oldToDate: NgbDate;

  @Input() set lowestGranularity(granularity: EGranularity) {
    this.filteredGranularities = this.granularities.filter(
      (g) => g.id >= granularity
    );
  }

  delayTime = 100;
  delayedEvent = new Subject();
  dateChangeDelayed = this.delayedEvent
    .pipe(
      filter(() => (!this.isInvalidDate(this.fromDate) && !this.isInvalidDate(this.toDate) && this.fromDateBeforeToDate())),
      debounceTime(this.delayTime)
    )
    .subscribe(() => {
      this.dateChange.emit({
        fromDate: this.fromDate,
        granularity: this.selectedGranularity,
        toDate: this.toDate,
      });
      this.fromDateRef.close();
      this.toDateRef.close();
    });

  _fromDate: NgbDate | null;
  private maxColumnCount = 100;
  private dateAmountBefore: number;
  private dateAmountAfter: number;
  protected readonly EsvgFiles = EsvgFiles;

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    public adapter: NgbDateAdapter<NgbDate>,
    private feedbackService: FeedbackService,
  ) {
    console.log(this);
    // this.outputData();
  }

  @Input() set config(c: IClassicCalendar) {
    if (c) {
      this._fromDate = c.fromDate;
      this.oldFromDate = c.fromDate;
      this._toDate = c.toDate;
      this.oldToDate = c.toDate;
      this.selectedGranularity = c.granularity;
    } else {
      console.error('no config given. Default init not implemented');
    }
  }

  private static granularityToPeriodAndCount(granularity: EGranularity): {
    period: NgbPeriod;
    n: number;
  } {
    let period: NgbPeriod = 'd';
    let n = 1;
    switch (granularity) {
      case EGranularity.day:
        n = 1;
        break;
      case EGranularity.week:
        n = 7;
        break;
      case EGranularity.month:
        period = 'm';
        break;
      case EGranularity.quarter:
        period = 'm';
        n = 3;
        break;
      case EGranularity.halfyear:
        period = 'm';
        n = 6;
        break;
      case EGranularity.year:
        period = 'y';
        break;
    }
    return {period, n};
  }

  ngOnInit() {
    this.delayTime = 500;
  }


  // external setter for updates
  @Input() set updateData(data: IClassicCalendar) {
    if (data) {
      if (data.fromDate != null) {
        this._fromDate = data.fromDate;
      }
      if (data.toDate != null) {
        this._toDate = data.toDate;
      }
      if (data.granularity != null) {
        this.selectedGranularity = data.granularity;
      }
    }
  }

  get fromDate(): NgbDate | null {
    return this._fromDate;
  }

  @Input()
  set fromDate(fromDate: NgbDate | null) {
    if (
      fromDate &&
      (!this._fromDate ||
        this._fromDate.equals == undefined ||
        !this._fromDate.equals(fromDate))
    ) {
      // check if the other date got adapted because it lied outside the valid range:
      // the new from date lies behind the current to date => adapt the to date.
      // if (this.toDate && this.fromDate) {
      //   const newAdaptedDate = this.adaptDate(fromDate, this.fromDate, this.toDate, 'before');
      //   // the other date got adapted, set the to date
      //   if (newAdaptedDate) {
      //     this._toDate = newAdaptedDate;
      //     this.toDateInput = this.formatter.format(newAdaptedDate);
      //     console.log('adapted day', newAdaptedDate);
      //   }
      // }
      this._fromDate = fromDate;
    }
  }

  get toDate(): NgbDate | null {
    return this._toDate;
  }

  @Input()
  set toDate(toDate: NgbDate | null) {
    if (
      toDate &&
      (!this._toDate ||
        this._toDate.equals == undefined ||
        !this._toDate.equals(toDate))
    ) {
      this._toDate = toDate;
    }
  }


  onDateSelection(date: NgbDate) {
    // Handle the calendar input when opened by clicking on the from input field.
    if (this.openedAs === 'from') {
      this.fromDate = date;
    }
    // Handle the calendar input when opened by clicking on the to input field.
    else if (this.openedAs === 'to') {
      if (date.after(this.fromDate)) {
        this.toDate = date;
      }
    } else {
      // Handle the selection when opened the calendar by clicking on the icon. Select a range..
      if (!this.fromDate && !this.toDate) {
        this.fromDate = date;
      } else if (
        this.fromDate &&
        !this.toDate &&
        date &&
        date.after(this.fromDate)
      ) {
        this.toDate = date;
        this.datePicker.close();
      } else {
        this.toDate = null;
        this.fromDate = date;
      }
    }
    this.resetGranularitySaveState();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  onDatePickerToggle(
    datepicker: NgbInputDatepicker,
    openedType: 'from' | 'to' | null
  ) {
    console.log(datepicker);
    this.openedAs = openedType;
    if (datepicker.isOpen()) {
      datepicker.close();
    } else {
      datepicker.open();
    }
  }

  isFirst(date: NgbDate) {
    return this.fromDate && date.equals(this.fromDate);
  }

  isLast(date: NgbDate) {
    return this.toDate && date.equals(this.toDate);
  }

  // map the from and to date to the correct date of the granularity
  // round down for each date to the first occurrence date of the granularity
  // round up for each date to the last occurrence date of the granularity
  // For weeks:  from date: first day of week; to date: last day of week
  // For Months: from date: first day of month;  to date: last day of month
  // For Quarters: from date: first day of quarter;  to date: last day of quarter
  // For half years: from date: first day of half year (01.01, 01.07); to date: last day of half year (30.06, 31.12)
  // For months: from date: first day of year; to date: last day of year
  onGranularityChange(granularity: EGranularity) {
    this.saveGranularityDateState();
    if (this.fromDate != null && this.toDate != null) {
      let fromDate = this.fromDate;
      let toDate = this.toDate;
      switch (granularity) {
        case EGranularity.week: {
          const weekdayFrom = this.calendar.getWeekday(this.fromDate);
          const weekdayTo = this.calendar.getWeekday(this.toDate);
          const firstDayOfWeekFromDate = this.calendar.getPrev(
            this.fromDate,
            'd',
            weekdayFrom - 1
          );
          const lastDayOfWeekToDate = this.calendar.getNext(
            this.toDate,
            'd',
            7 - weekdayTo
          );
          fromDate = firstDayOfWeekFromDate;
          toDate = lastDayOfWeekToDate;
          break;
        }
        case EGranularity.month:
          fromDate = new NgbDate(this.fromDate.year, this.fromDate.month, 1);
          toDate = this.calendar.getPrev(
            this.calendar.getNext(
              new NgbDate(this.toDate.year, this.toDate.month, 1),
              'm',
              1
            ),
            'd',
            1
          );
          break;
        case EGranularity.quarter: {
          let date;
          const quarterDatesFrom = [
            new NgbDate(this.fromDate.year, 1, 1),
            new NgbDate(this.fromDate.year, 4, 1),
            new NgbDate(this.fromDate.year, 7, 1),
            new NgbDate(this.fromDate.year, 10, 1),
          ];
          for (let i = 0; i < quarterDatesFrom.length; i++) {
            if (this.fromDate.before(quarterDatesFrom[i])) {
              break;
            } else {
              date = quarterDatesFrom[i];
            }
          }
          if (date != null) {
            fromDate = date;
          } else {
            console.error('Unexpected date value for from date');
          }
          const quarterDatesTo = [
            new NgbDate(this.toDate.year, 3, 31),
            new NgbDate(this.toDate.year, 6, 30),
            new NgbDate(this.toDate.year, 9, 30),
            new NgbDate(this.toDate.year, 12, 31),
          ];
          for (let i = 0; i < quarterDatesTo.length; i++) {
            if (
              this.toDate.before(quarterDatesTo[i]) ||
              this.toDate.equals(quarterDatesTo[i])
            ) {
              date = quarterDatesTo[i];
              break;
            } else {
              date = quarterDatesTo[i];
            }
          }
          if (date != null) {
            toDate = date;
          } else {
            console.error('Unexpected date value for to date');
          }
          break;
        }
        case EGranularity.halfyear:
          if (this.fromDate.month >= 7) {
            fromDate = new NgbDate(this.fromDate.year, 7, 1);
          } else {
            fromDate = new NgbDate(this.fromDate.year, 1, 1);
          }
          if (this.toDate.before(new NgbDate(this.toDate.year, 7, 1))) {
            toDate = new NgbDate(this.toDate.year, 6, 30);
          } else {
            toDate = new NgbDate(this.toDate.year, 12, 31);
          }
          break;
        case EGranularity.year:
          fromDate = new NgbDate(this.fromDate.year, 1, 1);
          toDate = new NgbDate(this.toDate.year, 12, 31);
      }
      this.dateAmountBefore = this.getDateAmount(this.fromDate, this.toDate);
      this.selectedGranularity = granularity;
      if (this.granularityDateState[granularity]) {
        fromDate = this.granularityDateState[granularity].from;
        toDate = this.granularityDateState[granularity].to;
      }
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.saveGranularityDateState();
      this.dateAmountAfter = this.getDateAmount(this.fromDate, this.toDate);
    }
    this.validateDateRange();
    this.outputData();
  }

  getNextToDate(date: NgbDate) {
    const periodNumberObject = CalendarComponent.granularityToPeriodAndCount(
      this.selectedGranularity
    );
    return this.calendar.getNext(
      date,
      periodNumberObject.period,
      periodNumberObject.n
    );
  }

  getNextFromDate(date: NgbDate) {
    const periodNumberObject = CalendarComponent.granularityToPeriodAndCount(
      this.selectedGranularity
    );
    const nextDate = this.calendar.getNext(
      date,
      periodNumberObject.period,
      periodNumberObject.n
    );
    return nextDate;
    // return nextDate.before(this.toDate) ? nextDate : this.calendar.getPrev(this.toDate as NgbDate, 'd', 1);
  }

  getPreviousToDate(date: NgbDate) {
    const periodNumberObject = CalendarComponent.granularityToPeriodAndCount(
      this.selectedGranularity
    );
    const prevDate = this.calendar.getPrev(
      date,
      periodNumberObject.period,
      periodNumberObject.n
    );
    return prevDate;
    // return prevDate.after(this.fromDate) ? prevDate : this.calendar.getNext(this.fromDate as NgbDate, 'd', 1);
  }

  getPreviousFromDate(date: NgbDate) {
    const periodNumberObject = CalendarComponent.granularityToPeriodAndCount(
      this.selectedGranularity
    );
    return this.calendar.getPrev(
      date,
      periodNumberObject.period,
      periodNumberObject.n
    );
  }

  onNextDateToDate() {
    this.toDate = this.getNextToDate(this.toDate as NgbDate);
    this.resetGranularitySaveState();
    this.onBlurToDate();
  }

  onPreviousDateToDate() {
    this.toDate = this.getPreviousToDate(this.toDate as NgbDate);
    this.resetGranularitySaveState();
    this.onBlurToDate();
  }

  onNextDateFromDate() {
    this.fromDate = this.getNextFromDate(this.fromDate as NgbDate);
    this.resetGranularitySaveState();
    this.onBlurFromDate();
  }

  onPreviousDateFromDate() {
    this.fromDate = this.getPreviousFromDate(this.fromDate as NgbDate);
    this.resetGranularitySaveState();
    this.onBlurFromDate();
  }

  onBlurFromDate() {
    if (this.oldFromDate.equals(this.fromDate)) return;

    if (this.toDate && this.fromDate && this.oldFromDate) {
      const newAdaptedDate = this.adaptDate(
        this.fromDate,
        this.oldFromDate,
        this.toDate,
        'before'
      );
      // the other date got adapted, set the to date
      if (newAdaptedDate) {
        this._toDate = newAdaptedDate;
        this.toDateInput = this.formatter.format(newAdaptedDate);
        console.log('adapted day', newAdaptedDate);
      }
    }
    this.oldFromDate = this.fromDate;
    this.outputData();
  }

  // Method which resets the date to a maximum of 100 date items
  private getDateAmount(fromDate: NgbDate, toDate: NgbDate): number {
    const periodNumberObject = CalendarComponent.granularityToPeriodAndCount(
      this.selectedGranularity
    );
    let columnCount = 0;
    if (!fromDate || !toDate) {
      return 0;
    }
    let fdate = new NgbDate(fromDate.year, fromDate.month, fromDate.day);
    let maxDate: NgbDate | null;
    switch (this.selectedGranularity) {
      case EGranularity.year:
      case EGranularity.halfyear:
        maxDate = this.calendar.getNext(toDate, 'm', 5);
        break;
      case EGranularity.quarter:
        maxDate = this.calendar.getNext(toDate, 'm', 2);
        break;
      case EGranularity.month:
        maxDate = this.toDate as NgbDate;
        break;
      case EGranularity.week:
        maxDate = this.calendar.getNext(toDate, 'd', 4);
        break;
      case EGranularity.day:
        maxDate = this.calendar.getNext(toDate, 'd', 1);
        break;
      default:
        maxDate = null;
    }
    // (periodNumberObject.period === 'y') ?
    //    this.calendar.getNext(this.toDate, 'm', 15) : this.fromDate;
    if (maxDate == null) {
      console.error('Unexpected granularity. No max date could be defined');
    } else {
      while (fdate.before(maxDate)) {
        fdate = this.calendar.getNext(
          fdate,
          periodNumberObject.period,
          periodNumberObject.n
        );
        columnCount++;
      }
    }
    return columnCount;
  }

  // if the amount of dates is higher then 100 => clamp the date such that the amount is the same as before
  private clampDates(dateAmount: number) {
    const obj = CalendarComponent.granularityToPeriodAndCount(
      this.selectedGranularity
    );
    this._fromDate = this.calendar.getPrev(
      this.toDate as NgbDate,
      obj.period,
      dateAmount
    );
    this.feedbackService.setNotification(
      `Die maximale Spaltenanzahl ist ${this.maxColumnCount}.
     Das von-Datum wurde automatisch auf den ${
        (this.fromDate as NgbDate).day
      }.${(this.fromDate as NgbDate).month}.${
        (this.fromDate as NgbDate).year
      } gesetzt.`,
      5000
    );
  }

  // checks if number of dates is under the maximum and claps it if not
  private validateDateRange() {
    if (this.dateAmountAfter > this.maxColumnCount) {
      this.clampDates(this.dateAmountBefore);
    }
  }

  private saveGranularityDateState() {
    this.granularityDateState[this.selectedGranularity] = {
      from: this.fromDate,
      to: this.toDate,
    };
  }

  private resetGranularitySaveState() {
    this.granularityDateState = {};
  }

  // onBlurFromDate(fromDateInput: string, dpToDate: NgModel) {
  //   const validDate: NgbDate = this.parseDate(fromDateInput, dpToDate);
  //   this.fromDate = validDate;
  // }

  // }

  onBlurToDate() {
    if (this.oldToDate.equals(this.toDate)) return;
    // adapt the other date if the new date is outside the valid range:
    // Check if the new toDate is set before the current from date => If yes the from date should be adapted by the same value to the left.
    if (this.fromDate && this.toDate && this.oldToDate) {
      const newAdaptedDate = this.adaptDate(
        this.toDate,
        this.oldToDate,
        this.fromDate,
        'after'
      );
      // the other date got adapted, set the from date
      if (newAdaptedDate) {
        this._fromDate = newAdaptedDate;
        this.fromDateInput = this.formatter.format(newAdaptedDate);
      }
    }
    this.oldToDate = this.toDate;
    this.outputData();
  }

  // onBlurToDate(fromDateInput: string, dpToDate: NgModel) {
  //   const validDate: NgbDate = this.parseDate(fromDateInput, dpToDate);
  //   this.toDate = validDate;

  isInvalidDate(fromDateInput: NgbDate) {
    const validDate = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
    const formattedDate = this.formatter.format(fromDateInput);
    return !formattedDate.match(validDate);
  }

  fromDateBeforeToDate(): boolean {
    return this.fromDate.before(this.toDate) || this.fromDate.equals(this.toDate);
  }

  private isValidDateModel(dpToDate: NgModel): boolean {
    return dpToDate.status == 'VALID';
  }

  private parseDate(dateString: string, dateModel: NgModel): NgbDate | null {
    if (this.isValidDateModel(dateModel)) {
      const ngbDateStruct = this.formatter.parse(
        dateString as string
      ) as NgbDateStruct;
      return new NgbDate(
        ngbDateStruct.year,
        ngbDateStruct.month,
        ngbDateStruct.day
      );
    } else {
      return null;
    }
  }

  private adaptDate(
    newDate: NgbDate,
    oldDate: NgbDate,
    referenceDate: NgbDate,
    type: 'before' | 'after'
  ): NgbDate | null {
    const newDateTime = FromNgbDate(newDate);
    const oldDateTime = FromNgbDate(oldDate);
    const referenceDateTime: DateTime = FromNgbDate(referenceDate);

    if (type == 'before') {
      if (referenceDateTime < newDateTime) {
        const dayDiff = newDateTime.diff(oldDateTime, 'days').days;
        console.log('daydiff', dayDiff);
        return ToNgbDate(referenceDateTime.plus({days: dayDiff}));
      }
    } else {
      if (referenceDateTime > newDateTime) {
        const dayDiff = oldDateTime.diff(newDateTime, 'days').days;
        console.log('daydiff', dayDiff);
        return ToNgbDate(referenceDateTime.minus({days: dayDiff}));
      }
    }

    return null;
  }

  private outputData() {
    if (
      this.fromDate &&
      this.toDate &&
      !this.isInvalidDate(this.fromDate) &&
      !this.isInvalidDate(this.toDate)
    ) {
      this.delayedEvent.next(null);
    }
  }
}
