import {Component} from '@angular/core';
import {EsvgFiles} from 'frontier/nucleus';
import {MatIcon} from '@angular/material/icon';
import {MatButton} from '@angular/material/button';

@Component({
  selector: 'app-cancel-icon-button',
  templateUrl: './cancel-icon-button.component.html',
  styleUrls: ['./cancel-icon-button.component.scss'],
  standalone: true,
  imports: [MatButton, MatIcon],
})
export class CancelIconButtonComponent {
  protected readonly EsvgFiles = EsvgFiles;
}
