import {Component, HostListener, ViewEncapsulation} from '@angular/core';
import {BaseCellEditorComponent} from '../base-cell-editor/base-cell-editor.component';
import {ITableVerticalDataRow} from '../../table-vertical-api-adapter';
import {FormControl} from '@angular/forms';
import {
  ISelectFormElement,
  ISelectFormOption
} from '../../../form-control/dynamic-form/form-element/form-data.interface';
import {
  SelectElementComponent
} from '../../../form-control/dynamic-form/form-element/select-element/select-element.component';

@Component({
  selector: 'kpi4me-enum-cell-editor',
  templateUrl: './enum-cell-editor.component.html',
  styleUrls: ['./enum-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [SelectElementComponent],
})
export class EnumCellEditorComponent extends BaseCellEditorComponent {

  selectElementData: ISelectFormElement;

  @HostListener('keydown.enter', ['$event'])
  override onEnter() {

  }

  override set row(r: ITableVerticalDataRow) {
    this.selectElementData = {
      options: r.repository.map((o, i) => {
        return {
          name: o, value: i
        } as ISelectFormOption
      }),
      createNewEnabled: false,
      emptyOptionAdded: false,
    };

    this.initialValue = r.value;

    this._row = r;

    this.formControl = new FormControl(r.value);
  }

  override get row(): ITableVerticalDataRow {
    return this._row;
  }

  override getValueForApi = () => {
    console.log(this.formControl);
    return this.formControl.value;
  }
  compareWith = (e1: any, e2: any) => {
    return e1 != null && e2 != null && e1 === e2;
  };


  onSelectionChange() {
    this.confirmChange();
  }
}
