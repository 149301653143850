const LibControlGuids = {
  "TFixedStructureHierarchy": "02C53B49AE9044218D80879F5782D080",
  "TCustomerSettingsForm": "23B2068969EA41DDBD09406D7D30FA87",
  "TEMailSmtpConfiguration": "DBE5B7FD376F4EB481B3D688A2113B1F",
  "TUserListTable": "3ED33075070F443A849D1A5AD6A1CAE3",
  "TEMailTemplateReplaceForm": "37D90EB8318E4F1AB42F02B60F6BABFC",
  "TWebhookConfigTable": "AA369705037C43F8B72A2EA5AB73D163",
  "TUserGroupListTable": "70333BA9B89E4993B79A9B711B8149AC",
  "TEmailTemplateForm": "F1EACFAE560B49109CE943BE46CB7653",
  "TAdminSettingsForm": "DDBABC06E09F474C93E18B7FF88B425F"
};
export default LibControlGuids;