import {Component, effect, input, InputSignal, model, ModelSignal} from '@angular/core';
import {TableControlComponent} from '../../../components/control/table-control/table-control.component';
import {TableControlModule} from '../../../components/control/table-control/table-control.module';
import {
  CSV_EXPORT_ACTION,
  CSV_IMPORT_ACTION,
  DELETE_ACTION,
  DUPLICATE_ACTION,
  ITableAction,
  NEW_ENTRY_ACTION
} from "frontier";
import {
  JSON_IMPORT_ACTION
} from "frontier/browserkit/src/lib/components/control/table-control/default-form-actions.constant";

@Component({
  selector: 'lib-guid-table-control',
  templateUrl: '../../../components/control/table-control/table-control.component.html',
  styleUrl: '../../../components/control/table-control/table-control.component.scss',
  standalone: true,
  imports: [
    TableControlModule
  ],
})
export class GuidTableControlComponent extends TableControlComponent {
  override GUID: string;
  inputGUID: InputSignal<string> = input.required<string>();
  override actions: ModelSignal<ITableAction[]>  = model<ITableAction[]>([
    NEW_ENTRY_ACTION,
    DELETE_ACTION,
    CSV_EXPORT_ACTION,
    CSV_IMPORT_ACTION,
    DUPLICATE_ACTION,
  ]);

  constructor() {
    effect(() => {
      this.GUID = this.inputGUID();
    });
    super();
  }
}
