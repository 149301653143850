import {inject, Injectable, signal} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FeedbackService} from 'frontier';

export interface IDynamicFormError {
  message: string;
  element: string;
}

@Injectable({
  providedIn: 'root'
})
export class DynamicFormStore {
  private _feedbackService = inject(FeedbackService);
  error = signal<IDynamicFormError>(null);

  error$ = new Subject<IDynamicFormError>();

  constructor(
  ) {
    this.error$.pipe(
      takeUntilDestroyed()
    ).subscribe((error: IDynamicFormError) => {
      this._feedbackService.hide();
      this.error.set(error);
    })
  }

}
