import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-boolean-cell-editor',
  templateUrl: './boolean-cell-editor.component.html',
  styleUrls: ['./boolean-cell-editor.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatSlideToggle,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class BooleanCellEditorComponent extends CustomCellEditorComponent implements AfterViewInit {
  @ViewChild('checkbox') checkboxRef: MatSlideToggle;

  override ngAfterViewInit() {
    setTimeout(() => {
      this.checkboxRef.focus();
    });
  }

  override isPopup(): boolean {
    return false;
  }

  onEnter() {
    this.textFormControl.patchValue(!this.textFormControl.value);
    this.saveValue();
  }

  protected override setPastedValue(pasted: string) {
    const bool = JSON.parse(pasted);
    this.textFormControl.patchValue(bool);
    this.params.value.value = bool;
    this.params.api.stopEditing();
  }
}
