import {Component, effect, input} from '@angular/core';
import {DynamicFormControlComponent} from '../../dynamic-form-control/dynamic-form-control.component';
import {DynamicFormComponent} from '../../dynamic-form.component';
import {NgIf} from '@angular/common';
import {MatError} from '@angular/material/form-field';

@Component({
  selector: 'lib-form-wrapper-control',
  templateUrl: '../../../../form-control/dynamic-form/dynamic-form-control/dynamic-form-control.component.html',
  styleUrl: '../../../../form-control/dynamic-form/dynamic-form-control/dynamic-form-control.component.scss',
  standalone: true,
  imports: [NgIf, DynamicFormComponent, MatError],
})
export class FormWrapperControlComponent extends DynamicFormControlComponent {
  override GUID: string;
  inputGUID = input.required<string>();

  constructor() {
    effect(() => {
      this.GUID = this.inputGUID();
    });
    super();
  }
}
