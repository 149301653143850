import {AbstractControl, ValidationErrors,} from '@angular/forms';

export class DateValidator {
  static isoFormat(control: AbstractControl): ValidationErrors | null {
    const validShortDate = /^\d{4}-\d{1,2}-\d{1,2}$/;
    const validIsoDate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

    if (control.value == null) {
      return null;
    }

    if (typeof control.value !== 'string') {
      if (control.value.day && control.value.month && control.value.year) {
        return null;
      }
      return {noIsoFormat: true};
    }

    if (!control.value.match(validShortDate) && !control.value.match(validIsoDate)) {
      return {noIsoFormat: true};
    }

    console.log('return null');
    return null;
  }
}
