import {Component, effect, input, InputSignal, model, ModelSignal} from '@angular/core';
import {
  DynamicFormControlComponent
} from '../../../components/control/form-control/dynamic-form/dynamic-form-control/dynamic-form-control.component';
import {DynamicFormComponent} from '../../../components/control/form-control/dynamic-form/dynamic-form.component';
import {NgIf} from '@angular/common';
import {IAction} from "frontier";
import {
  JSON_EXPORT_ACTION,
  JSON_IMPORT_ACTION,
} from "frontier/browserkit/src/lib/components/control/table-control/default-form-actions.constant";
import {MatError} from '@angular/material/form-field';

@Component({
  selector: 'lib-guid-form-control',
  templateUrl: '../../../components/control/form-control/dynamic-form/dynamic-form-control/dynamic-form-control.component.html',
  styleUrl: '../../../components/control/form-control/dynamic-form/dynamic-form-control/dynamic-form-control.component.scss',
  standalone: true,
  imports: [NgIf, DynamicFormComponent, MatError],
})
export class GuidFormControlComponent extends DynamicFormControlComponent {
  override GUID: string;
  inputGUID: InputSignal<string> = input.required<string>();
  override actions: ModelSignal<IAction[]> = model<IAction[]>([
    JSON_EXPORT_ACTION,
    JSON_IMPORT_ACTION,
  ]);

  constructor() {
    effect(() => {
      this.GUID = this.inputGUID();
    });
    super();
  }
}
