import {inject, Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {FeedbackService} from 'frontier';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  _feedbackService = inject(FeedbackService);

  downloadJsonFromBlobResponse(res: HttpResponse<any>) {
    const fileContent: Blob = res?.body;
    let fileName: string = res.headers.get('content-disposition')?.split('filename=')[1]?.split(';')[0];
    if (fileName) {
      fileName = fileName.substring(1, fileName.length - 1);
    }
    if (fileContent == null) {
      this._feedbackService.setError('Es konnte keine JSON-Datei heruntergeladen werden.');
      return;
    }
    const url: string = URL.createObjectURL(fileContent);
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.download = fileName || 'export.json';
    a.click();
    URL.revokeObjectURL(url);
  }

  downloadObjectAsJson(exportObj: object, exportName: string){
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href",     dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }
}
