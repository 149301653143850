<div
  class="mat-h4 header flex-row justify-center"
  [ngClass]="sideNavState() == 'opened' ? 'label' : 'hoverable-icon'"
  style="display: flex; align-items: center"
>
  <span
    class="flex-row align-center"
    [style.width.px]="24">
    @if (icon()) {
      <mat-icon
        [ngClass]="sideNavState() == 'opened' ? 'label' : 'hoverable-icon'"
        class="flex-row centered"
        [svgIcon]="icon()"
      ></mat-icon>
    }
  </span>


  @if (sideNavState() === 'opened') {
    <span class="title">
      <span>{{ title() }}</span>
<!--      Buffer to counteract a bug of firefox that doesn't add the scrollbar's width to the container width-->
      <span style="width: 17px"></span>
    </span>
    <button
      *ngIf="sideNavState() === 'opened'"
      mat-icon-button
      class="icon-wrapper"
      style="cursor: pointer; margin-left: auto;"
      [style.width.px]="40"
      (click)="toggleOpenClosed()"
    >
      <mat-icon
        class="centered"
        [svgIcon]="getOpenCloseIcon()"
      ></mat-icon>
    </button>
  }
</div>
