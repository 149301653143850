<mat-checkbox
  color="primary"
  (ngModelChange)="modelChange.emit($event)"
  *ngIf="displayAs !== 'toggle'; else toggle"
  [formControl]="formControlElement">
  {{ label }}
</mat-checkbox>
<ng-template #toggle>
  <mat-slide-toggle (ngModelChange)="modelChange.emit($event)"
                    [formControl]="formControlElement">{{ label }}
  </mat-slide-toggle>
</ng-template>
