import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  // This is a backup variable. In the first line the Windows clipboard is used for Copy and Paste. This variable is
  // accessed where the Windows Variable would convert the copied value, when browsers/browser versions are used,
  // that do not support the Paste implementation from Windows clipboard, and when the application is not executed in a
  // windows environment.
  clipboardValue: any;

  constructor() {
  }
}
