import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {ColumnApi, GridApi, ICellEditorParams} from 'ag-grid-community';
import {createGridRow} from '../../../adapters/table/create-grid-row.function';
import {BaseCell} from '../../interfaces/cell.interface';
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';
import {TColType} from '../../cell.enum';
import {catchError, tap} from 'rxjs/operators';
import {ALLOWED_KEYS} from './allowed-keys.const';
import {of} from 'rxjs';
import {ControlService, EControlActions, TableControlService, TYPE_MAX_MINS} from 'frontier/nucleus';
import {FeedbackService} from "../../../../../services/feedback.service";
import {ITextFormElement, TTextInputType} from '../../../form-control/dynamic-form/form-element/form-data.interface';
import {FormControl} from '@angular/forms';
import {
  TextElementComponent
} from '../../../form-control/dynamic-form/form-element/text-element/text-element.component';
import {ControlStore} from 'frontier/nucleus/src/lib/apiv2/control.store';
import {NgIf} from '@angular/common';
import {TableControlComponent} from 'frontier/browserkit';

type TColTypeToTextInputType = Partial<Record<TColType, TTextInputType>>;


const
  apiColTypeToInputType: TColTypeToTextInputType = {
    [TColType.clValue]: 'text',
    [TColType.clNumber]: 'number',
    [TColType.clIban]: 'iban',
    [TColType.clTelephone]: 'tel',
    [TColType.clMail]: 'email',
  }

@Component({
  selector: 'app-custom-cell-editor',
  templateUrl: './custom-cell-editor.component.html',
  styleUrls: ['./custom-cell-editor.component.scss'],
  standalone: true,
  imports: [NgIf, TextElementComponent],
})
export class CustomCellEditorComponent
  implements OnInit, ICellEditorAngularComp, AfterViewInit, OnDestroy {
  protected _controlService = inject(ControlService);
  protected _controlStore = inject(ControlStore);
  protected _tableControlService = inject(TableControlService);
  protected _cdr = inject(ChangeDetectorRef);
  protected _elementRef = inject(ElementRef);
  protected _feedbackService = inject(FeedbackService);

  @ViewChild('input', {static: false}) public input: TextElementComponent | ViewContainerRef;

  params: ICellEditorParams;
  protected canceled = false;
  protected oldValue: any;

  columnApi: ColumnApi;
  private floatValue: number;

  textElementData: ITextFormElement;
  textFormControl: FormControl = new FormControl();

  @HostListener('keydown.escape')
  fn() {
    this.canceled = true;
  }

  isPopup(): boolean {
    return true;
  }

  // detect clicks outside
  @HostListener('document:click', ['$event'])
  protected clickOut(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!this._elementRef.nativeElement.contains(target)) {
      this.params.api.stopEditing();
    }
  }

  ngOnInit(): void {
    return;
  }

  agInit(params: ICellEditorParams): void {
    this.params = params;
    this.columnApi = params.context.colApi;
    console.log(params);
    this.oldValue = {...this.params.value};
    const apiColType: TColType = (this.params.colDef as ICustomColDef).apiCol.type;

    // map the api column type to the respective input type of the kpi4me-text-element
    this.textElementData = {
      inputType: apiColTypeToInputType[apiColType]
    }

    this.textFormControl = new FormControl(params.value.value);

    if (params.charPress) {
      this.handlePaste(params);
    }
  }

  ngAfterViewInit() {
    // focus on the input
    setTimeout(() => {
      const nativeElement = (<TextElementComponent>this.input).inputRef ? (<TextElementComponent>this.input).inputRef.nativeElement : (<any>this.input).nativeElement;
      if (!this.params.charPress) {
        nativeElement.select();
      }
      nativeElement.focus();
    });
  }

  ngOnDestroy() {
    (<GridApi>this.params.context.gridApi).setFocusedCell(this.params.rowIndex, this.params.column);

    if (this.canceled) {
      this.textFormControl.patchValue(this.oldValue.value);
      return;
    } else if (this.oldValue.value === this.textFormControl.value) {
      return;
    }

    if (this.textFormControl.valid === false) {
      this.params.colDef.cellStyle = {
        'border-color': '#D55F13', // var(--color-orange-600)
        'border-width': '2px'
      }
      this.params.value.value = this.textFormControl.value;
      this._feedbackService.setError((<TextElementComponent>this.input).getErrorMessage().join(', '));
      this._cdr.detectChanges();
      return;
    }

    // check if the value is too big
    if (
      this.isMaximumLengthReached(
        (this.params.colDef as ICustomColDef).apiCol.attributetype
      )
    ) {
      this._feedbackService.setError('Der eingegeben Wert ist zu groß');
      this.textFormControl.patchValue(this.oldValue.value);
      return;
    }

    this.saveValue();
    this.params.colDef.cellStyle = {
      'border-color': '',
      'border-width': ''
    }
  }

  getValue(): any {
    return this.params.value;
  }

  focusOut() {
  }

  // Gets called once when editing is finished (eg if Enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd() {
    const oldValue = this.oldValue.value ? this.oldValue.value : this.oldValue;
    return this.canceled || oldValue == this.textFormControl.value;
  }

  /**
   * Prevent keys that make no sense for the column / cell type.
   * @param evt
   */
  onKeyDown(evt: KeyboardEvent) {
    console.log(evt.key);
    const apiColType: TColType = (this.params.colDef as ICustomColDef).apiCol
      .type;
    // for numbers check if the maximum number length is reached
    const key = evt.key;
    /**
     * Allow the key down event if the key is a special key for navigating, etc...
     */
    if (ALLOWED_KEYS[key] === true) {
      return;
    }

    let regex: RegExp;
    let hasToCheckRegex = true;
    switch (apiColType) {
      /**
       * For integers: Disable all but integer numbers.
       */
      case TColType.clValue:
        regex = new RegExp(/[^0-9]/g);
        break;
      /**
       * If there is no case for the column type: leave the function and do nothing.
       */
      default:
        hasToCheckRegex = false;
        break;
    }

    if (hasToCheckRegex == false) {
      return;
    }
    /**
     * We found a disallowed key as input. Prevent the default event.
     */
    if (key.match(regex)) {
      evt.preventDefault();
      return;
    }
  }

  protected changeLine(
    obj: any,
    rowidx: number,
    attribute: string,
    attributeindex: number,
    val: number | string | boolean
  ) {
    return this._tableControlService.tableControlChangeLine(
      {
        InstanceId: (this.params.context as TableControlComponent).apiInstance().instanceid,
        RowObject: obj,
        RowIdx: rowidx,
        Attribute: attribute,
        AttributeIndex: attributeindex,
        Value: val
      }
    ).pipe(
      tap((res) => {
        this._controlStore.controlDataChanged$.emit({
          GUID: (this.params.context as any).GUID,
          changeType: EControlActions.changeLine
        });
        this.params.node.setData(createGridRow(res));
        (this.params.context as any).onCellValueChanged(this.params);
      }),
      catchError((err, caught) => {
        // When there is an error from the api, then restore the old value.
        // this.textFormControl.patchValue(this.oldValue.value);
        // this.params.node.setDataValue(this.params.column, this.oldValue);
        this.handleError(err)
        return of(err);
      })
    );
  }

  protected handlePaste(params: ICellEditorParams) {
    if (params.charPress === 'delete') {
      this.textFormControl.patchValue(null);
      this.params.stopEditing();
      return;
    }
    if (params.charPress.includes('#pasted')) {
      const pasted: string = JSON.parse(params.charPress.slice(7));
      this.setPastedValue(pasted);
    } else {
      // append the value to the current cell value
      if (params.value.value) {
        this.textFormControl.patchValue(params.charPress);
      } else {
        this.textFormControl.patchValue(params.charPress);
      }
    }
  }

  protected setPastedValue(pasted: string) {
    console.log(pasted);
    this.textFormControl.patchValue(pasted);
    this.params.value.value = pasted;
    this.params.api.stopEditing();
  }

  onModelChange(evt: any) {
    this.floatValue = Number(evt.replace(',', '.'));
  }

  protected saveValue() {
    // prevent change line if the value did not change
    const cell: BaseCell = this.params.value;
    const colDef: ICustomColDef = this.params.colDef;
    let val = this.textFormControl.value;
    if (val != null) {
      switch (colDef.colType) {
        case TColType.clNumber | TColType.clValue:
          val = Number(val);
          break;
        case TColType.clText:
          if (typeof val === 'object') {
            val = val.name;
          }
      }
    }

    const colApis = this.columnApi.getAllColumns().map((c: any) => {
      return c.colDef.apiCol;
    });
    this.changeLine(
      this.params.data.apiRow.obj,
      this.params.data.apiRow.rowidx,
      colDef.attribute,
      colDef.attributeindex,
      val,
    ).subscribe(() => {
      console.log('saved Value');
    });
  }

  protected isMaximumLengthReached(apiColType: string): boolean {
    // integers
    if (apiColType === 'Integer') {
      const n = this.floatValue;
      if (n >= TYPE_MAX_MINS.integer || n <= -TYPE_MAX_MINS.integer) {
        return true;
      }
    }
    // float
    else if (apiColType === 'Double') {
      const n = this.floatValue;
      if (n >= TYPE_MAX_MINS.integer || n <= -TYPE_MAX_MINS.integer) {
        return true;
      }
    }

    return false;
  }

  private handleError(err: any) {
    this.canceled = true;
    this.params.node.setDataValue(this.params.column, {
      ...this.params.value,
      value: this.params.value.value,
      error: err.error.error.userMsg
    })
  }
}
