import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {ControlRendererComponent} from '../control-renderer/control-renderer.component';
import {MAT_DIALOG_DATA, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {IControlFormData} from './control-form-data.interface';
import {Subscription} from 'rxjs';
import {IControlDialogComponent} from './control-form-component.interface';

@Component({
  selector: 'app-control-form-dialog',
  templateUrl: './control-form-dialog.component.html',
  styleUrls: ['./control-form-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogContent, ControlRendererComponent],
})
export class ControlFormDialogComponent implements OnInit {
  @ViewChild(ControlRendererComponent, {static: true})
  controlRenderer: ControlRendererComponent;

  private subs = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: IControlFormData, // { events: IIntervallCellValue[], calendarRow: any, rowNode: any },
    protected dialogRef: MatDialogRef<ControlFormDialogComponent>
  ) {
  }

  ngOnInit(): void {
    console.log(this.data);
    this.controlRenderer.render(
      this.data.component,
      this.data.guid,
      this.data.params,
      this.data.filter
    );

    (
      this.controlRenderer.formRef.instance as IControlDialogComponent
    ).dialogRef = this.dialogRef;

    this.subs.add(
      this.controlRenderer.confirmed.subscribe(() => {
        this.onConfirm();
      })
    );
    this.subs.add(
      this.controlRenderer.canceled.subscribe(() => {
        this.onCancel();
      })
    );
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  private onCancel() {
    this.dialogRef.close();
  }
}
