import {Component, HostListener, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {FormControl} from "@angular/forms";
import {ConfirmIconButtonComponent} from "frontier/browserkit/src/lib/components/confirm-icon-button/confirm-icon-button.component";
import {
  TextElementComponent
} from "../../../components/control/form-control/dynamic-form/form-element/text-element/text-element.component";
import {DialogHeaderComponent} from "frontier/browserkit/src/lib/components/dialog-header/dialog-header.component";

@Component({
  selector: 'kpi4me-reasoning-dialog-component',
  templateUrl: './reasoning-dialog.component.html',
  styleUrls: ['./reasoning-dialog.component.scss'],
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatDialogContent,
    TextElementComponent,
    MatDialogActions,
    ConfirmIconButtonComponent,
  ],
})
export class ReasoningDialogComponent {
  reason = new FormControl('');

  @HostListener('window:keydown.enter', ['$event'])
  onEnter() {
    this.onConfirm();
  }

  constructor(
    public dialogRef: MatDialogRef<ReasoningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string }
  ) {
  }

  ngOnInit(): void {
    return;
  }

  onConfirm(): void {
    this.dialogRef.close(this.reason.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
