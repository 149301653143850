import {BaseRow} from './interfaces/row.interface';
import {ICustomGridRow} from './interfaces/custom-grid-row.interface';

/**
 * Helper class that represents one viewport on the ag grid.
 */
export class ViewPort {
  endCol: number;
  startCol: number;
  endRow: number;
  startRow: number;
  startColKey: string;
  endColKey: string;

  constructor(
    startRow: number,
    endRow: number,
    startCol: number,
    endCol: number
  ) {
    this.startRow = startRow;
    this.endRow = endRow;
    this.startCol = startCol;
    this.endCol = endCol;
  }

  /**
   * Checks another viewport intersects this one. It either checks for column or row intersections.
   * @param other
   * @param changeType
   */
  intersects(other: ViewPort, changeType: 'row' | 'col'): boolean {
    if (changeType === 'row') {
      return this.startRow <= other.endRow && this.endRow >= other.startRow;
    } else {
      return this.startCol <= other.endCol && this.endCol >= other.startCol;
    }
  }

  /**
   * Checks if  another viewport lies inside this one.
   * @param other
   */
  isInside(other: ViewPort): boolean {
    return (
      this.startRow <= other.startRow &&
      this.endRow >= other.endRow &&
      this.startCol <= other.startCol &&
      this.endCol >= other.endCol
    );
  }

  /**
   * Checks if another viewport is the same.
   * @param currentViewPort
   */
  equals(currentViewPort: ViewPort): boolean {
    return (
      currentViewPort.startRow == this.startRow &&
      currentViewPort.endRow == this.endRow &&
      currentViewPort.startCol == this.startCol &&
      currentViewPort.endCol == this.endCol
    );
  }
}

/**
 * A Table that stores all viewports it fetched from the server. It is a container that stores all fetched
 * viewports in the client side.
 */
export class ViewPortTable {
  // Rows that are stored as a lookup table format.
  // They row-index is the key to access the columns for the row
  rows: { [index: number]: ICustomGridRow } = {};

  /**
   * All viewports that were fetched already
   * @private
   */
  private viewPorts: ViewPort[] = [];

  constructor() {
    this.viewPorts = [];
    this.rows = {};
  }

  /**
   * Add the row block to the client side table model. For each server row, update the client side columns
   * for the respective row.
   * @param newServerRowBlock: Rowblock from the server. Client side-Null Cells are updated with real values.
   * @param currentViewPort
   */
  addRowBlock(
    newServerRowBlock: ICustomGridRow[],
    currentViewPort: ViewPort,
    staticColumns: number[]
  ) {
    console.log('adding a row block for the viewport', currentViewPort);
    // loop through the old of the current viewport and update the row client-side.
    // For each client row update the cells, with values from the server.
    newServerRowBlock.forEach((oldRow) => {
      // Fix column attributes
      const agGridRowObject: ICustomGridRow = {
        apiRow: oldRow.apiRow,
      };
      if (staticColumns) {
        staticColumns.forEach((c) => {
          agGridRowObject[c] = oldRow[c];
        });
      }
      // Add only the valid cells for this current viewport
      for (
        let i = currentViewPort.startCol;
        i <= currentViewPort.endCol + 1;
        i++
      ) {
        if (oldRow[i] != null) {
          agGridRowObject[i] = {...oldRow[i]};
        }
      }

      // Cached row for the current api row
      let clientRow = {...this.rows[(oldRow.apiRow as BaseRow).rowidx]};
      // Either add the new column cells to the row or for new rows add the valid column cells
      if (clientRow) {
        clientRow = {...clientRow, ...agGridRowObject};
      } else {
        clientRow = {...agGridRowObject};
      }
      // update the local cache for this row
      this.rows[(oldRow.apiRow as BaseRow).rowidx] = clientRow;
    });
  }

  /**
   * FOr a viewport, return all rows that lie in this viewport.
   * @param currentViewPort
   */
  getRowBlock(currentViewPort: ViewPort): ICustomGridRow[] {
    const block: ICustomGridRow[] = [];
    for (let i = currentViewPort.startRow; i < currentViewPort.endRow; i++) {
      if (this.rows[i]) {
        block.push(this.rows[i]);
      }
    }
    return block;
  }

  /**
   * Helper method that checks if a viewport has to fetch new data. Returns a viewport if there were some new
   * columns or rows that are not included in the current ViewPortTable.
   * @param currentViewPort
   * @param changeType
   */
  viewPortChanged(
    currentViewPort: ViewPort,
    changeType: 'row' | 'col'
  ): ViewPort | null {
    let returnType: 'new' | 'changed' | null = 'new';
    let returnValue = null;
    const viewPortColRange: ViewPort = new ViewPort(
      currentViewPort.startRow,
      currentViewPort.endRow,
      currentViewPort.startCol,
      currentViewPort.endCol
    );
    /**
     * For each viewport that was fetched already, we check if the new one lies inside the other. For either scroll-event
     * (row or column) we only check this dimension. If we find an intersection, then the intersected viewports
     * dimension will be changed to the input viewports.
     */
    this.viewPorts.forEach((viewPort) => {
      if (viewPort.isInside(viewPortColRange)) {
        returnType = null;
        return;
      }

      // Check both for column intersections or row intersections.
      if (viewPort.intersects(viewPortColRange, changeType)) {
        // Only check the column dimension.
        if (changeType === 'col') {
          if (
            viewPort.startCol == currentViewPort.startCol &&
            viewPort.endCol == currentViewPort.endCol
          ) {
            returnType = null;
            returnValue = null;
            return;
          } else if (viewPort.endCol < currentViewPort.endCol) {
            viewPort.endCol = currentViewPort.endCol;
          }
        }
        // Check for the row dimension.
        else {
          if (
            viewPort.startRow == currentViewPort.startRow &&
            viewPort.endRow == currentViewPort.endRow
          ) {
            returnType = null;
            returnValue = null;
            return;
          } else if (viewPort.endRow < currentViewPort.endRow) {
            viewPort.endRow = currentViewPort.endRow;
          }
        }
        returnType = 'changed';
        returnValue = viewPort;
        return;
      }
    });
    // If we did not find any intersection, we have to insert the checked
    // viewport to the viewports of the ViewPortTable.
    if (returnType === 'new') {
      returnValue = viewPortColRange;
      console.log('Adding new viewport to seen viewports');
      this.viewPorts.push(viewPortColRange);
    }
    return returnValue;
  }

  resetCache() {
    this.rows = {};
    this.viewPorts = [];
  }
}

export interface IViewPort {
  startRow: number;
  endRow: number;
  startCol: number;
  endCol: number;
}
