import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {DatePipe} from '@angular/common';
import {CellErrorIconComponent} from '../../cell-error-icon/cell-error-icon.component';
import {
  RendererContainerComponent
} from 'frontier/browserkit/src/lib/components/control/table-control/renderers/renderer-container/renderer-container.component';

@Component({
  selector: 'app-date-cell-renderer',
  templateUrl: './date-cell-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
  standalone: true,
  imports: [CellErrorIconComponent, DatePipe, RendererContainerComponent],
})
export class DateCellRendererComponent extends CustomCellComponent {

}
