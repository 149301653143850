<div
  cdkDrag
  cdkDragHandle
  cdkDragRootElement=".cdk-overlay-pane"
  mat-dialog-title
>
  <div>
    <span class="title-string">{{ title }}</span>
  </div>
  <mat-icon
    style="flex-shrink: 0"
    (click)="onCloseClick($event)"
    svgIcon="{{EsvgFiles.close}}"
  >
  </mat-icon>
</div>
