<div
  class="flex-column"
  [class]="state() + ' wrapper'"
>
  <div
    #menuContainer
    class="menu-container"
    style="display: flex; flex-direction: column"
  >
    @for (category of sidePanelCategories(); track category.title) {
      <ul
        class="side-nav-menu"
        (mouseenter)="state() == 'closed' ? onMenuTriggerHovered($index) : null"
        (mouseleave)="onMenuMouseLeaved($index, $event)"
      >
        <div class="flex-row justify-center">
          <lib-section-title
            style="width: 100%;"
            [class]="state()"
            *ngIf="category.title"
            [title]="category.title"
            [icon]="category.icon"
            [color]="category.color"
            [sideNavState]="state()"
            [(categoryOpened)]="category.opened"
          />
          <span class="menu-trigger" [matMenuTriggerFor]="i"></span>

          <mat-menu
            #i="matMenu"
            yPosition="above"
            xPosition="after"
            [hasBackdrop]="false"
          >
            <div (mouseleave)="onMenuMouseLeavedFromMenu($index, $event)">
              <ng-template matMenuContent>
                <span class="mat-menu-title mat-h3 hover-menu-span hover-menu-span-title">
                  {{ category.title }}
                </span>

                <mat-divider/>

                <button
                  *ngFor="let item of category.items"
                  mat-menu-item
                  (click)="itemClicked.emit(item)"
                  (keydown.enter)="$event.preventDefault()"
                >
                  <ng-container>
                    <span class="hover-menu-span">{{ item?.title }}</span>
                  </ng-container>
                </button>
              </ng-template>
            </div>
          </mat-menu>
        </div>

        @if (!category.opened && state() === 'opened') {
          @for (item of category.items; track item.tabId) {
            <li *authorizeVisibility="getAuthorizeVisibilityGuids(item)"
                class="side-nav-item"
                (click)="itemClicked.emit(item)">
              <a
                class="side-nav-link flex-row align-center">
                {{ item.title }}
              </a>
            </li>
          }
        }
      </ul>

    }
  </div>
  <div style="flex: 0; min-height: 41px">
    <mat-divider style="width: 100%; color: var(--divider)"></mat-divider>

    <div
      class="mat-h4 header"
      style="display: flex; justify-content: center; gap: 0.5rem; padding: 0.5rem 1rem; color: var(--open-close-icon); cursor: pointer;"
      (click)="onToggleOpened()"
    >
      <span
        class="icon-wrapper align-center"
        [style.width.px]="24"
      >
        <mat-icon
          class="centered"
          style="
          display: flex;
          margin-left: auto;
          color: var(--open-close-icon);
          background-color: transparent;
          "
          [svgIcon]="state() === 'opened' ? EsvgFiles.arrow_left : EsvgFiles.arrow_right"
        ></mat-icon>
      </span>

      @if (state() === 'opened') {
        <span class="title flex-row align-center" *ngIf="state() == 'opened'">
          <span>Seitenleiste minimieren</span>
        </span>
      }
    </div>
  </div>
</div>
