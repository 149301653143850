import {Component, EventEmitter, Inject, Injectable, OnDestroy, OnInit, signal} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
import {Observable, race, Subject, Subscription, takeWhile, timer} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {MatIcon} from '@angular/material/icon';
import {MatProgressBar} from '@angular/material/progress-bar';
import {NgIf} from '@angular/common';
import {MatButton, MatIconButton} from '@angular/material/button';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

interface IError {
  text: string;
  duration?: number;
}

@Injectable({
  providedIn: 'root',
})
export class FeedbackService implements OnDestroy {
  private error$: Subject<IError> = new Subject<IError>();
  private hide$: EventEmitter<void> = new EventEmitter<void>();

  delayedError = race(
    this.error$.pipe(debounceTime(300)),
    this.hide$
  )
  subscription = new Subscription();

  constructor(private snackBar: MatSnackBar) {
    this.subscription.add(
      this.delayedError.pipe().subscribe((err: IError | void) => {
        if (!err) {
          this.snackBar?.dismiss()
        } else {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: err,
            verticalPosition: 'top', announcementMessage: 'ok'
          });
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setNotification(notification: string, duration = 3000) {
    this.snackBar.open(notification, null, {duration});
  }

  setError(error: string, duration?: number) {
    this.error$.next({text: error, duration});
  }

  hide() {
    this.hide$.emit()
  }
}

@Component({
  selector: 'custom-snackbar',
  template: `
    <div class="wrapper">
             <span class="text-wrapper">
               {{ data.text }}
             </span>
      <button color="accent" mat-stroked-button (click)="manualDismiss.emit()">ok</button>
      <!--        <mat-icon class="centered" style="display: flex" svgIcon="{{EsvgFiles.correct}}"></mat-icon>-->
    </div>
    <div style="display: flex; justify-content: center; flex-direction: column; align-items: center">
      <mat-progress-bar mode="determinate" [value]="val()" *ngIf="data.duration"></mat-progress-bar>
      <button mat-icon-button class="icon-button-centered" *ngIf="data.duration">
        <mat-icon class="blue-icon" svgIcon="pause" (click)="onPause()"></mat-icon>
      </button>
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0.5rem;
      }

      .text-wrapper {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: unset;
      }

      button {
        max-height: 2rem;
      }
    `,
  ],
  standalone: true,
  imports: [
    MatButton,
    NgIf,
    MatProgressBar,
    MatIconButton,
    MatIcon,
  ],
})
export class CustomSnackbarComponent implements OnInit {
  timer: Observable<number>;
  timerSub: Subscription;
  manualDismiss = new EventEmitter<void>();

  val = signal<number>(0);
  private maxDuration: number;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: IError, public snackBar: MatSnackBar) {
    this.manualDismiss
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.snackBar?.dismiss()
      })
  }

  ngOnInit() {
    if (this.data.duration == null) return;

    this.maxDuration = this.data.duration;
    this.timer = timer(0, 10);

    this.timerSub = this.timer.pipe(
      takeUntil(this.manualDismiss),
      takeWhile(val => val * 10 <= this.data.duration)
    ).subscribe({
      next: v => {
        this.val.set((v * 10 / this.maxDuration) * 100);
      },
      complete: () => {
        this.val.set(100);
        this.snackBar.dismiss();
      }
    })
  }

  onPause() {
    this.timerSub.unsubscribe();
    this.data.duration = null;
  }
}
