<!--<input-->
<!--  #inputRef-->
<!--  #input="ngbDatepicker"-->
<!--  (dateSelect)="onDateChange($event)"-->
<!--  [startDate]="startDate"-->
<!--  [value]="dateString"-->
<!--  ngbDatepicker-->
<!--  type="text"-->
<!--  (focusout)="onInputFocusOut()"-->
<!--  placeholder="TT.MM.JJJJ"-->
<!--/>-->
<!--<mat-icon-->
<!--  (click)="input.toggle()"-->
<!--  svgIcon="{{EsvgFiles.calendar}}"-->
<!--  class="blue-icon"-->
<!--&gt;</mat-icon>-->
<kpi4me-date-element class="form-field-no-padding"
                     [formControlElement]="textFormControl"
                     (modelChange)="this.params.api.stopEditing()"
>

</kpi4me-date-element>
