import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DOCUMENT} from '@angular/common';

export interface ITheme {
  baseColor?: string;
  darkerColor?: string;
  highlightColor?: string;
  highlightLess?: string;
  warning?: string;
  rowFocus?: string;
  toolBarFontColor?: string;
  toolBarIconColor?: string;
}

export type themeClass = 'dark-theme' | 'light-theme' | 'custom-theme';

const themeMap: any = {
  'light-theme': 1,
  'dark-theme': 2,
  'custom-theme': 3,
  '1': 'light-theme',
  '2': 'dark-theme',
  '3': 'custom-theme',
};

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  highlightColor: BehaviorSubject<string> = new BehaviorSubject<string>(
    '#F2975B' // var(--color-orange-400)
  );
  isThemeSet = false;
  themeClass: themeClass = 'light-theme';
  theme$ = new BehaviorSubject(this.themeClass);

  constructor(@Inject(DOCUMENT) private document: Document) {
    const cachedTheme = localStorage.getItem('themeClass');
    if (cachedTheme !== '1' && cachedTheme !== '2' && cachedTheme !== '3') {
      console.warn(
        'Unexpected theme class in localStorage. Setting the default theme'
      );
      this.setTheme(1);
    }
    if (cachedTheme) {
      this.setTheme(Number(cachedTheme));
    }
  }

  setTheme(guitheme: number) {
    console.log('Setting theme', guitheme);
    localStorage.setItem('themeClass', String(guitheme));
    this.document.body.classList.remove('dark-theme');
    this.document.body.classList.remove('light-theme');
    this.document.body.classList.remove('custom-theme');
    const themeClassName = themeMap[String(guitheme)];
    this.document.body.classList.add(themeClassName);
    this.themeClass = themeClassName;
    this.theme$.next(themeClassName);
  }

  toggleTheme() {
    if (this.themeClass === 'dark-theme') {
      this.setTheme(themeMap['light-theme']);
    } else {
      this.setTheme(themeMap['dark-theme']);
    }
  }

  applyTheme(evt: string) {
    this.setTheme(themeMap[evt]);
  }
}
