import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {ICellRendererParams} from 'ag-grid-community';
import {EsvgFiles} from 'frontier/nucleus';
import {CellErrorIconComponent} from '../../cell-error-icon/cell-error-icon.component';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {
  RendererContainerComponent
} from 'frontier/browserkit/src/lib/components/control/table-control/renderers/renderer-container/renderer-container.component';

@Component({
  selector: 'app-reference-cell-selection-renderer',
  templateUrl: './reference-cell-selection-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
  standalone: true,
  imports: [
    MatTooltip,
    MatIcon,
    CellErrorIconComponent,
    RendererContainerComponent,
  ],
})
export class ReferenceCellSelectionRendererComponent extends CustomCellComponent {
  protected readonly EsvgFiles = EsvgFiles;

  names: string[] = [];

  override agInit(params: ICellRendererParams) {
    this.setNames(params);
    super.agInit(params);
  }

  private setNames(params: ICellRendererParams) {
    if (params.value) {
      this.names = params.value.value.map((v: { name: any }) => v.name);
    }
  }
}
