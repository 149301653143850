import {NgModule} from '@angular/core';
import {TableControlComponent} from './table-control.component';
import {NgxResizeObserverModule} from 'ngx-resize-observer';
import {NgFor, NgIf} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {MatDivider} from '@angular/material/divider';
import {MatCheckbox} from '@angular/material/checkbox';
import {AgGridModule} from 'ag-grid-angular';
import {ValueOrFunctionPipe} from '../../../pipes/value-or-function.pipe';

@NgModule({
  declarations: [TableControlComponent],
  imports: [
    NgxResizeObserverModule,
    NgIf,
    MatButton,
    MatTooltip,
    MatIcon,
    MatDivider,
    NgFor,
    MatCheckbox,
    AgGridModule,
    ValueOrFunctionPipe,
  ],
  exports: [TableControlComponent, NgxResizeObserverModule, NgIf, MatButton, MatTooltip, MatIcon, MatDivider, NgFor, MatCheckbox, AgGridModule, ValueOrFunctionPipe]
})
export class TableControlModule {

}
