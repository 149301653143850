@if (header) {
  <h2>{{ header }}</h2>
}


@if (!data() || !rows()) {
  <!-- Display loading spinner -->
  <div class="centered" style="height: 50px">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
} @else if (rows()?.length === 0) {
  <div class="centered">
    <p>Keine Daten</p>
  </div>
} @else if (rows().length > 0) {
  <table>
    <tbody>
    @for(r of rows(); track r.apiRow.signature) {
      <tr>
        <td>{{ r.fieldName }}</td>
        <td>
          <kpi4me-cell-editor
            (cellChanged)="onCellChange($event)"
            (updateTable)="onUpdateTable()"
            (selectionDataChange)="onSelectionDataChange($event)"
            [params]="r"
          ></kpi4me-cell-editor>
        </td>
        @if (showImports()) {
          <td
            class="imported-cell"

          >
            {{ r.imported }}
          </td>
        }
      </tr>
    }

    </tbody>
  </table>
}


