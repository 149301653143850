import {computed, effect, inject, Injectable, InjectionToken, signal} from '@angular/core';
import {patchState, signalState} from '@ngrx/signals';
import {ITab} from './tab.interface';
import {OnTabActivated} from 'frontier/browserkit';

export
type TabState = {
  tabs: ITab[];
  selectedTabIndex: number;
}

const LOCAL_STORAGE_KEY = 'tabs';
const localStorageTabs = localStorage.getItem(LOCAL_STORAGE_KEY);

export const INITIAL_TABS_TOKEN = new InjectionToken<TabState>('initialTabs');
export const COMPONENT_REGISTRY_TOKEN = new InjectionToken<{ [key: string]: any }>('componentRegistry');

@Injectable({
  providedIn: 'root'
})
export class TabsService {
  componentRegistry = inject(COMPONENT_REGISTRY_TOKEN, {optional: false});
  initialTabsToken = inject(INITIAL_TABS_TOKEN, {optional: false});

  initialState: TabState = localStorageTabs ? JSON.parse(localStorageTabs) : this.initialTabsToken;

  readonly state = signalState<TabState>(this.initialState);
  readonly tabs = this.state.tabs;
  readonly selectedTabIndex = this.state.selectedTabIndex;
  readonly selectedTab = computed(() => this.tabs()[this.selectedTabIndex()]);
  readonly animationDuration = signal(300);

  constructor() {
    console.log('Initial tabs:', this.initialTabsToken);
    effect(() => {
      const state = this.state();
      const copy = {
        ...state, tabs: state.tabs.map(tab => {
          const tabCopy = {...tab};
          delete tabCopy.componentInstance;
          return tabCopy;
        })
      };
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(copy));
    });

    effect(() => {
      const selectedTab = this.selectedTab();

      if (selectedTab) {
        // Activate the tab
        if ((<OnTabActivated>selectedTab.componentInstance)?.onTabActivated) {
          setTimeout(() => {
            (<OnTabActivated>selectedTab.componentInstance).onTabActivated();
          }, this.animationDuration() + 50);
        }
      }
    });
  }

  openTab(label: string, component: string, tabId: string) {
    patchState(this.state, state => {
      return {
        tabs: [...state.tabs, {label, componentName: component, tabId}],
        selectedTabIndex: state.tabs.length,
      }
    })
  }

  selectTabById(label: string) {
    patchState(this.state, state => {
      return {
        selectedTabIndex: state.tabs.findIndex(tab => tab.label === label),
      }
    })
  }

  selectTabByIndex(index: number) {
    patchState(this.state, state => {
      return {
        selectedTabIndex: index,
      }
    })
  }

  closeTabByIndex(index: number) {
    patchState(this.state, state => {
      return {
        tabs: state.tabs.filter((_, i) => i !== index),
        // If the selected tab is closed, select the previous tab
        selectedTabIndex: index === state.selectedTabIndex ? Math.max(0, state.selectedTabIndex - 1) : state.selectedTabIndex,
      }
    })
  }

  closeAllTabs() {
    patchState(this.state, state => {
      return {
        tabs: [],
        selectedTabIndex: 0,
      }
    })
  }

  closeAllTabsExcept(tab: ITab) {
    patchState(this.state, state => {
      return {
        tabs: [tab],
        selectedTabIndex: 0,
      }
    })
  }
}
