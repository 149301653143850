import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginStore} from './login.store';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {combineLatestWith, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {SetPasswordDialogComponent} from '../../dialogs/basics/set-password-dialog/set-password-dialog.component';
import {IUser, StoreService} from 'frontier/nucleus';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService as AuthApiService, UserService} from 'frontier/nucleus/src/lib/apiv2/generated';
import {AuthenticationService} from '../../authentication/authentication.service';
import {MatButton} from '@angular/material/button';
import {NgIf} from '@angular/common';
import {MatInput} from '@angular/material/input';
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field';
import {MatCardActions, MatCardContent} from '@angular/material/card';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    MatCardContent,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    NgIf,
    MatError,
    MatCardActions,
    MatButton,
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  private _userService = inject(UserService);
  private _authApiService = inject(AuthApiService);
  private _authService = inject(AuthenticationService)
  nextRoute: string;
  CurrentUser: IUser;
  form: FormGroup;
  submitted: boolean;
  private subs = new Subscription();

  // route is the current route, router changes the route
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthenticationService,
    public loginService: LoginStore,
    private fb: FormBuilder,
    private store: StoreService,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar
  ) {
    // Set CurrentUser to the saved one in the local storage
    this.CurrentUser = {
      loginname: '',
      password: '',
    };

    this.form = this.fb.group({
      username: this.fb.control(
        this.CurrentUser.loginname ? this.CurrentUser.loginname : '',
        [Validators.required]
      ),
      password: this.fb.control(
        this.CurrentUser.password ? this.CurrentUser.loginname : '',
        [Validators.required]
      ),
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  get getForm() {
    return this.form.controls;
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.subs.add(
      this.route.queryParams.pipe(
        combineLatestWith(this.route.data)
      ).subscribe(([params, data]) => {
        // route.data is defined in the route declaration statements in the routes.ts configuration file of the consuming app.
        console.log('route data event: ', params, data);
        this.nextRoute = params.nextRoute || data.nextRoute;
        this._authApiService.authGetOAuthEnabled().subscribe((res) => {
          if (res) {
            try {
              const lastLogin = localStorage.getItem('lastlogin');
              if (lastLogin) {
                const diff = Date.now() - parseInt(lastLogin)
                // smaller than 10 seconds --> don't redirect automatically
                if (diff < 10000) {
                  this.authService.store.singleSignOn = false
                  return;
                }
              }
            } finally {
              this.store.resetLastLogin();
            }
            this.singleSignOnLogin();
          } else {
            this.authService.store.singleSignOn = false;
          }
        });
      })
    );
  }

  /*
  Deprecated: Login without Keycloak / Single sign on
   */
  onEnter() {
    this.login();
  }

  login() {
    this.submitted = true;
    if (this.form.get('password').value === undefined) {
      this.form.get('password').setValue('');
    }

    // stop here if form is invalid
    if (this.form.invalid) {
      console.log('Form is invalid');
      return;
    }
    localStorage.removeItem('sso');

    this.store.setActiveUser(null);

    // login via api. If the user logs in the first time, so the response is true, then the set password dialog should pop up.
    this.loginService
      .login(this.form.get('username').value, this.form.get('password').value)
      .subscribe((resp: any) => {
        this.authService.store.manualNotAuthenticated = false;
        if (resp.body === true) {
          // open the dialog so the user can change the password directly.
          this.dialog.open(SetPasswordDialogComponent, {
            panelClass: 'customDialogContainer',
            data: {
              username: this.CurrentUser.loginname,
              passwort: '',
              valuetype: '',
              type: 'new',
              title: 'Erste Anmeldung mit diesem User. Bitte Passwort setzen',
            },
            maxHeight: '99vh',
            width: '50vw',
            minWidth: '300px',
            maxWidth: 400,
          });
        } else {
          // Save headers from get observable
          // this.headers = keys.map(key => `${key}: ${resp.headers.get(key)}`);
          const userDataSub = this._userService.userGetGetLoginData().subscribe(
            (user: IUser) => {
              if (user) {
                this.matSnackBar.dismiss();
                this.store.setActiveUser(user);
                // if (user.theme !== undefined) {
                //     this.themeService.setTheme(user.theme);
                // }
                this.router.navigate([this.nextRoute]);
              }
            }
          );
        }
      });
  }

  singleSignOnLogin() {
    console.log('next url: ' + this.nextRoute);
    this.authService.store.singleSignOn = true;
    localStorage.removeItem('tenant');
    this.authService.singleSignOn(this.nextRoute);
  }
}
