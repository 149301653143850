<app-dialog-header
  [title]="data?.title"
  [dialogRef]="dialogRef"
  (close)="onCancel()"
></app-dialog-header>

<mat-dialog-content>
  <kpi4me-text-element
    [formControlElement]="this.reason"
    [hideLabel]="true"
  />
</mat-dialog-content>

<div mat-dialog-actions style="margin-top:1rem;">
  <app-confirm-icon-button (customClick)="onConfirm()"/>
</div>
