import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ITreeSelectionElement} from './tree-selection-element.interface';

@Directive({
  selector: 'input[type=treeselect]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TreeSelectFormDirective,
      multi: true,
    },
  ],
  standalone: true
})
export class TreeSelectFormDirective implements ControlValueAccessor {

  constructor(private _elementRef: ElementRef<HTMLInputElement>, private _renderer: Renderer2) {
    console.log('this');
  }

  @HostListener("input", ["$event.target.value"])
  onInput = (_: any) => {
  };

  writeValue(value: ITreeSelectionElement[] | null): void {
    const treeSelection: ITreeSelectionElement[] = value || [{name: ''}];
    const labelConcat: string = treeSelection.reduce((prev: string, curr: ITreeSelectionElement) => {
      if (curr == null) return '';
      return prev === '' ? curr.name : prev + ', ' + curr.name
    }, '')
    this._renderer.setAttribute(this._elementRef.nativeElement, 'value', labelConcat)
  }

  registerOnChange(fn: any): void {
    this.onInput = (value: string) => {
      fn(value);
    };
  }

  registerOnTouched(fn: any): void {
  }
}
