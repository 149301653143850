import {
  AfterViewInit,
  Component,
  createComponent,
  ElementRef,
  EnvironmentInjector,
  inject, OnDestroy,
  viewChild,
  ViewEncapsulation
} from '@angular/core';
import {UiHelperService} from 'frontier/browserkit/src/lib/components/ui-helper/ui-helper.service';
import {OverlayModule} from 'primeng/overlay';
import {CdkConnectedOverlay} from '@angular/cdk/overlay';
import {UIHelperComponent} from 'frontier/browserkit/src/lib/components/ui-helper/ui-helper-component.interface';
import {MatIconButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {EsvgFiles} from 'frontier';
import {MatDivider} from '@angular/material/divider';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

@Component({
  selector: 'lib-ui-helper-content',
  standalone: true,
  imports: [OverlayModule, CdkConnectedOverlay, MatIconButton, MatIcon, MatDivider],
  templateUrl: './ui-helper-content.component.html',
  styleUrl: './ui-helper-content.component.css',
  encapsulation: ViewEncapsulation.None
})
export class UiHelperContentComponent implements AfterViewInit, OnDestroy {
  private _uiHelperService = inject(UiHelperService);
  private _helpContentHost = viewChild<ElementRef>('helpContentHost');
  private _injector = inject(EnvironmentInjector);

  protected readonly EsvgFiles = EsvgFiles;
  private _destroyed$ = new Subject<void>();

  constructor() {
  }

  ngAfterViewInit() {
    this._uiHelperService.openedHelp$.pipe(
      takeUntil(this._destroyed$)
    ).subscribe((evt: UIHelperComponent) => {
      createComponent(evt.component, {
        environmentInjector: this._injector,
        hostElement: this._helpContentHost().nativeElement
      });
    })
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }


  closeOverlay() {
    this._uiHelperService.close();
  }
}
