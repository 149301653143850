import {BehaviorSubject, concatMap, defer, finalize, first, Observable, of} from 'rxjs';
import {filter} from 'rxjs/operators';

export
class Debouncer {
  private changeRequestLock = 0;
  private changeQueue = new BehaviorSubject<number>(0);

  queueRequest(request: Observable<any>): Observable<any> {
    return defer(() => {
      const lock = this.changeRequestLock;
      this.changeRequestLock++;
      return this.changeQueue.pipe(
        filter((i) => i === lock),
        first(),
        concatMap(() => request),
        finalize(() =>
          this.changeQueue.next(
            this.changeRequestLock - lock > 1 ? this.changeRequestLock - 1 : this.changeRequestLock
          )
        )
      );
    });
  }
}
