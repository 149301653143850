<app-dialog-header [title]="data.title" (close)="dialogRef.close()"></app-dialog-header>
<div
  style="
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
  "
>
  @if (data.description != null) {
    <div>{{ data.description }}</div>
  }
  <div *ngIf="data.email">{{ data.email }}</div>
  <div *ngIf="data.showCustomCheckBox">
    <kpi4me-checkbox-element
      [displayAs]="'toggle'"
      [label]="data.checkBoxText"
      [formControlElement]="checkBoxValue"
    >

    </kpi4me-checkbox-element>
  </div>
</div>
<mat-dialog-actions style="margin-top: 20px">
  <div style="display: flex;">
    <app-confirm-icon-button
      (customClick)="onConfirm()"
      [matTooltip]="data.confirmButtonText"
    ></app-confirm-icon-button>
    <app-cancel-icon-button
      style="margin-left: 1rem;"
      (click)="onCancel()"
      [matTooltip]="data.cancelButtonText"
    ></app-cancel-icon-button>
  </div>
</mat-dialog-actions>
