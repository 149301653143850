<mat-menu #userMenu="matMenu" class="customized-menu">
  <button
    *ngIf="showTenantMenu"
    [matMenuTriggerFor]="tenantMenu?.menuRef"
    xposition="before"
    (click)="updateTenantMenu()"
    mat-menu-item
  >

    <span>Mandanten wechseln</span>
  </button>

  <button
    *ngIf="this.themeService.themeClass !== 'light-theme'"
    (click)="applyTheme.emit('light-theme')"
    mat-menu-item
  >
    <span> Helles Design setzen </span>
  </button>

  <button
    *ngIf="this.themeService.themeClass !== 'dark-theme'"
    (click)="applyTheme.emit('dark-theme')"
    mat-menu-item
  >
    <span> Dunkles Design setzen </span>
  </button>

  <button
    *ngIf="this.themeService.themeClass !== 'custom-theme'"
    (click)="applyTheme.emit('custom-theme')"
    mat-menu-item
  >
    <span> Custom-Design setzen </span>
  </button>

  <button
    (click)="changeUser()"
    mat-menu-item
  >

    <span>Benutzerdaten ändern</span>
  </button>

  <button
    (click)="changeMail()"
    mat-menu-item
  >
    <!--    <mat-icon-->
    <!--      mat-ripple-->
    <!--      svgIcon="{{EsvgFiles.mail}}"-->
    <!--    ></mat-icon>-->

    <span>E-Mail-Adresse ändern</span>
  </button>

  <button
    *ngIf="menuOptions.length !== 0"
    [matMenuTriggerFor]="optionsMenu?.menuRef"
    xposition="before"
    mat-menu-item
  >

    <span>Optionen</span>
  </button>

  <button
    (click)="changeOwnPassword()"
    mat-menu-item
  >
    <!--    <mat-icon-->
    <!--      mat-ripple-->
    <!--      svgIcon="password"-->
    <!--    ></mat-icon>-->

    <span>Passwort ändern</span>
  </button>

  <button
    *ngIf="helpUrl"
    (click)="openHelp()"
    mat-menu-item
  >
    <!--    <mat-icon-->
    <!--      mat-ripple-->
    <!--      svgIcon="help"-->
    <!--    ></mat-icon>-->

    <span>Hilfe</span>
  </button>

  <button
    (click)="logout.emit()"
    mat-menu-item
  >
    <!--    <mat-icon-->
    <!--      mat-ripple-->
    <!--      svgIcon="log_out_fa_thin"-->
    <!--    ></mat-icon>-->

    <span>Ausloggen</span>
  </button>
</mat-menu>

<kpi4me-tenant-menu [moduleFilters]="moduleFilters"></kpi4me-tenant-menu>

<kpi4me-options-menu
  [options]="menuOptions"
  (optionTriggered)="optionTriggered.emit($event)"
></kpi4me-options-menu>
