<div [class]="hasError() ? 'error-cell' : ''">
  <ng-template
    #inputHost
  ></ng-template>
</div>
@if (hasError()) {
  <mat-icon
    color="warn"
    #tooltip="matTooltip"
    matTooltipClass="table-cell-error-tooltip"
    [matTooltip]="errorTooltip()"
    [svgIcon]="EsvgFiles.error"
  />
}
