import {Component, HostListener, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl} from "@angular/forms";
import {IApiControl} from 'frontier/nucleus';
import {ConfirmIconButtonComponent} from "frontier/browserkit/src/lib/components/confirm-icon-button/confirm-icon-button.component";
import {
  TextElementComponent
} from "../../../components/control/form-control/dynamic-form/form-element/text-element/text-element.component";
import {DialogHeaderComponent} from "frontier/browserkit/src/lib/components/dialog-header/dialog-header.component";

@Component({
  selector: 'kpi4me-new-entry-dialog-component',
  templateUrl: './new-entry-dialog.component.html',
  styleUrls: ['./new-entry-dialog.component.scss'],
  standalone: true,
  imports: [
    DialogHeaderComponent,
    MatDialogContent,
    TextElementComponent,
    MatDialogActions,
    ConfirmIconButtonComponent,
  ],
})
export class NewEntryDialogComponent {
  option = new FormControl(this.data.oldValue ? this.data.oldValue : '');

  @HostListener('window:keydown.enter', ['$event'])
  onEnter() {
    this.onConfirm();
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<NewEntryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { apiInstance: IApiControl, categoryLabel: string, oldValue: string }
  ) {
  }

  ngOnInit(): void {
    return;
  }

  /**
   * closes the dialog and returns the new/changed entry for the dynamic array as a string
   */
  onConfirm(): void {
    this.dialogRef.close(this.option.value);
  }

  /**
   * closes the new entry dialog without returning a response value
   */
  onCancel(): void {
    this.dialogRef.close();
  }

  /**
   * Determines the title message for the user in a new entry dialog depending on if it's a change or a new dialog and
   * if the category is known.
   */
  getTitle(): string {
    if (this.data.oldValue) {
      return 'In was soll die Option "' + this.data.oldValue + '" geändert werden?';
    }
    if (this.data.categoryLabel) {
      return this.data.categoryLabel + ' hinzufügen:';
    }
    return 'Welche Option möchten Sie hinzufügen?';
  }
}
