<mat-menu #menu="matMenu">
  <button
    *ngIf="node && node.level != 1"
    (click)="deleteNode('node')"
    [matTooltipDisabled]="node == null"
    [matTooltip]="getRefCountOfNode()"
    class="menuButton"
    mat-menu-item
  >
    <span>Nur diesen Knoten</span>
  </button>
  <button
    (click)="deleteNode('all')"
    [matTooltipDisabled]="node == null"
    [matTooltip]="getRefCountOfNode()"
    class="menuButton"
    mat-menu-item
  >
    <span>Diesen Knoten und alle Kindknoten</span>
  </button>
  <button
    (click)="deleteNode('children')"
    *ngIf="node && node.level != 1"
    [matTooltipDisabled]="node == null"
    [matTooltip]="getRefCountOfNode()"
    class="menuButton"
    mat-menu-item
  >
    <span>Alle Kindknoten</span>
  </button>
</mat-menu>

