import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
/* NS Extension
import {MultiWindowService} from '@kpi4me/golden-angular-layout'
*/
import {IUser} from '../interfaces/user.interface';


/**
 * Service-Class that holds global state of
 *  - User
 *  - Authentication
 */

/* NS Extension
@MultiWindowService<StoreService>('store-service')*/
@Injectable({
  providedIn: 'root',
})
export class StoreService {
  activeUser$: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  serviceInfo$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  singleSignOn = (localStorage.getItem('sso') === 'true');
  manualNotAuthenticated: boolean;
  public sessionExpired = new EventEmitter<boolean>();

  constructor() {
  }

  public getSID(): string {
    return localStorage.getItem('PortalSID');
  }

  public isAuthenticated(): boolean {
    if (this.singleSignOn === true) {
      return true;
    }
    const SID = this.getSID();
    if (SID === null || SID === "dsession='-1'") {
      return false;
    } else {
      return !this.manualNotAuthenticated;
    }
  }

  setActiveUser(user: IUser) {
    if (user == null) {
      localStorage.setItem('CurrentUser', null);
    } else {
      localStorage.setItem('CurrentUser', JSON.stringify(user));
    }
    this.activeUser$.next(user);
  }

  public resetLastLogin() {
    localStorage.setItem('lastlogin', Date.now().toString());
  }
}
