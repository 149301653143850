import {Directive, effect, inject, input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthorizationService} from 'frontier/nucleus';

@Directive({
  selector: '[authorizeVisibility]',
  standalone: true
})
export class AuthorizeVisibilityDirective {
  private _authorizationService = inject(AuthorizationService);
  private _templateRef = inject(TemplateRef);
  private _viewContainerRef = inject(ViewContainerRef);
  private _hasView = false;

  // The guids that are allowed to see the element
  // If it is empty, it means that everyone can see it
  authorizeVisibility = input.required<string[] | boolean>();

  constructor() {
    effect(() => {
      const guids = this.authorizeVisibility();

      if (guids == null) {
        this.clear()
        return
      }

      if (typeof guids === 'boolean') {
        if (guids) {
          this.render()
        } else {
          this.clear()
        }
        return
      }

      // Check if at least one of the guids is present in the user's roles
      if (
        guids.length === 0 ||
        guids.some((guid) => this._authorizationService.hasControl(guid))
      ) {
        this.render();
      } else {
        this.clear();
      }
    });
  }

  private render() {
    if (!this._hasView) {
      this._viewContainerRef.createEmbeddedView(this._templateRef);
      this._hasView = true;
    }
  }

  private clear() {
    if (this._hasView) {
      this._viewContainerRef.clear();
      this._hasView = false;
    }
  }
}
