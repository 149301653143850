import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {EsvgFiles} from 'frontier/nucleus';
import {CellErrorIconComponent} from '../../cell-error-icon/cell-error-icon.component';
import {MatIcon} from '@angular/material/icon';
import {NgIf} from '@angular/common';
import {
  RendererContainerComponent
} from 'frontier/browserkit/src/lib/components/control/table-control/renderers/renderer-container/renderer-container.component';

@Component({
  selector: 'app-reference-cell-renderer',
  templateUrl: './reference-cell-renderer.component.html',
  styleUrls: ['../custom-cell/custom-cell.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    CellErrorIconComponent,
    RendererContainerComponent,
  ],
})
export class ReferenceCellRendererComponent extends CustomCellComponent {
  protected readonly EsvgFiles = EsvgFiles;

  cellEditable() {
    return this.params && this.params.value ? this.params.value.editable : true;
  }
}
