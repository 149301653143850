import {Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EsvgFiles} from 'frontier/nucleus';
import {MatIcon} from '@angular/material/icon';

@Component({
  selector: 'kpi4me-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    FormsModule,
    MatIcon,
    ReactiveFormsModule,
  ],
})
export class SearchInputComponent {
  @Output() ngModelChange = new EventEmitter();
  @Input() control: FormControl = new FormControl('');
  @ViewChild('searchInput') input: ElementRef<HTMLInputElement>;

  protected readonly EsvgFiles = EsvgFiles;

  onKeyPress(evt: KeyboardEvent) {
    if (evt.key === ' ' || evt.code === 'Space') {
      evt.stopPropagation(); // Prevent default behavior of form element on space key press, which would be submitting the form
    }
  }

  focusInput() {
    this.input?.nativeElement.focus();
  }
}
