import {
  Component,
  HostListener, inject,
  input,
  InputSignal,
  model,
  ModelSignal,
  output,
  OutputEmitterRef,
  Signal,
  viewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  ISideNavContentCategory,
  isSideNavContentItemComponent,
  TSideNavContentItem,
} from './side-nav-content-category.interface';
import {JsonPipe, NgForOf, NgIf, NgStyle} from '@angular/common';
import {MatRipple} from '@angular/material/core';
import {AuthorizeVisibilityDirective, EsvgFiles} from 'frontier/nucleus';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {SectionTitleComponent} from './section-title/section-title.component';
import {MatDivider} from '@angular/material/divider';
import {MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {Observable, Subject} from "rxjs";
import {debounceTime, takeUntil} from "rxjs/operators";
import {AUTHORIZATION_CONFIG_TOKEN} from 'frontier/nucleus/src/lib/authorization/control-list.token';

type TSideDrawerState = 'opened' | 'closed';

@Component({
  selector: 'lib-side-nav-content',
  standalone: true,
  imports: [
    NgForOf,
    MatRipple,
    NgIf,
    JsonPipe,
    MatIcon,
    MatIconButton,
    SectionTitleComponent,
    MatDivider,
    MatMenuTrigger,
    MatMenu,
    MatMenuContent,
    MatMenuItem,
    NgStyle,
    AuthorizeVisibilityDirective,
  ],
  templateUrl: './side-nav-content.component.html',
  styleUrl: './side-nav-content.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class SideNavContentComponent {
  protected readonly AUTHORIZATION_CONFIG = inject(AUTHORIZATION_CONFIG_TOKEN, {optional: true});

  matMenuTrigger: Signal<readonly MatMenuTrigger[]> = viewChildren<MatMenuTrigger>(MatMenuTrigger);
  sidePanelCategories: InputSignal<ISideNavContentCategory[]> = input.required<ISideNavContentCategory[]>();
  state: ModelSignal<TSideDrawerState> = model.required<TSideDrawerState>();
  itemClicked: OutputEmitterRef<TSideNavContentItem> = output<TSideNavContentItem>();

  protected readonly EsvgFiles = EsvgFiles;

  private mouseX: number = 0;
  private mouseY: number = 0;

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    this.mouseX = event.clientX;
    this.mouseY = event.clientY;
  }

  mouseLeave$: Subject<number> = new Subject<number>();
  mouseEnter$: Subject<number> = new Subject<number>();
  waitingToClose$: Observable<number> = this.mouseLeave$.pipe(
    debounceTime(500),
    takeUntil(this.mouseEnter$),
  );

  getMousePosition(): { x: number, y: number } {
    return {x: this.mouseX, y: this.mouseY};
  }

  onToggleOpened(): void {
    this.state.update((state: TSideDrawerState): TSideDrawerState => state === 'closed' ? 'opened' : 'closed');
  }

  onMenuTriggerHovered(i: number): void {
    console.log('hovered', i);
    this.mouseEnter$.next(i);
    this.waitingToClose$.subscribe((res: number) => {
      const relatedTarget: HTMLElement = document.elementFromPoint(this.getMousePosition().x, this.getMousePosition().y) as HTMLElement;
      if (
        !(relatedTarget?.classList.contains('mat-drawer') ||
          relatedTarget?.classList.contains('mat-mdc-menu-item') ||
          relatedTarget?.classList.contains('side-nav-menu') ||
          relatedTarget?.classList.contains('hover-menu-span'))
      ) {
        this.matMenuTrigger().at(res).closeMenu()
      }
    });
    this.matMenuTrigger().forEach((menu: MatMenuTrigger, index: number) => {
      if (index !== i) menu.closeMenu();
    })
    this.matMenuTrigger()?.at(i)?.openMenu();
  }

  onMenuMouseLeaved(i: number, evt: MouseEvent): void {
    let relatedTarget: HTMLElement = evt.relatedTarget as HTMLElement;
    if (
      relatedTarget?.classList.contains('mat-drawer') ||
      relatedTarget?.classList.contains('mat-mdc-menu-item') ||
      relatedTarget?.classList.contains('hover-menu-span')
    ) {
      return
    }
    this.mouseLeave$.next(i);
  }

  onMenuMouseLeavedFromMenu(i: number, evt: MouseEvent): void {
    this.matMenuTrigger()?.at(i)?.closeMenu();
  }

  // This function is used to check if the user has the necessary permissions to see the item
  // If the item is a component, it will check the guids in the AUTHORIZATION_CONFIG
  // If the item is a link, everyone can see it
  getAuthorizeVisibilityGuids(item: TSideNavContentItem): string[] | boolean {
    if (!this.AUTHORIZATION_CONFIG) return true;

    if (isSideNavContentItemComponent(item)) {
      const guids = this.AUTHORIZATION_CONFIG[item.componentName];
      if (!guids?.length) {
        // If there are no guids, it means that everyone can see it
        return true;
      }
      return guids
    }
    // If it is a link, everyone can see it
    return true;
  }
}
