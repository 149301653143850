import {Component, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu';
import {MatDialog} from '@angular/material/dialog';
import {SetPasswordDialogComponent} from '../../../../dialogs/basics/set-password-dialog/set-password-dialog.component';
import {Router} from "@angular/router";
import {IModuleFilter, TenantMenuComponent} from "../tenant-menu/tenant-menu.component";
import {OptionsMenuComponent} from "../options-menu/options-menu.component";
import {EsvgFiles, IUser, StoreService, themeClass, ThemeService, UserService} from 'frontier/nucleus';
import {IMenuOption} from './menu-option.interface';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: true,
  imports: [
    MatMenu,
    NgIf,
    MatMenuItem,
    MatMenuTrigger,
    TenantMenuComponent,
    OptionsMenuComponent,
  ],
})
export class UserMenuComponent implements OnInit {
  private _userService = inject(UserService);
  @ViewChild(TenantMenuComponent, {static: true}) tenantMenu: TenantMenuComponent;
  @ViewChild(OptionsMenuComponent, {static: true}) optionsMenu: OptionsMenuComponent;
  @ViewChild(MatMenu, {static: true}) menuRef: MatMenu;
  @Input() showTenantMenu = true;
  @Input() helpUrl = '';
  @Input() menuOptions: IMenuOption[] = [];
  @Input() moduleFilters: IModuleFilter[] = [];
  @Output() logout = new EventEmitter();
  @Output() themeToggle = new EventEmitter();
  @Output() applyTheme = new EventEmitter<themeClass>();
  @Output() optionTriggered = new EventEmitter<any>()

  // Object for changing user data
  user: IUser;

  protected readonly EsvgFiles = EsvgFiles;

  constructor(
    public store: StoreService,
    public themeService: ThemeService,
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this._userService.userGetGetLoginData().subscribe((user: IUser) => {
      this.user = user;
    });
  }

  changeOwnPassword() {
    if (this.store.singleSignOn) {
      this._userService.userPostRedirectToChangePassword({
        _parameters: [window.origin, this.router.url]
      }).subscribe((res: string) => {
        window.location.href = res;
      });
    } else {
      this.dialog.open(SetPasswordDialogComponent, {
        panelClass: 'customDialogContainer',
        data: {
          username: this.user.loginname,
          passwort: '',
          valuetype: '',
          type: 'change',
          title: 'Passwort ändern',
        },
        maxHeight: '99vh',
        width: '50vw',
        minWidth: '300px',
        maxWidth: 400,
      });
    }
  }

  changeUser() {
    if (this.store.singleSignOn) {
      this._userService.userPostRedirectToChangeProfile({
        _parameters: [
          window.origin, this.router.url
        ]
      }).subscribe((res: string) => {
        window.location.href = res;
      });
    }
    /*
    else {
      this.coreService.User.GetLoginData().subscribe((user: IUser) => {
        if (this.user.loginname !== user.loginname) {
          this.store.setActiveUser(user);
        }
        this.dialog.open(SetUserComponent, {
          disableClose: true,
          panelClass: 'customDialogContainer',
          data: {
            status: 'change',
            user: this.user,
            openedAsAdmin: false,
          },
          maxHeight: '99vh',
        });
      });
    }

     */
  }

  changeMail() {
    if (this.store.singleSignOn) {
      this._userService.userPostRedirectToChangeMail({
        _parameters: [
          window.origin, this.router.url
        ]
      }).subscribe((res: string) => {
        window.location.href = res;
      });
    }
  }

  getThemeToggleLabel(): string {
    return this.themeService.themeClass === 'dark-theme' ? 'Helles Design setzen' : 'Dunkles Design setzen';
  }

  updateTenantMenu(): void {
    return this.tenantMenu.updateAndOpen();
  }

  openHelp(): void {
    window.open(this.helpUrl, '_blank');
  }
}
