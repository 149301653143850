import {Component} from '@angular/core';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {tap} from 'rxjs/operators';
import {createGridRow} from '../../../adapters/table/create-grid-row.function';
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';
import {EControlActions} from 'frontier/nucleus';
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {NgIf} from '@angular/common';
import {TableControlComponent} from 'frontier';

@Component({
  selector: 'app-boolean-cell-renderer',
  templateUrl: './boolean-cell-renderer.component.html',
  styleUrls: ['./boolean-cell-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, MatSlideToggle],
})
export class BooleanCellRendererComponent extends CustomCellComponent {
  checkedHandler(evt: MouseEvent, checked: boolean) {
    evt.preventDefault();
    if (this.params.value?.editable === false) {
      evt.stopPropagation();
      return
    }
    ;

    this.params.value.value = !checked;
    const colDef: ICustomColDef = this.params.colDef;
    this._tableControlService.tableControlChangeLine({
        InstanceId: (this.params.context as TableControlComponent).apiInstance().instanceid,
        RowObject: this.params.data.apiRow.obj,
        RowIdx: this.params.data.apiRow.rowidx,
        Attribute: colDef.attribute,
        AttributeIndex: colDef.attributeindex,
        Value: this.params.value.value
      }
    )
      .pipe(
        tap((res) => {
          this._controlStore.controlDataChanged$.emit({
            GUID: (this.params.context as any).GUID,
            changeType: EControlActions.changeLine
          });
          this.params.node.setData(createGridRow(res));
          (this.params.context as any).onCellValueChanged(this.params);
        })
      )
      .subscribe();
  }
}
