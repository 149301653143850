import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  effect,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  viewChild,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {ColtypeToEditorMap} from './coltype-to-editor-map.const';
import {BaseCellEditorComponent} from './base-cell-editor/base-cell-editor.component';
import {ITableVerticalDataRow} from '../table-vertical-api-adapter';
import {Subscription} from "rxjs";
import {EsvgFiles, IControlDataChanged, IControlObject} from 'frontier/nucleus';
import {TColType} from '../../table-control/cell.enum';
import {ISelectFormOption} from '../../form-control/dynamic-form/form-element/form-data.interface';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {ICellChangedEvent} from '../cell-editor/cell-changed-event.interface';

@Component({
  selector: 'kpi4me-cell-editor',
  templateUrl: './cell-editor.component.html',
  styleUrls: ['./cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatIcon,
    MatTooltip
  ],
})
export class CellEditorComponent implements OnDestroy {
  protected readonly EsvgFiles = EsvgFiles;
  private _subs = new Subscription();

  @ViewChild('inputHost', {static: true, read: ViewContainerRef}) inputHost!: ViewContainerRef;
  @Output() cellChanged = new EventEmitter<ICellChangedEvent>();
  @Output() updateTable = new EventEmitter();
  @Output() selectionDataChange = new EventEmitter<IControlDataChanged>();
  componentRef: ComponentRef<BaseCellEditorComponent>;

  private tooltipRef = viewChild<MatTooltip>('tooltip');

  hasError = computed(() => {
    return this.componentRef.instance.error() != null
  })

  errorTooltip = computed(() => {
    return this.componentRef.instance.error() || 'Ungültiger Wert';
  });

  constructor() {
    effect(() => {
      const tooltipRef = this.tooltipRef()
      const hasError = this.hasError()
      if (hasError && tooltipRef) {
        tooltipRef.show();
      }
    });
  }


  @Input() set params(row: ITableVerticalDataRow) {
    if (row == null) {
      throw new Error('No row set. Cannot create component')
    }
    if (row.apiInstance == null) {
      throw new Error('No Api Instance as input. Cannot create component.')
    }


    this.inputHost.clear();
    const type = row.type;

    this.invalidmessage = row.invalidMessage ? {value: {invalidmessage: row.invalidMessage}} : null;

    const component = ColtypeToEditorMap[type];
    if (component == null) {
      throw new Error('Could not find a respective component for type ' + String(type));
    }
    // map repository to options for the select element
    if ((type === TColType.clRefSelection || type === TColType.clReference) && row.repository) {
      row.repository = row.repository.map((repoObject: IControlObject) => {
        let option: ISelectFormOption = {
          value: repoObject,
          name: repoObject.name
        }
        return option;
      })
    }


    this.componentRef = this.inputHost.createComponent<BaseCellEditorComponent>(component);
    this.componentRef.instance.hideLabel = true;

    this.componentRef.instance.row = row;
    this._subs.add(
      this.componentRef.instance.cellChanged.subscribe((row) => {
        this.cellChanged.emit(row);
      }))
    this._subs.add(
      this.componentRef.instance.updateTable.subscribe((row) => {
        this.updateTable.emit();
      }))
    this._subs.add(
      this.componentRef.instance.selectionDataChanged.subscribe((evt) => {
        this.selectionDataChange.emit(evt);
      }))
    row.component = this.componentRef.instance;
  }

  invalidmessage: { value: { invalidmessage: string } };

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
