export enum TColType {
  clNone, // Sollte nie gesetzt sein
  clLevel, // Für die Level-Eingabe --> Zahl und Punkt möglich
  clText, // Für die reine Textdarstellung ohne Änderungen
  clValue, // Für die Anwendung der Pipe(GUI)
  clAction, // Für die Aktionen
  clBool, // Für Boolean-Werte
  clIcon, // Für die Anzeige eines Icons
  clTodoValue, // Für die Werterfassung
  clName, // Für KPI-Name setzen
  clValTuple, // Für mehrere Werte in einem JSON-Objekt
  clColor, // Für einen Farbwert
  clTime, // Für ein spezifisches Objekt: Anzeige Datum, course_plan-Tabelle
  clReference, // Für eine Objekt-Referenz
  clRefSelection, // Für eine Objekt-Referenz-Sekektion
  clNumber, // Für einen Integer
  clEnum, // Für einen EnumType
  clDateTime, // Für einen Datetime
  clTimeRangeString,
  clEnumMulti,
  clPercentage, // Prozentwerte
  clTimeOfDay,
  clLongText,
  clAutoComplete,
  clDocument,
  clMail,
  clTelephone,
  clIban,
  clCurrency,
  resourceDay = 100,
  document
}
