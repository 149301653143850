import {Directive, ElementRef} from '@angular/core';
import {NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

@Directive({
  selector: '[appRemoveDatepickerValidation]',
  standalone: true
})
export class RemoveDatepickerValidationDirective {
  constructor(private e: ElementRef, private dt: NgbInputDatepicker) {
    dt.validate = () => null;
  }
}
