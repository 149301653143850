import {ICustomGridRow} from '../../table-control/interfaces/custom-grid-row.interface';
import {IApiCell} from '../../table-control/api-cell.interface';
import {IApiRow} from '../../table-control/api-row.interface';

/**
 * Creates one single ag-grid row given a row from the api.
 * @param apiRow: One single row from the api.
 */

export function createGridRow(apiRow: IApiRow): ICustomGridRow {
  let row: ICustomGridRow = {
    apiRow: apiRow,
  };
  apiRow.cols.forEach((apiCell: IApiCell, index: number) => {
    row[Number(index)] = apiCell;
  });
  return row;
}
