<kpi4me-text-element style="padding-top: 0.5rem;"
  [formControlElement]="tableGuidForm"
  [data]="tableGuidFormData"
  (modelChange)="onTableGuidChange($event)"
>

</kpi4me-text-element>
@if (render()) {
  <lib-guid-table-control
    [inputGUID]="tableGuid()"
  />
}
