import {Directive, EventEmitter, HostBinding, HostListener, Output,} from '@angular/core';

@Directive({
  selector: '[appDropUpload]',
  standalone: true
})
export class DropUploadDirective {
  @Output() fileDropped = new EventEmitter<FileList>();

  @HostBinding('class.fileover') fileOver: boolean;

  @HostListener('dragover', ['$event']) onDragOver(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    this.fileDropped.emit(evt.dataTransfer.files);
  }

  constructor() {
  }
}
