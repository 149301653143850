import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ICellRendererParams} from 'ag-grid-community';
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';
import {ControlStore} from 'frontier/nucleus/src/lib/apiv2/control.store';
import {DecimalPipe, NgIf} from '@angular/common';
import {MatTooltip} from '@angular/material/tooltip';
import {ControlService, TableControlService} from 'frontier/nucleus';
import {
  RendererContainerComponent
} from 'frontier/browserkit/src/lib/components/control/table-control/renderers/renderer-container/renderer-container.component';

@Component({
  selector: 'app-custom-cell',
  templateUrl: './custom-cell.component.html',
  styleUrls: ['./custom-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatTooltip,
    NgIf,
    DecimalPipe,
    RendererContainerComponent,
  ],
})
export class CustomCellComponent implements OnInit, ICellRendererAngularComp {
  protected _controlService = inject(ControlService);
  protected _controlStore = inject(ControlStore);
  protected _tableControlService = inject(TableControlService);
  params: ICellRendererParams;

  constructor() {
  }

  ngOnInit(): void {
    return;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  // Helper method to check if a cell is of type double / float
  isFloatValue(): boolean {
    return (
      (this.params.colDef as ICustomColDef).apiCol.attributetype === 'Double'
    );
  }

  startEdit(evt: MouseEvent) {
    evt.stopPropagation();
    this.params.api.stopEditing(true);
    this.params.api.startEditingCell({rowIndex: this.params.rowIndex, colKey: this.params.column});
  }
}
