import {Component} from '@angular/core';
import {MatSlideToggle, MatSlideToggleChange} from '@angular/material/slide-toggle';
import {CustomCellComponent} from '../custom-cell/custom-cell.component';
import {FormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-custom-bool-cell-renderer',
  templateUrl: './custom-bool-cell-renderer.component.html',
  styleUrl: './custom-bool-cell-renderer.component.scss',
  standalone: true,
  imports: [NgIf, MatSlideToggle, FormsModule]
})
export class CustomBoolCellRendererComponent extends CustomCellComponent {
  onChange(event: MatSlideToggleChange) {
    this.params.setValue({...this.params.value, value: event.checked});
  }
}
