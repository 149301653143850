import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {AuthenticationService} from '../authentication/authentication.service';
import {FeedbackService} from '../services/feedback.service';
import {IApiError, IApiErrorDto} from 'frontier/nucleus/src/lib/types/api-error-dto.interface';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthenticationService,
    private feedbackService: FeedbackService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log(request);
    // If custom/link/auth request => return
    // Pragma header darf nicht gesetzt sein. Leerer Header.
    if (
      request.url.includes('/extern/') ||
      request.url.includes('/resource/') ||
      request.url.includes('demoservice')
    ) {
      return next.handle(request.clone()).pipe(
        tap(),
        catchError((err: HttpErrorResponse) => {
          console.error(err.error);
          this.feedbackService.setError(err.error);
          throw err;
        })
      );
    } else {
      let newHeaders: object = {
        headers: request.headers.set('Pragma', `${this.auth.store.getSID()}`),
      };
      if (this.auth.apiConfigService.environment.env === 'dev') {
        const bearerToken = this.auth.getBearerToken();
        if (bearerToken) {
          newHeaders = this.setBearerToken(request, bearerToken);
        }
      }
      return next
        .handle(
          request.clone(newHeaders)
        )
        .pipe(
          tap((httpEvent: HttpEvent<any>) => {
            // Skip request
            if (httpEvent.type === 0) {
              return;
            }
            if (httpEvent instanceof HttpResponse) {
              this.auth.saveSessionPortal(httpEvent as HttpResponse<any>);
              if (this.auth.apiConfigService.environment.env === 'dev') {
                this.auth.updateBearerToken(httpEvent as HttpResponse<any>);
              }
            }
          }),
          catchError((err: HttpErrorResponse) => {
            try {
              this.handleApiError(err);
            }
            catch (e) {
              this.handleUnknownError(err);
            }
            throw err;
          })
        );
    }
  }

  private setBearerToken(request: HttpRequest<any>, bearerToken: string): object {
    let newHeaders;
    const Tenant = this.auth.getTenant();
    if (Tenant) {
      newHeaders = {
        headers: request.headers.set('Authorization', 'Bearer ' + bearerToken).set('refresh_token', this.auth.getRefreshTokenToken()).set('tenant', Tenant),
      }
    } else {
      newHeaders = {
        headers: request.headers.set('Authorization', 'Bearer ' + bearerToken).set('refresh_token', this.auth.getRefreshTokenToken()),
      }
    }
    return newHeaders;
  }

  private handleApiError(res: HttpErrorResponse) {
    const apiErrorDto: IApiErrorDto = res.error;
    const apiError: IApiError = apiErrorDto.error;
    if (apiError == null) {
      throw res;
    }
    console.error(`[logId: ${apiError.logId}]: ${apiError.developerMsg}`);
    if (apiError.stacktrace) {
      console.error(`Stacktrace: ${apiError.stacktrace}`);
    }
    switch (apiError.display) {
      case 'logOnly':
        break;
      case 'showUser':
        this.feedbackService.setError(apiError.userMsg);
        break;
      case 'validationError':
        this.feedbackService.setError(apiError.userMsg);
        break;
    }
  }

  private handleUnknownError(err: HttpErrorResponse) {
    if (err.status === 403 || err.status === 401) {
      if ((err.status === 403) && (err.statusText === 'Session has expired')) {
        // Route to url without #
        this.feedbackService.setError(
          'Die Sitzung ist abgelaufen. Bitte erneut anmelden.'
        );
        this.auth.setSessionExpired();
        //this.auth.redirectToLogin();
      } else if (err.status === 403 && err.statusText === 'Forbidden') {
        this.feedbackService.setError(
          'Falsche Anmeldedaten. Der Nutzername oder das Passwort ist falsch.'
        );
        this.auth.deleteSession();
        this.auth.navigateToLogin();
      } else if (err.status === 403 && err.statusText === 'Unauthorized') {
        this.feedbackService.setError(
          'Sie besitzen nicht die erforderlichen Berechtigungen diese Aktion durchzuführen.'
        );
        console.error('unauthorized api request: ', err)
        // this.auth.deleteSession();
        // this.auth.navigateToLogin();
      } else {
        this.feedbackService.setError(
          'Es ist ein unbekannter Fehler aufgetreten.'
        );
        console.error(err);
      }
    }
    else if (err.status === 0) {
      // Connection to service Failed
      this.feedbackService.setError(
        'Verbindung zum Server fehlgeschlagen.'
      );
    }
    else if (err.status === 412) {
      // Connection to service Failed
      this.feedbackService.setError(err.error);
    }
    else {
      console.error(err);
      if (err.error && err.error.error) {
        this.feedbackService.setError(err.error.error);
      } else {
        throw err;
      }
    }
  }
}
