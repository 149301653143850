import {ChangeDetectorRef, Component, effect, inject, input, signal, viewChild, ViewContainerRef,} from '@angular/core';
import {TabsService} from '../tabs.service';
import {ITab} from '../tab.interface';

@Component({
  selector: 'lib-tab-content',
  standalone: true,
  imports: [],
  templateUrl: './tab-content.component.html',
  styleUrl: './tab-content.component.scss'
})
export class TabContentComponent {
  private _cdRef = inject(ChangeDetectorRef);
  private _tabsService = inject(TabsService);
  componentRegistry = this._tabsService.componentRegistry;
  data = input.required<ITab>();
  containerSig = viewChild('container', {read: ViewContainerRef});

  constructor() {
    effect(() => {
      const data = this.data;
      const containerSig = this.containerSig;
      if (containerSig && data) {
        this.setupComponent();
      }
    });
  }

  private setupComponent(): void {
    const componentRegistryElement = this.componentRegistry[this.data().componentName];
    if (componentRegistryElement) {
      this.containerSig().clear();
      this.data().componentInstance = this.containerSig().createComponent(componentRegistryElement).instance;
      this._cdRef.detectChanges();
    } else {
      console.error(`Component with name "${this.data().componentName}" not found in registry.`);
    }
  }
}
