import {Component, EventEmitter, inject, Input, Output, ViewChild,} from '@angular/core';
import {MatMenu, MatMenuItem} from '@angular/material/menu';
import {TreeNode} from '@ali-hm/angular-tree-component';

import {ControlService, IApiControl} from 'frontier/nucleus';
import {ITreeControlConfiguration} from '../tree-control-config.interface';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-node-style-menu',
  templateUrl: './node-style-menu.component.html',
  styleUrls: ['./node-style-menu.component.scss'],
  standalone: true,
  imports: [
    MatMenu,
    NgIf,
    MatMenuItem,
    ColorPickerModule,
  ],
})
export class NodeStyleMenuComponent {
  private _controlService = inject(ControlService);
  @ViewChild(MatMenu, {static: true}) menu: MatMenu;
  @Input() node: TreeNode;
  @Input() config: ITreeControlConfiguration;
  @Input() apiInstance: IApiControl;
  @Output() updatedStyle = new EventEmitter();

  onColorColorPickerClosed(color: string) {
    this.addStylePropertyToNode('color', color);
  }

  onBgColorPickerClosed(color: string) {
    this.addStylePropertyToNode('backgroundColor', color);
  }

  addStylePropertyToNode(styleAttribute: string, value: string) {
    if (this.node.data.styles == null) {
      this.node.data.styles = {};
    }
    this.node.data.styles[styleAttribute] = value;
    this.saveNodeStyle(this.node);
  }

  private saveNodeStyle(menuNode: TreeNode) {
    const data = {
      node: menuNode.data,
      styles: menuNode.data.styles,
    };
    this._controlService.controlPostExecuteAction({
        _parameters: [
          this.apiInstance.instanceid,
          'ChangeStyles',
          data
        ]
      }
    ).subscribe((res) => {
      console.log(res);
      this.updatedStyle.emit();
    });
  }
}
