<kpi4me-dynamic-form
  *ngIf="data()"
  [standardForm]="standardForm"
  [formData]="data()"
  (formChange)="onFormChange($event)"
  [displayHeaders]="displayHeaders"
  [showImports]="showImports()"
  [actions]="actions()"
  (callActionFunction)="callActionFunction($event)"
/>

<!-- Error when an error occures which is not specific to a form element  -->
@if (error() != null) {
  <mat-error>
    {{ error() }}
  </mat-error>
}

<input
  #fileUpload
  style="display: none"
  type="file"
  accept=".json"
  (change)="uploadJson($event).subscribe()"
/>
