<div (onResize)="onTableResize()">
  @if (actions()?.length > 0 || tableName) {
    <div class="optionWrapper">
      {{ tableName }}

      @for (action of actions(); track action.controlAction) {
        <button
          *ngIf="action.isHidden == null || (!action.isHidden())"
          (click)="callActionFunction(action.controlAction)"
          [color]="action.color | valueOrFunction"
          [disabled]="getIsDisabled(action)"
          [matTooltip]="action.tooltip"
          [matTooltipShowDelay]="action.tooltipDelay || 0"
          mat-raised-button
        >
            <span
              style="display: flex; align-items: center; justify-content: center; gap: 0.5rem"
              [class]="getIsDisabled(action) ? 'mat-button-disabled' : ''">
              @if (action.icon | valueOrFunction) {
                <mat-icon
                  style="display: flex;"
                  class="centered"
                  *ngIf="action.icon | valueOrFunction"
                  [svgIcon]="action.icon | valueOrFunction"
                ></mat-icon>
              }
              {{ action.displayName | valueOrFunction }}
           </span>
        </button>
      }
      <mat-checkbox
        (change)="onFilterChange($event.checked, filter)"
        *ngFor="let filter of filters()"
        [checked]="filter.active"
        [color]="'primary'"
      >
        {{ filter.name }}
      </mat-checkbox>
    </div>
  }


  <div id="grid-wrapper" #gridWrapper>
    <ag-grid-angular #grid contenteditable="true" spellcheck="false"
                     onbeforeinput="return false"
                     [context]="context"
                     [columnDefs]="columnDefs"
                     [getRowId]="getRowNodeId"
                     [gridOptions]="gridOptions"
                     [class]="gridClass"
                     (gridReady)="onGridReady($event)"
                     (copy)="onCopyValue($event)"
                     (paste)="onPaste($event)"
                     (cellClicked)="onCellClicked($event)"
                     (cellContextMenu)="onCellContextMenu($event)"
                     (paginationChanged)="paginationChanged$.emit($event);"
                     (rowSelected)="onRowSelectedEvent($event)"
                     (rowClicked)="onRowClicked($event)"
                     (cellDoubleClicked)="cellDoubleClicked.emit($event)"
                     (newColumnsLoaded)="onVirtualColumnChange($event)"
                     (virtualColumnsChanged)="onVirtualColumnChange($event)"
                     (filterModified)="onColumnFilterChange($event)"
                     (sortChanged)="onSortChange($event)"
                     (bodyScroll)="onGridScroll($event)"
                     style="height: 100%"
    ></ag-grid-angular>
    <div #pdfOverlay id="pdf-overlay"
    >
      @if (pdf()) {
        <embed
          style="height: 100%; width: 100%"
          [src]="pdfSrc()"
        >
      }
    </div>
  </div>
</div>
<input
  #fileUpload
  style="display: none"
  type="file"
  accept=".csv"
  (change)="uploadCsv($event).subscribe()"
/>
