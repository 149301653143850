import {Component, ViewEncapsulation} from '@angular/core';
import {BaseCellEditorComponent} from '../base-cell-editor/base-cell-editor.component';
import {TTextInputType} from '../../../form-control/dynamic-form/form-element/form-data.interface';
import {TColType} from '../../../table-control/cell.enum';
import {MatTooltip} from '@angular/material/tooltip';
import {
  TextElementComponent
} from '../../../form-control/dynamic-form/form-element/text-element/text-element.component';

@Component({
  selector: 'kpi4me-text-cell-editor',
  templateUrl: './text-cell-editor.component.html',
  styleUrls: ['./text-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [TextElementComponent, MatTooltip],
})
export class TextCellEditorComponent extends BaseCellEditorComponent {
  mapType(): TTextInputType {
    switch (this.row.type) {
      case TColType.clText: {
        return 'text';
      }
      case TColType.clLongText: {
        return 'textarea';
      }
      case TColType.clNumber: {
        return 'number';
      }
      case TColType.clTelephone: {
        return 'tel';
      }
      // Backend type for 'url' is still missing
      case TColType.clMail: {
        return 'email';
      }
      // Backend type for 'password' is still missing
      case TColType.clIban: {
        return 'iban';
      }
      default: {
        return null;
      }
    }
  }
}
