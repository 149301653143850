import {inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UIHelperComponent} from 'frontier/browserkit/src/lib/components/ui-helper/ui-helper-component.interface';
import {Overlay, OverlayConfig} from '@angular/cdk/overlay';
import {ComponentPortal} from '@angular/cdk/portal';
import {
  UiHelperContentComponent
} from 'frontier/browserkit/src/lib/components/ui-helper/ui-helper-content/ui-helper-content.component';

@Injectable({
  providedIn: 'root'
})
export class UiHelperService {
  openedHelp$ = new BehaviorSubject<UIHelperComponent>(null);

  private _overlay = inject(Overlay);

  private _config = new OverlayConfig(
    {
      height: '100%',
      width: 300,
      positionStrategy: this._overlay.position().global().right('0'),
      panelClass: ['ui-helper-panel', 'mat-elevation-z2'],
    }
  )

  private _overlayRef = this._overlay.create(this._config);

  openHelp(config: UIHelperComponent) {
    const uiHelperContentComponentPortal = new ComponentPortal(UiHelperContentComponent);
    this._overlayRef.detach();
    this._overlayRef.attach(uiHelperContentComponentPortal);
    this.openedHelp$.next(config);
  }

  close() {
    this._overlayRef.detach();
  }
}
