import {Component, Input, OnDestroy, ViewEncapsulation} from '@angular/core';
import {BaseFormElementComponent} from '../base-form-element.class';
import {ICheckboxFormElement} from '../form-data.interface';
import {AbstractControl, FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {Subscription} from "rxjs";
import {MatSlideToggle} from '@angular/material/slide-toggle';
import {MatCheckbox} from '@angular/material/checkbox';
import {NgIf} from '@angular/common';

@Component({
  selector: 'kpi4me-checkbox-element',
  templateUrl: './checkbox-element.component.html',
  styleUrls: ['./checkbox-element.component.scss', '../form-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    NgIf,
    MatCheckbox,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggle,
  ],
})
export class CheckboxElementComponent extends BaseFormElementComponent implements OnDestroy {
  @Input() displayAs: "checkbox" | "toggle";


  @Input()
  override set data(form: ICheckboxFormElement) {
    this.displayAs = form.displayAs;
    super.data = form;
  }

  @Input()
  override set formControlElement(c: AbstractControl) {
    if (c) {
      this._formControlElement = c;
      this.subs.add(
        this.formControlElement.valueChanges.subscribe(() => {
          this.modelChange.emit(this._formControlElement as FormControl)
        }),
      );
    }
  };

  override get formControlElement(): FormControl {
    return this._formControlElement as FormControl;
  }

  private subs = new Subscription()

  constructor() {
    super();

  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  override onFocusOut(evt: FocusEvent) {
    return;
  }
}
