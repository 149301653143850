export enum EControlActions {
  addNode = 'Knoten hinzufügen',
  arrange = 'Anordnen',
  arrangeAll = 'Alle anordnen',
  attendance = 'Anwesenheit bescheinigen',
  attestation = 'Bescheinigen',
  cancel = 'Stornieren',
  change = 'Ändern',
  changeLine = 'Zellenwert ändern', // not for custom action
  changeName = 'Knotennamen ändern',
  changeNodePosition = 'Knotenposition ändern',
  changeSelectionEntry = 'Selektionseintrag ändern',
  check = 'Geprüftstatus togglen',
  confirm = 'Änderungen bestätigen',
  connection = 'Neue Verbindung anlegen',
  contract = 'Vertrag',
  copy = 'Duplizieren', // not for custom action
  create = 'Erstellen', // not for custom action
  credit = 'Gutschrift',
  csvExport = "CSV-Export",
  csvImport = "CSV-Import",
  customer = 'Kunde',
  delete = 'Löschen', // not for custom action
  deleteInvoice = 'Rechnung löschen',
  deleteSelectionEntry = 'Selektionseintrag löschen',
  deprecate = 'Verwerfen',
  detail = 'Verknüpfte Sicht öffnen',
  device = 'Neues Gerät anlegen',
  dunning = 'Mahnung erstellen',
  edit = 'Ändern',
  editAll = 'Alle ändern',
  etl = 'ETL-Schritt ausführen',
  execute = 'Erzeugen',
  export = 'Exportieren',
  import = 'Importieren',
  invoice = 'Rechnung erstellen',
  invoicePreview = 'Rechnung anzeigen',
  jsonExport = "JSON-Export",
  jsonImport = "JSON-Import",
  load = 'Laden',
  loadAll = 'Alle laden',
  key = 'Neuen Schlüsseltyp anlegen',
  map = 'Zuordnen',
  mail = 'Elektronische Post',
  newMission = 'Neuer Einsatz',
  newNode = 'Neuen Knoten erstellen',
  newOrdinance = 'Neue Dauerverordnung anlegen',
  newReference = 'Neue Referenz setzen',
  newSelectionEntry = 'Selektionseintrag erstellen',
  partlyCancel = 'Teilstornieren',
  payed = 'Bezahlt',
  phone = 'Neuen Telefontyp erstellen',
  preview = 'Vorschau anzeigen',
  recalculateItemPriceHistory = 'Preise rückwirkend anwenden',
  reinvoice = 'Rechnung erneut erzeugen',
  removeNode = 'Knoten löschen',
  replaceClientForCart = 'Patientendaten der Fahrt ersetzen',
  reroll = 'Reroll',
  resetpassword = 'Passwort ändern',
  resolveClientMessage = 'Patientennachrichten auflösen',
  resynchronize = 'Resynchronisieren',
  rule = 'ETL-Regeln öffnen',
  selectAll = 'Alle selektieren', // not for custom action
  successor = 'Nachfolger',
  synchronize = 'Synchronisieren',
  tariffChange = 'Tarifänderung',
  tariffOption = 'Tarifoptionen',
  uncollectible = 'Uneinbringlich',
  undo = 'Aktion zurücknehmen',
  variantcreate = 'Variante erstellen',
}

export const changes = new Set([
  EControlActions.changeLine,
  EControlActions.create,
  EControlActions.delete,
  EControlActions.copy,
])
