import {Directive, ElementRef, EventEmitter, HostListener, Input, Output,} from '@angular/core';
import {AuthenticationService} from '../authentication/authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Directive({
  selector: '[appConfirmButton]',
  standalone: true,
})
export class ConfirmButtonDirective {
  @Input() confirmDisabled: boolean;
  @Output() clicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private el: ElementRef,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar
  ) {
    this.clicked.emit(false);
  }

  @HostListener('click', ['$event']) onClick(event: Event) {
    if (!this.confirmDisabled) {
      this.clicked.emit(true);
      this.snackBar.dismiss();
    } else {
      this.snackBar.open('Ihre Anfrage wird zurzeit noch bearbeitet', 'ok', {
        duration: 3000,
      });
    }
  }
}
