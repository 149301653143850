import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {API_CONFIG_TOKEN, IApiConfig} from './api-config.interface';
import {IEnvironment} from '../interfaces/environment.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiConfigService {
  url: string;
  environment: IEnvironment;

  constructor(
    public http: HttpClient,
    @Inject(API_CONFIG_TOKEN) private config: IApiConfig
  ) {
    this.environment = config.environment;
    if (this.url === undefined) {
      this.url = config.url;
    }
  }
}
