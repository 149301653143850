import {ApiAdapter} from 'frontier/nucleus';

export class TreeApiAdapter extends ApiAdapter {
  from(apiData: any): any {
    return apiData;
  }

  to(data: any): any {
  }
}
