import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {CustomCellEditorComponent} from '../custom-cell-editor/custom-cell-editor.component';
import {ICellEditorParams} from 'ag-grid-community';
import {BehaviorSubject, Observable, of, startWith} from 'rxjs';
import {debounceTime, map, mergeMap} from 'rxjs/operators';
import {createGridRow} from "../../../adapters/table/create-grid-row.function";
import {ICustomColDef} from '../../interfaces/custom-col-def.interface';
import {IApiRow, TableControlComponent} from 'frontier/browserkit';
import {MatTooltip} from '@angular/material/tooltip';
import {MatOption} from '@angular/material/core';
import {AsyncPipe, NgFor} from '@angular/common';
import {MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'kpi4me-autocomplete-cell-editor',
  templateUrl: './autocomplete-cell-editor.component.html',
  styleUrls: ['./autocomplete-cell-editor.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    FormsModule,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatAutocomplete,
    NgFor,
    MatOption,
    MatTooltip,
    AsyncPipe,
  ],
})
export class AutocompleteCellEditorComponent extends CustomCellEditorComponent
  implements OnDestroy, AfterViewInit {

  @ViewChild('input', {read: ViewContainerRef})
  public override input: ViewContainerRef;
  filteredEntities = new BehaviorSubject<any>([]);

  displayWith = (value: any) => {
    if (value) {
      return typeof value === 'string' ? value : value.name;
    }
  };

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    this.onOptionSelected()
  }

  override agInit(params: ICellEditorParams) {
    super.agInit(params);
    this.textFormControl.valueChanges.pipe(
      startWith(''),
      debounceTime(10),
      mergeMap(value => {
        return value ? this.getEntities(value) : []
      }),
    ).subscribe((entities) => {
      this.filteredEntities.next(entities);
    });
  }

  override ngAfterViewInit() {
    // focus on the input
    setTimeout(() => {
      (<ViewContainerRef>this.input).element.nativeElement.focus();
    });
  }

  override ngOnDestroy(): void {
    return null;
  }

  override isPopup(): boolean {
    return false;
  }

  onOptionSelected() {
    const data: {
      client: any, location?: string, district?: string, postcode?: string, city?: any
    } = {
      client: this.params.node.data.apiRow.obj,
    }
    if (typeof this.textFormControl.value === 'string') {
      data[this.attribute as 'location' | 'district' | 'postcode'] = this.textFormControl.value;
    } else {
      data.city = this.textFormControl.value;
    }
    this._controlService.controlPostExecuteAction({
      _parameters: [
        (this.params.context as TableControlComponent).apiInstance().instanceid,
        'setcity',
        data
      ]
    }).subscribe((res: object) => {
      const apiRow = res as IApiRow;
      this.params.node.setData(createGridRow(apiRow));
      (this.params.context as any).onCellValueChanged(this.params);
    })
  }

  getEntities(filterValue: string): Observable<any[]> {
    if (typeof filterValue !== 'string') {
      return of([]);
    }
    return this._controlService.controlPostExecuteAction({
        _parameters: [
          (this.params.context as TableControlComponent).apiInstance().instanceid,
          'getcity',
          {
            [this.attribute]: filterValue
          }
        ]
      }
    ).pipe(map((res: object) => {
      const rows = res as { name: string }[] | string
      if (typeof rows === 'string') return [];

      if (rows) {
        return rows.map(r => {
          return {
            apiRow: r,
            name: r.name,
          }
        })
      }
      return null
    }))
  }

  get attribute() {
    return (this.params.colDef as ICustomColDef).apiCol.attribute;
  }
}
