<mat-menu #menu="matMenu">
  <button
    (click)="$event.preventDefault(); $event.stopPropagation()"
    (colorPickerSelect)="onColorColorPickerClosed($event)"
    *ngIf="config?.contextMenuActions?.changeColor"
    [(colorPicker)]="node?.data.color"
    [cpOKButton]="true"
    [cpSaveClickOutside]="false"
    class="menuButton"
    mat-menu-item
  >
    <!--    <mat-icon class="blue-icon" mat-ripple svgIcon="transfer_format"></mat-icon>-->
    <span>Schriftfarbe</span>
  </button>
  <button
    (click)="$event.preventDefault(); $event.stopPropagation()"
    (colorPickerSelect)="onBgColorPickerClosed($event)"
    [(colorPicker)]="node?.data.backgroundColor"
    [cpOKButton]="true"
    [cpSaveClickOutside]="false"
    class="menuButton"
    mat-menu-item
  >
    <!--    <mat-icon class="blue-icon" mat-ripple svgIcon="transfer_format"></mat-icon>-->
    <span>Hintergrundfarbe</span>
  </button>
</mat-menu>
