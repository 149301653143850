import {Component, Input, viewChild} from '@angular/core';
import {EsvgFiles} from 'frontier/nucleus';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {JsonPipe, NgIf} from '@angular/common';
import {ICellRendererParams} from 'ag-grid-community';

@Component({
  selector: 'app-cell-error-icon',
  templateUrl: './cell-error-icon.component.html',
  styleUrls: ['./cell-error-icon.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatIcon,
    MatTooltip,
    JsonPipe,
  ],
})
export class CellErrorIconComponent {
  protected readonly EsvgFiles = EsvgFiles;

  tooltipRef = viewChild<MatTooltip>('tooltipRef');

  @Input() params: ICellRendererParams;
}
