import {Component, inject, model, ViewEncapsulation} from '@angular/core';
import {GridOptions} from 'ag-grid-community';
import {changes, EControlActions, UserListTableService} from 'frontier/nucleus';
import {SharedGridOptions} from '../../components/control/table-control/shared-gridoptions.const';
import {ITableAction} from "../../components/control/table-control/interfaces/actions.interface";
import {
  CSV_EXPORT_ACTION,
} from "../../components/control/table-control/default-table-actions.constant";
import {concatMap, filter, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {TableControlComponent} from "../../components/control/table-control/table-control.component";
import {DialogService} from "../../dialogs/dialog.service";
import {ValueOrFunctionPipe} from 'frontier/browserkit/src/lib/pipes/value-or-function.pipe';
import {AgGridModule} from 'ag-grid-angular';
import {MatCheckbox} from '@angular/material/checkbox';
import {MatDivider} from '@angular/material/divider';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {MatButton} from '@angular/material/button';
import {NgFor, NgIf} from '@angular/common';
import LibControlGuids from 'frontier/nucleus/src/lib/apiv2/ControlGuids';
import { OnTabActivated } from '../../components/home-without-gl/tabs/tab-activated.interface';
import {
  CreateUserDialogComponent
} from 'frontier/browserkit/src/lib/views/user-list/create-user-dialog/create-user-dialog.component';

@Component({
  selector: 'kpi4me-user-list',
  templateUrl: '../../components/control/table-control/table-control.component.html',
  styleUrl: '../../components/control/table-control/table-control.component.scss',
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    NgIf,
    MatButton,
    MatTooltip,
    MatIcon,
    MatDivider,
    NgFor,
    MatCheckbox,
    AgGridModule,
    ValueOrFunctionPipe,
  ],
})
export class UserListComponent extends TableControlComponent implements OnTabActivated {
  private _userListService = inject(UserListTableService);
  private _dialogService = inject(DialogService);
  override GUID = LibControlGuids.TUserListTable;

  override gridOptions: GridOptions = {
    ...SharedGridOptions,
    rowSelection: 'single',
  };
  override actions = model<ITableAction[]>([
    CSV_EXPORT_ACTION, {
      displayName: 'Nutzer anlegen',
      controlAction: EControlActions.resetpassword,
      disabledIfNoSelection: false,
      disabledIfMultiSelection: false,
      color: 'primary',
      action: () => this.openCreateUserDialog(),
    },
  ]);

  constructor() {
    super();
    this.subs.add(
      this.controlStore.controlDataChanged$.pipe(
        filter(evt => {
          return new Map([
            [LibControlGuids.TUserGroupListTable, changes],
          ]).get(evt.GUID)?.has(evt.changeType);
        }),
        tap(() => {
          this.refreshInfiniteCache();
        })).subscribe(),
    );
  }

  onTabActivated() {
    this.fitColumns();
  }

  private openCreateUserDialog(): Observable<boolean> {
    return this._dialogService.dialog.open(CreateUserDialogComponent, {
      width: '80vw',
      maxWidth: '35rem'
    }).afterClosed().pipe(
      filter(v => !!v),
      concatMap((dialogResult: string[]) => {
        return this._userListService.userListTableNewUser({InstanceId: this.instanceId(), login: dialogResult})
      }),
      tap(() => this.changeAndFetchInstance())
    );
  }
}
