<div
  class="input-group"
  style="width: 0"
>
  <input
    #datepicker="ngbDatepicker"
    (dateSelect)="onDateSelection($event)"
    [autoClose]="'outside'"
    [dayTemplate]="t"
    [displayMonths]="3"
    [placement]="placement"
    [showWeekNumbers]="true"
    [startDate]="fromDate!"
    class="form-control"
    name="datepicker"
    ngbDatepicker
    outsideDays="collapsed"

    style="visibility: hidden; width: 0; height: 0"
  />
  <ng-template #t let-date let-focused="focused">
    <span
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
      [class.faded]="isHovered(date) || isInside(date)"
      [class.first]="isFirst(date)"
      [class.focused]="focused"
      [class.last]="isLast(date)"
      [class.range]="isRange(date)"
      class="custom-day"
    >
      {{ date.day }}
    </span>
  </ng-template>
</div>

<div class="calendar-inputs-wrapper">
  <!--  <mat-divider class="divider" [vertical]="true"></mat-divider>-->
  <kpi4me-granularity-rotator
    (granularityChange)="onGranularityChange($event)"
    [(granularity)]="selectedGranularity"
    [granularities]="filteredGranularities"
  ></kpi4me-granularity-rotator>
  <!--  <div *ngIf="showGranularityButtons" class="buttons" fxLayoutGap="0.2rem">-->
  <!--    <div *ngFor="let granularity of filteredGranularites" class="buttonDiv">-->
  <!--      <button-->
  <!--        (click)="onGranularityChange(granularity.id)"-->
  <!--        [matTooltip]="granularity.name"-->
  <!--        [ngClass]="granularity.id === selectedGranularity ? 'granularityButton active' : 'granularityButton'"-->
  <!--        mat-button-->
  <!--        mat-ripple-->
  <!--      >{{granularity.shortcut}}-->
  <!--      </button>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="input-group" style="height: 100%">
    <div class="calendar-input-wrapper">
      <span>von</span>

      <div class="input-wrapper">
        <input
          #dpFromDate="ngbDatepicker"
          #fromDateModel="ngModel"
          (blur)="onBlurFromDate()"
          (keydown.enter)="onBlurFromDate()"
          (dateSelect)="onBlurFromDate()"
          [(ngModel)]="fromDate"
          class="form-control calendar-input"
          name="fromDate"
          ngbDatepicker
          placeholder="tt.mm.jjjj"
        />

        <mat-icon
          *ngIf="isInvalidDate(fromDate) || !this.fromDateBeforeToDate()"
          [matTooltip]="isInvalidDate(fromDate) ? 'Ungültiges Datumsformat. Bitte im Format tt.mm.jjjj angeben' :
          !this.fromDateBeforeToDate() ? 'Das von- Datum muss vor dem bis- Datum liegen' : ''
          "
          class="red-icon"
          svgIcon="{{EsvgFiles.error}}"
        ></mat-icon>

        <div class="calendar-icon">
          <mat-icon
            (click)="onDatePickerToggle(dpFromDate, null)"
            id="calendar-icon"
            svgIcon="{{EsvgFiles.calendar}}"
          ></mat-icon>
        </div>
      </div>

      <div class="icon-wrapper">
        <mat-icon
          (click)="onNextDateFromDate()"
          class="calendar-icon"
          style="transform: rotate(180deg)"
          svgIcon="{{EsvgFiles.arrow_down}}"
        ></mat-icon>

        <mat-icon
          (click)="onPreviousDateFromDate()"
          class="calendar-icon"
          svgIcon="{{EsvgFiles.arrow_down}}"
        ></mat-icon>
      </div>
    </div>
  </div>
  <div class="input-group" style="margin-left: 2rem">
    <div class="calendar-input-wrapper">
      <span>bis</span>
      <!--      <div (click)="onDatePickerToggle(datepicker, 'to')" class="input-wrapper">-->

      <div class="input-wrapper">
        <input
          #dpToDate="ngbDatepicker"
          #toDateModel="ngModel"
          (blur)="onBlurToDate()"
          (keydown.enter)="onBlurToDate()"
          (dateSelect)="onBlurToDate()"
          [(ngModel)]="toDate"
          class="form-control calendar-input"
          name="dpToDate"
          ngbDatepicker
          placeholder="tt.mm.jjjj"
        />
        <mat-icon
          *ngIf="isInvalidDate(toDate)"
          [matTooltip]="
            'Ungültiges Datumsformat. Bitte im Format tt.mm.jjjj angeben'
          "
          class="red-icon"
          svgIcon="{{EsvgFiles.error}}"
        >
        </mat-icon>
        <div class="calendar-icon">
          <mat-icon
            (click)="onDatePickerToggle(dpToDate, null)"
            svgIcon="{{EsvgFiles.calendar}}"
          ></mat-icon>
        </div>
      </div>

      <div class="icon-wrapper">
        <mat-icon
          (click)="onNextDateToDate()"
          class="calendar-icon"
          style="transform: rotate(180deg)"
          svgIcon="{{EsvgFiles.arrow_down}}"
        ></mat-icon>
        <mat-icon
          (click)="onPreviousDateToDate()"
          class="calendar-icon"
          svgIcon="{{EsvgFiles.arrow_down}}"
        ></mat-icon>
      </div>
    </div>
  </div>
</div>
