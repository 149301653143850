<input
  #ignoredInput
  [(colorPicker)]="params.value.value"
  [(cpToggle)]="toggle"
  [cpOKButtonClass]="'btn btn-primary btn-xs'"
  [cpOKButton]="true"
  (colorPickerClose)="onColorPickerClosed($event)"
  [cpSaveClickOutside]="false"
  [style.background]="params.value.value"
/>
