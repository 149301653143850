import {Component, ViewEncapsulation} from '@angular/core';
import {MatIconModule} from '@angular/material/icon';
import {EsvgFiles} from 'frontier/nucleus';

@Component({
  selector: 'kpi4me-clear-entity',
  template: `

    <div class="clear-option">
      <mat-icon class="red-icon" svgIcon="{{EsvgFiles.delete}}" style="height: 100%;"></mat-icon>
      <span>Auswahl leeren</span>
    </div>

  `,
  styles: [
    `
           :host {
             display: block;
             width: 100%;
             padding: 4px 16px;
             margin-bottom: 0.5rem;
             .clear-option {
               cursor: pointer;
               display: flex;
               align-items: center;
             }
           }
         `,
  ],
  encapsulation: ViewEncapsulation.Emulated,
  imports: [
    MatIconModule
  ],
  standalone: true
})
export class ClearEntityComponent {
  protected readonly EsvgFiles = EsvgFiles;
}
