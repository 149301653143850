<div style="display: flex; flex-direction: column; gap: 0.5rem;">
  <div
    *ngIf="config?.showDoFlattenToggle || config?.showDoShowLeafs"
    class="flex-row space-between center"
  >
    <!--    <div class="mat-h4" style="margin: unset">{{apiInstance.name}}</div>-->
    <mat-slide-toggle
      *ngIf="config.showDoFlattenToggle"
      (change)="onDoFlattenChange($event)"
      [checked]="true"
      [color]="'primary'"
      [labelPosition]="'before'"
    >
      Reduzieren
    </mat-slide-toggle>
    <mat-slide-toggle
      *ngIf="config.showDoShowLeafs"
      (change)="onShowLeafToggle($event)"
      [checked]="false"
      [color]="'primary'"
      [labelPosition]="'before'"
    >
      Blätter anzeigen
    </mat-slide-toggle>
    <mat-slide-toggle
      *ngIf="config.showReferenceToggle"
      [(ngModel)]="config.showReferenceIcon"
      [checked]="config.showReferenceIcon"
      [color]="'primary'"
      [labelPosition]="'before'"
    >
      Referenzen verdeutlichen
    </mat-slide-toggle>
    <mat-slide-toggle
      (change)="onShowDoShowInvisibleNodesToggle($event)"
      *ngIf="config.showDoShowInvisibleNodesToggle"
      [(ngModel)]="config.showDoShowInvisibleNodes"
      [checked]="config.showDoShowInvisibleNodes"
      [color]="'primary'"
      [labelPosition]="'before'"
    >
      Unsichtbare Knoten anzeigen
    </mat-slide-toggle>
  </div>

  <input
    *ngIf="config?.showFilter"
    class="form-control smaller-form-control"
    id="filter"
    #filter
    (keyup)="onStringFilterChange(filter.value)"
    placeholder="Filter"
  />
  <div class="flex-row align-center" style="gap: 0.25rem">
    <button
      (click)="addRootNode()"
      *ngIf="config?.showNewRoot"
      mat-stroked-button>
      Neuer Wurzelknoten
    </button>
    <button
      *ngIf="config?.contextMenuActions?.import"
      (click)="onImportMenuClick(null)"
      mat-stroked-button
    >
      <span class="flex-row align-center">
        <mat-icon class="blue-icon" [svgIcon]="EsvgFiles.upload"></mat-icon>
        Importieren
      </span>
    </button>
  </div>

  <tree-root
    #tree
    [nodes]="data"
    [options]="options"
    class="customTreeStyle"
    [state]="this.apiInstance?.custom?.state"
    (contextmenu)="$event.preventDefault()"
    (nodeActivate)="onNodeActivated($event)"
    (moveNode)="onMoveNode($event)"
    (initialized)="onTreeInitialized()"
    (stateChange)="onTreeStateChange($event)"
    [style.top.px]="treePaddingTop"
  >
    <ng-template #loadingTemplate let-loadingNode>
      <div *ngIf="loadingNode.hasChildren">Lädt...</div>
    </ng-template>
    <ng-template #treeNodeWrapperTemplate let-node let-index="index">
      <div class="tree-node-wrapper">
        <mat-icon
          (click)="onNewNode(node.parent, node)"
          *ngIf="config?.editableTreeStructure"
          class="blue-icon node-action top-icon"
          svgIcon="{{EsvgFiles.add}}"
        ></mat-icon>
        <div>
          <div class="node-wrapper">
            <tree-node-expander
              [node]="node"
              [style.width.px]="showCheckbox(node) && node.isLeaf ? 0 : 13"
            ></tree-node-expander>
            <!--        <tree-node-checkbox [node]="node"></tree-node-checkbox>-->
            <mat-checkbox
              #checkbox="matCheckbox"
              (change)="onNodeSelectionChange($event, node)"
              *ngIf="showCheckbox(node)"
              [color]="'primary'"
              [checked]="node.checked"
              [indeterminate]="node.partiallyChecked"
            >
            </mat-checkbox>
            <div
              (click)="node.mouseAction('click', $event)"
              [class.node-content-wrapper-active]="node.isActive"
              [class.node-content-wrapper-focused]="node.isFocused"
              (contextmenu)="node.mouseAction('contextMenu', $event)"
              (dblclick)="node.mouseAction('dblClick', $event)"
              (treeDrop)="node.onDrop($event)"
              [ngClass]="
                showCheckbox(node) && node.isLeaf
                  ? 'node-content-wrapper-leaf node-content-wrapper'
                  : showCheckbox(node)
                  ? 'extended-node-content-wrapper node-content-wrapper'
                  : 'node-content-wrapper'
              "
              [treeAllowDrop]="node.allowDrop"
              [treeDragEnabled]="node.allowDrag()"
              [style.background-color]="node.data.styles?.backgroundColor || ''"
              [style.color]="node.data.styles?.color || ''"
              [treeDrag]="node"
            >
              <tree-node-content
                #myTooltip="matTooltip"
                (mouseenter)="onGetNodeTooltip(node, myTooltip)"
                (mouseleave)="node.data.mouseInside = false; myTooltip.hide()"
                [index]="index"
                [matTooltipDisabled]="node.data.tooltip == null"
                [template]="treeNodeTemplate"
                [matTooltip]="getNodeTooltip(node)"
                [node]="node"
                [treeDrag]="node"
              ></tree-node-content>
            </div>
          </div>
        </div>
        <mat-icon
          (click)="onNewNode(node.parent, node.parent.children[index + 1])"
          *ngIf="config?.editableTreeStructure"
          class="blue-icon node-action bottom-icon"
          svgIcon="{{EsvgFiles.add}}"
        ></mat-icon>
        <mat-icon
          (click)="onNewNode(node, null)"
          *ngIf="config?.editableTreeStructure"
          class="blue-icon node-action right-icon"
          svgIcon="{{EsvgFiles.add}}"
        ></mat-icon>
      </div>
    </ng-template>
    <ng-template #treeNodeTemplate let-index="index" let-node>
      <div
        (contextmenu)="onContextMenu($event, node)"
        class="tree-node-content"
      >
        <mat-icon
          *ngIf="config.showReferenceIcon && node.data?.isreference"
          svgIcon="{{EsvgFiles.link}}"
        ></mat-icon>
        {{ node.data.name }}
      </div>
    </ng-template>
  </tree-root>
</div>
<button
  [matMenuTriggerFor]="menu"
  [style.left.px]="menuPos.x"
  [style.top.px]="menuPos.y"
  mat-button
  style="visibility: hidden; width: 0; height: 0; position: fixed"
>
  Menu
</button>
<mat-menu #menu="matMenu">
  <button
    (click)="onMenuRename()"
    *ngIf="config?.contextMenuActions?.rename"
    class="menuButton"
    mat-menu-item
  >
    <mat-icon class="blue-icon" mat-ripple svgIcon="{{EsvgFiles.edit}}"></mat-icon>
    <span>Umbenennen</span>
  </button>
  <button
    *ngIf="config?.contextMenuActions?.changeColor && styleMenuRef"
    [matMenuTriggerFor]="styleMenuRef?.menu"
    mat-menu-item
  >
    <mat-icon class="blue-icon" mat-ripple svgIcon="transfer_format"></mat-icon>
    <span>Darstellung</span>
  </button>

  <button
    *ngIf="
      (menuNode &&
        config?.contextMenuActions?.delete?.type === 'reference' &&
          menuNode?.data.isManualReference) ||
        (config?.contextMenuActions?.delete?.type === 'node' &&
          menuNode?.hasChildren == false);
      else deleteMenuTrigger
    "
    mat-menu-item
    (click)="onDeleteSingleNode()"
  >
    <mat-icon class="blue-icon" mat-ripple svgIcon="{{EsvgFiles.delete}}"></mat-icon>
    <span>Löschen</span>
  </button>
  <ng-template #deleteMenuTrigger>
    <button
      *ngIf="config?.contextMenuActions?.delete && deleteNodeMenuRef"
      [matMenuTriggerFor]="deleteNodeMenuRef?.menu"
      mat-menu-item
    >
      <mat-icon class="blue-icon" mat-ripple svgIcon="{{EsvgFiles.delete}}"></mat-icon>
      <span>Löschen</span>
    </button>
  </ng-template>


  <button
    *ngIf="config?.contextMenuActions?.import"
    mat-menu-item
    (click)="onImportMenuClick(this.menuNode.data)"
  >
    <mat-icon class="blue-icon" mat-ripple svgIcon="{{EsvgFiles.upload}}"></mat-icon>
    <span>Importieren</span>
  </button>
  <button
    *ngIf="config?.contextMenuActions?.export"
    mat-menu-item
    (click)="onExportMenuClick()"
  >
    <mat-icon class="blue-icon" mat-ripple svgIcon="{{EsvgFiles.download}}"></mat-icon>
    <span>Exportieren</span>
  </button>

</mat-menu>

<app-node-style-menu
  [config]="config"
  [node]="menuNode"
  [apiInstance]="apiInstance"
  (updatedStyle)="onNodeStyleUpdate()"
  style="visibility: hidden"
>
</app-node-style-menu>
<kpi4me-delete-node
  [node]="menuNode"
  (deletePress)="onNodeDeletePress($event)"
  [apiInstance]="apiInstance"
  style="visibility: hidden"
>
</kpi4me-delete-node>
