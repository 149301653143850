import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {DialogService} from '../dialogs/dialog.service';
import {ApiConfigService, StoreService} from 'frontier/nucleus';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private router: Router, private dialogService: DialogService, public apiConfigService: ApiConfigService, public store: StoreService) {
  }

  storeBearerToken(accesstoken: string, refreshtoken: string) {
    console.log('store dev authorization');
    localStorage.setItem('accesstoken', accesstoken);
    localStorage.setItem('refreshtoken', refreshtoken);
  }

  // reset everything
  deleteSession() {
    console.log('deleting session!');
    this.clearStorage();
  }

  navigateToLogin() {
    this.router.navigate(['/login']);
    this.dialogService.dialog.closeAll();
  }

  isAuthenticated(): boolean {
    return this.store.isAuthenticated();
  }

  // Deprecated
  // Save the session in localstorage
  saveSessionPortal(resp: HttpResponse<any>) {
    // Convert Pragma string to session id(string) and session expiration time(number)
    const str = resp.headers.get('Pragma');
    if (str != null) {
      console.log('Save Pragma header: ', str);
      let [portalSid, portalSexp] = str.split(',');
      portalSid = portalSid.substring(0);
      portalSexp = portalSexp.substring(17);
      localStorage.setItem('PortalSID', portalSid);
      localStorage.setItem('PortalSEXP', portalSexp);
    }
  }

  singleSignOn(nextPath: string) {
    let href;
    if (this.apiConfigService.environment.env === 'prod') {
      href = window.location.origin + '/login?redirectpath=' + nextPath;
    } else {
      nextPath = `${encodeURIComponent('sso?callbackurl=' + nextPath)}`
      href = this.apiConfigService.environment.url + '/login?devredirect=' + window.origin + '&redirectpath=' + nextPath;
    }
    localStorage.setItem('sso', 'true');
    window.location.href = href;
  }

  getBearerToken() {
    return localStorage.getItem('accesstoken')
  }

  getTenant() {
    return localStorage.getItem('tenant')
  }

  getRefreshTokenToken() {
    return localStorage.getItem('refreshtoken')
  }

  updateBearerToken(resp: HttpResponse<any>) {
    const str = resp.headers.get('access_token');
    if (str !== null) {
      console.log('update access_token in local storage');
      this.storeBearerToken(str, resp.headers.get('refresh_token'));
    }
    const tenant = resp.headers.get('tenant');
    if (tenant !== null) {
      console.log('update tenant in local storage');
      localStorage.setItem('tenant', tenant);
    }
  }

  setSessionExpired() {
    this.deleteSession();
    this.dialogService.dialog.closeAll();
    if (this.router.url.includes('login')) return;
    this.router.navigate(['login'], {queryParams: {nextRoute: this.router.url}})
  }

  private clearStorage() {
    localStorage.setItem('PortalSID', "dsession='-1'");
    localStorage.removeItem('PortalSEXP');
    localStorage.removeItem('CurrentUser');
    localStorage.removeItem('accesstoken');
    localStorage.removeItem('refreshtoken');
  }
}
